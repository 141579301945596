import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {comprasUsuarioFetch} from './../../actions/MinhasComprasAction'
import Compra from './components/Compra'


class MinhasCompras extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        this.props.comprasUsuarioFetch(this.props.idUsuario).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="minhas-compras text-left">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="table-compras col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="h4">Materiais didáticos adquiridos</h4>
                            </div>
                            <div className="card-body">
                                {
                                    (this.state.loadingCompleted) ?
                                        (this.props.minhasCompras.length > 0) ? (
                                                this.props.minhasCompras.map((item) => (
                                                    <Compra key={item.id} history={this.props.history}
                                                            compra={item}/>
                                                ))
                                            )
                                            :
                                            <div className="alert alert-info col-12">Você não fez nenhuma compra!</div>
                                        : <div className="text-center"><Spinner/></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        idUsuario: state.AppReducer.userData.id,
        minhasCompras: state.MinhasComprasReducer.minhasCompras,

    }
)

export default connect(mapStateToProps, {
    comprasUsuarioFetch
})(MinhasCompras)