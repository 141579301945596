import React from 'react'
import Link from "react-router-dom/Link";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
//
import {connect} from "react-redux"
import {setStepAction} from "../../actions/RenovacaoMatriculaAction";

class StepContrato extends React.Component {

    getDate = str => {
        if (!str)
            return '';

        const date_str = str.split('-');
        const date = new Date(parseInt(date_str[0]), parseInt((date_str[1] - 1)), date_str[2]);
        return date;
    }

    submitContrato = () => {
        const {usuario} = this.props;
        if(usuario.name) {
            this.props.setStepAction(3);
        }else {
            this.props.setStepAction(2);
        }
    }

    render() {
        const {usuario, matricula} = this.props;

        return (
            (matricula && matricula.id) ? (
                <div className="content h-100">
                    <div className="content-body font-text-renovacao h-75">
                        {
                            usuario.name ? (
                                <div>
                                    <span>Olá, {usuario.name.split(" ")[0]} bem vindo a renovação de contrato, leia os termos abaixo e caso concorde clique em continuar</span><br/><br/>
                                </div>
                            ) : (
                                <div>
                                    <span>Olá, {matricula.name.split(" ")[0]}, sentimos sua falta,</span><br/>
                                    <span>Caso queira voltar a estudar, leia os termos abaixo e para concordar clique em continuar.</span><br/><br/>
                                </div>
                            )
                        }
                        <div className="h-75 col-12 col-lg-8 offset-lg-2">
                            <iframe
                                id="contractIframe"
                                width="100%"
                                height="90%"
                                src="https://rockfeller.com.br/pps"
                                allowFullScreen
                            />
                        </div>

                    </div>
                    <div className="input mt-2 h-25">
                        <br/><br/>
                        <Link to="/" className="btn btn-danger mr-2">Voltar</Link>
                        <button onClick={this.submitContrato} className="btn btn-info">Continuar</button>
                    </div>
                </div>
            ) : (
                <div className="content h-100">
                    <div className="content-body h-75">
                        <div className="alert alert-warning">Você não possuí um contrato a renovar</div>
                    </div>
                </div>
            )
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        usuarioLogin: state.AppReducer.userData,
        matricula: state.RenovacaoMatriculaReducer.matriculaRenovacao,

    }
)

export default connect(mapStateToProps, {setStepAction})(StepContrato)


