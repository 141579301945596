import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {conteudoOnlineKidsUsuarioFetch} from "../../actions/ConteudoOnlineAction"
import {URL} from "../../services/api";


class ConteudoOnlineKidsAula extends Component {

    state = {
        loadingCompleted: false,
        livro: []
    }

    componentWillMount() {
        const id = parseInt(this.props.match.params.id)
        this.props.conteudoOnlineKidsUsuarioFetch(this.props.usuario.id).then(
            () => {
                this.props.conteudoOnline.map(livro => {
                    if (parseInt(livro.id) === parseInt(id))
                        this.setState({livro})
                })
                this.setState({loadingCompleted: true})
            }
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="conteudo-online-kids-aula">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        {
                            (this.state.loadingCompleted) ?
                                <div className="card">
                                    <div className="text-center">
                                        <div className="content aluno">
                                            <h2 className="text-info">Conteúdo
                                                Online : {this.state.livro.nome} </h2>
                                            <br/>
                                            <iframe
                                                name="FRAME1"
                                                //src={`${this.state.livro.link}`}
                                                src={`${URL}/student-payment/${this.props.usuario.id}/token/${this.props.usuario.token}/book/${this.state.livro.id}/redirect-book`}
                                                width="95%"
                                                height="700"
                                                scrolling='auto' >
                                                <embed src={`${URL}/student-payment/${this.props.usuario.id}/token/${this.props.usuario.token}/book/${this.state.livro.id}/redirect-book`}
                                                       width="95%" height="700"/>
                                            </iframe>
                                        </div>
                                    </div>
                                </div> : <div className="w-100 text-center"><Spinner/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        conteudoOnline: state.ConteudoOnlineReducer.conteudoOnlineKids,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {conteudoOnlineKidsUsuarioFetch})(ConteudoOnlineKidsAula)