import {
    MODIFICA_ATIVIDADE_COMPLEMENTAR,
    MODIFICA_LIVROS_ATIVIDADE_COMPLEMNETAR
} from '../actions/types'

const INITIAL_STATE = {
    atividades: [],
    aulas: [],
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case MODIFICA_ATIVIDADE_COMPLEMENTAR:
            return { ...state, atividades: action.payload }
        case MODIFICA_LIVROS_ATIVIDADE_COMPLEMNETAR:
            return { ...state, aulas: action.payload }
        default:
            return state;
    }

}