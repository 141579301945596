import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Spinner from "react-activity/lib/Spinner";
import "react-activity/lib/Spinner/Spinner.css";
import b64 from 'base-64';
//
import "./styles.css"
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import {pesquisaUsuarioFetch, savePesquisa} from '../../actions/AppAction';
import {removeResearch} from '../../services/pesquisa';
import {addItemLocalStorage, getItemDecodedLocalStorage} from '../../services/auth';


class Pesquisa extends Component {

    state = {
        form: {},
        messageSucess: '',
        loadingSave: false
    };

    async componentWillMount() {
        const pesquisa = this.props.pesquisa.research;
        const user = this.props.user;
        await this.props.pesquisaUsuarioFetch();
        if (!this.props.pesquisa.research)
            this.props.history.push('/');
        if (pesquisa && pesquisa.id) {
            const form = await getItemDecodedLocalStorage(`@RESEARCH-${user.id}-${pesquisa.id}-FORM`);
            if (form) {
                this.setState({form});
            }
        }
    }

    handleInputChange = async ({target}) => {
        const form = this.state.form;
        let value = target.value;
        if (typeof value === 'string' && value.length > 3) {
            value = this.cleanString(value);
            target.value = value;
        }
        form[target.name] = value;
        this.setState({form});
        if (target.name && target.name.indexOf('option') >= 0)
            this.saveResearch();
    };

    saveResearch = () => {
        const pesquisa = this.props.pesquisa.research;
        const user = this.props.user;
        const form = this.state.form;
        if (pesquisa && pesquisa.id && form)
            addItemLocalStorage(form, `@RESEARCH-${user.id}-${pesquisa.id}-FORM`);
    }

    cleanString = input => {
        var output = "";
        var lastChar = "";
        const normalChars = "a-zA-Z0-9\\s`~!@#$%^&*()_+-={}|:;<>?,.àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ\/\"\'\\\\[\\]";
        for (var i = 0; i < input.length; i++) {
            if ((input.charCodeAt(i) <= 127 || normalChars.includes(input.charAt(i)) !== false) && (lastChar !== '  ' || input.charAt(i) !== '  ')) {
                output += input.charAt(i);
                lastChar = input.charAt(i);
            }
        }
        return output;
    }

    _removeEmoji = str => {
        let text = str;
        text = text.replace('\ud83d', '');
        text = text.replace('\ud83C', '');
        text = text.replace('\udff3', '');
        text = text.replace('\ufffd', '');
        text = text.replace('\ufe0f', '');
        text = text.replace('\udde6', '');
        text = text.replace('\udde9', '');
        text = text.replace('\udd28', '');
        text = text.replace('\uddee', '');
        text = text.replace('\ude48', '');
        text = text.replace('\ude0a', '');
        text = text.replace('\udd14', '');
        text = text.replace('\ud83e', '');
        text = text.replace('\udc8b', '');
        text = text.replace('\ude05', '');
        text = text.replace('\udf19', '');
        text = text.replace('\ude00', '');
        text = text.replace('\ude03', '');
        text = text.replace('\uFFFD', '');
        text = text.replace('\udc79', '');
        text = text.replace('\ud83d', '');
        text = text.replace('\udc7a', '');
        text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        text.replace(/[^A-Za-z0-9]/g, '');
        text.replace(/[^a-z0-9+]+/gi, '');
        text.replace('👺', '');
        text.replace(/[^\x00-\xFF]/g, "");

        text = text.replace("/[^a-zA-Z0-9\s`~!@#$%^&*()_+-={}|:;<>?,.\/\"\'\\\[\]]/", '')
        return text;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const form = this.state.form;
        const pesquisa = this.props.pesquisa.research;
        const user = this.props.user;
        const messageSucess = this.props.pesquisa.research.messageSuccess;
        this.setState({loadingSave: true});
        this.props.savePesquisa(this.props.pesquisa.research.id, this.props.pesquisa.courseClass, Object.entries(form)).then(
            () => {
                this.setState({messageSucess: messageSucess});
                removeResearch();
                localStorage.removeItem(`@RESEARCH-${user.id}-${pesquisa.id}-FORM`)
                //this.props.history.push('/')
                this.setState({loadingSave: false})
            }
        ).catch(
            error => {
                if (error.response && error.response.data) {
                    if (typeof error.response.data === 'string')
                        alert(error.response.data);
                    else
                        alert('Remova os emojis presentes no(s) texto(s) e tente novamente!')
                } else
                    alert('Erro ao tentar salvar a pesquisa');
                this.setState({loadingSave: false})
            }
        );
    };

    render() {
        const {form} = this.state;

        return (
            <div className="pesquisa">
                <Header/>
                <div className="row text-center" translate="no">
                    <Menu/>

                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="row">
                            <div className="container">
                                <div className="card">
                                    <div className="card-header">
                                        <h1 className="jumbotron-heading mb-3" translate="no">Responder ao
                                            questionário!</h1>
                                    </div>

                                    <div className="card-body text-left">
                                        {(
                                            this.props.pesquisa.research && this.props.pesquisa.research.itens && !this.state.messageSucess) && (
                                            <div className="row">
                                                <div className="col-12 alert alert-warning">
                                                    Por favor não utilizar emojis!
                                                </div>
                                            </div>
                                        )}
                                        <form className="mt-3" ref={c => (this.form = c)}
                                              onSubmit={this.handleSubmit}>
                                            {(this.props.pesquisa.research) ?
                                                this.props.pesquisa.research.itens.map(item => (
                                                    <div key={item.id} className='card p-3'>
                                                        <div className="card-header">
                                                            <h4 className="jumbotron-heading mb-3 h4"
                                                                translate="no">
                                                                {item.name}
                                                            </h4>
                                                        </div>

                                                        <div className="card-body text-left">
                                                            {
                                                                item.questions.map(question => (
                                                                    <div key={question.id}
                                                                         className='jumbotron row'>

                                                                        <div
                                                                            className='col-12 col-sm-4 col-md-4 col-xl-4 mb-3'>
                                                                            {question.name}
                                                                        </div>


                                                                        {
                                                                            (question.responseQuestion) ?
                                                                                <div
                                                                                    className='col-12 col-sm-4 col-md-4 col-xl-4'>
                                                                                    {
                                                                                        question.options.map(option => (
                                                                                            <div key={option.id}
                                                                                                 className="radio col-12">
                                                                                                <label>
                                                                                                    <input
                                                                                                        onChange={this.handleInputChange}
                                                                                                        type="radio"
                                                                                                        name={`option[${question.id}]`}
                                                                                                        value={option.id}
                                                                                                        required
                                                                                                        checked={parseInt(form[`option[${question.id}]`]) === option.id}
                                                                                                    /> {option.name}
                                                                                                </label>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <input
                                                                                    type="hidden"
                                                                                    name={`option[${question.id}]`}
                                                                                />
                                                                        }
                                                                        <div
                                                                            className={(question.responseQuestion) ? 'col-12 col-sm-4 col-md-4 col-xl-4' : 'col-12 col-sm-8 col-md-8 col-xl-8 mt-3'}>
                                                                            {
                                                                                (question.canComment) ?
                                                                                    <textarea
                                                                                        onChange={this.handleInputChange}
                                                                                        className='col-12'
                                                                                        name={`comments[${question.id}]`}
                                                                                        placeholder={(question.responseQuestion) ? 'Comentário' : 'Resposta'}
                                                                                        required={(question.responseQuestion) ? false : true}
                                                                                        value={form[`comments[${question.id}]`]}
                                                                                        onBlur={() => this.saveResearch()}
                                                                                    >
                                                                                        </textarea>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>

                                                    </div>
                                                )) :
                                                (this.state.messageSucess) ?
                                                    <div className='card p-3'>
                                                        <div className="card-header">
                                                            <div className="aler alert-info w-100 p-3 text-center">
                                                                {this.state.messageSucess}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='card p-3'>
                                                        <div className="card-header">
                                                            <div
                                                                className="aler alert-info w-100 p-3 text-center">Você
                                                                não
                                                                possui nenhum questionário para responder.
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                (this.state.messageSucess) ?
                                                    <Link to="/" className='btn btn-secondary col-12'>Voltar</Link>
                                                    :
                                                    (this.state.loadingSave) ?
                                                        <div className="col-12 justify-content-center text-center">
                                                            <Spinner/>
                                                        </div> :
                                                        <button type='submit'
                                                                className='btn btn-info col-12'>Salvar</button>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        pesquisa: state.AppReducer.pesquisa,
        user: state.AppReducer.userData
    }
);

export default connect(mapStateToProps, {pesquisaUsuarioFetch, savePesquisa})(Pesquisa)

