import axios from "axios"
import {getToken} from "./auth"
export const URL = 'https://joy.rockfellerbrasil.com.br'
//export const URL = 'https://joydev.rockfellerbrasil.com.br'
//export const URL = 'https://joydf.rockfellerbrasil.com.br'
//export const URL = 'http://127.0.0.1:8008';

const api = axios.create({
    baseURL: URL+"/api/v1"
});

api.interceptors.request.use(async config => {
    let token = getToken();
    if (token) {
        token = token.replace(`"`, '');
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;
