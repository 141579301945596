import {
    PAGAMENTO_CARTAO_SUCESSO
} from '../actions/types';

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAGAMENTO_CARTAO_SUCESSO:
            return  action.payload
        default:
            return state;
    }

}