import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {livrosAComprarUsuarioFetch} from "../../actions/ComprasAction"
import Body from './components/Body'


class ComprarMaterialPedagogico extends Component {

    state = {
        loadingCompleted: false,
        error: false,
    }

    componentWillMount() {
        this.props.livrosAComprarUsuarioFetch().then(
            () => {
                this.setState({loadingCompleted: true})
            }
        ).catch(
            error => {
                this.setState({error: Object.values(error), loadingCompleted: true})
            }
        )
    }

    render() {
        return (
            <div className="comprar-material-pedagogico text-left">
                <Header/>
                <div className="row">
                    <Menu/>
                    {
                        (this.state.loadingCompleted) ?
                            <Body history={this.props.history} error={this.state.error}/>
                            : (
                                <div className="col-8">
                                    <div className="text-center">
                                        <Spinner/>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        )
    }
}

export default connect(null, {
    livrosAComprarUsuarioFetch
})(ComprarMaterialPedagogico)