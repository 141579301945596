import React from 'react';

export default class AtividadeComplementarItem extends React.Component {

    state = {
        url: ''
    }

    componentWillMount() {
        this.setState({url: `/${this.props.atividade.id}/atividades-complementares-livro`})
    }

    render() {
        const {atividade} = this.props

        return (
            <div className="col-4 col-md-3 p-1">
                <div className="card card-body p-1">
                    <div className="item">
                        <a href={this.state.url}>
                            <img src={`${atividade.miniaturaSrc}`} className="w-100 r-t" alt={`${atividade.nome}`}/>

                            <div className="p">
                                {/*<span className="font-thin h6">

                                    <div className="text-info">
                                        <small className="text-left">{atividade.nome}</small>
                                    </div>

                                </span>*/}
                                {
                                    (atividade.cdId && atividade.file) ?
                                        <p className="">
                                            <span className="pull-right">

                                            </span>
                                        </p>
                                        : null
                                }
                            </div>

                        </a>
                    </div>
                </div>
            </div>
        )
    }
}