import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {atividadesLivrosUsuarioFetch, getSlide} from './../../actions/AtividadeComplementarAction'


class AtividadeComplementarLivro extends Component {

    state = {
        loadingCompleted: false,
    }

    componentWillMount() {
        const id = parseInt(this.props.match.params.id)
        this.props.atividadesLivrosUsuarioFetch(this.props.usuario.id, id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            error => {
                if (error.response && error.response.data)
                    alert(error.response.data)
                this.setState({loadingCompleted: true})
            }
        )

        //this.props.getSlide(this.props.match.params.id,this.props.match.params.unit)
    }

    render() {
        return (
            <div className="conteudo-online-atividade">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        {
                            (this.state.loadingCompleted) ?
                                (this.props.aulas.livro) ?
                                    <div className="card">
                                        <div className="text-center">
                                            <h4 className="text-info h4">{this.props.aulas.livro.nome}</h4>
                                            <br/>
                                            <div className="row">
                                                <div className="container">
                                                    <iframe
                                                        type="text/html"
                                                        allow="microphone;"
                                                        src={`https://joy.rockfellerbrasil.com.br/aula/aaclass.php?id=${this.props.match.params.id}-${this.props.match.params.unit}`}
                                                        className="ml-conteudo w-100" height="645"
                                                    >
                                                        <embed type="text/html"
                                                               src={`https://joy.rockfellerbrasil.com.br/aula/aaclass.php?id=${this.props.match.params.id}-${this.props.match.params.unit}`}
                                                               className="w-100" height="645"
                                                        />
                                                    </iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="w-100 text-center alert-info alert">Você não possui atividade!</div>
                                : <div className="w-100 text-center"><Spinner/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        aulas: state.AtividadeComplementarReducer.aulas,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {atividadesLivrosUsuarioFetch, getSlide})(AtividadeComplementarLivro)



