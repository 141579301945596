import React, {Component} from 'react'
//
import Produto from './Produto'

export default class Matricula extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-header">
                    Matricula nº {this.props.matricula.id} - {this.props.matricula.name}
                </div>
                <div className="row text-center">
                    {
                        (this.props.matricula.product.length > 0) ?
                            this.props.matricula.product.map((product) => (
                                <Produto key={`${product.enrollment}-${product.id}`} produto={product} history={this.props.history}/>
                            )) :
                            <div className="col-12">
                                    <div className="alert alert-info">Nenhum material pendente.</div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}