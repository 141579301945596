import React, {Component} from 'react'
import {connect} from "react-redux"
import CheckboxDia from "../AgendarAulaPresencial/components/CheckboxDia"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {suportesDiasCheck, diasDisponiveisSuporte} from './../../actions/SuporteAction'


class Suporte extends Component {

    state = {
        enviandoDados: false,
        loadingCompleted: false,
        dias: []
    }

    componentWillMount() {
        this.props.diasDisponiveisSuporte(this.props.usuario.id).then(
            response => {
                this.setState({dias: response})
                this.setState({loadingCompleted: true})
            }
        )
    }

    enviarDados = (dias) => {
        if (dias.length > 0) {
            this.setState({enviandoDados: true})
            this.props.suportesDiasCheck(this.props.usuario.id, dias)
                .then(() => {
                    if (this.props.suportes.length === 0)
                        alert('Não foram encontrado suportes para essa(s) data(s)')
                    this.setState({enviandoDados: false})
                    this.props.history.push('/horario-suporte')
                })
                .catch(error => {
                    if (error.response && error.response.data )
                        alert(error.response.data)
                    else
                        alert('Não foram encontrado suportes para essa(s) data(s)')
                    this.props.history.push('/horario-suporte')
                })
        } else {
            alert('Selecione os dia(s) e horário(s) do suporte')
        }
    }

    _renderButton = () => {

        if (this.state.enviandoDados) {
            return (
                <Spinner/>
            )
        }
        return (
            <button type="button" className="btn btn-success"
                    onClick={this.enviarDados.bind(this, this.props.dias)}>
                Continuar
            </button>
        )
    }

    render() {
        return (
            <div className="suporte-list dashboard">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="row">
                            <div className="container">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="h4 pt-3">Agendar Suporte</h4>
                                    </div>
                                    {
                                        (this.state.loadingCompleted) ?
                                            <div className="card-body">
                                                <div className="row">
                                                    {
                                                        this.state.dias.map((data, i) => (
                                                            <CheckboxDia key={i} index={i} valor={data.data}
                                                                         diaString={data.dia}
                                                                         title={data.title}/>
                                                        ))
                                                    }
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        {this._renderButton()}
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="text-center col-12"><Spinner/></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        dias: state.AgendarAulaPresencialReducer.dias,
        suportes: state.SuporteReducer.suportes

    }
)

export default connect(mapStateToProps, {suportesDiasCheck, diasDisponiveisSuporte})(Suporte)