import _ from 'lodash'
//
import {
    MODIFICA_DIAS,
    MODIFICA_HORARIOS,
    MODIFICA_DIAS_HORARIOS_DISPONIVEIS,
    MODIFICA_PROCESSAMENTO, LISTA_AGENDAMENTOS_USUARIO,
    ADD_HORARIO_DISPONIVEL
} from '../actions/types'
import api from '../services/api'

export const diasAulaFetch = (dia) => {
    return dispatch => {
        dispatch({type: MODIFICA_DIAS, payload: dia.data});
        return dia.hours.map(item => {
            return dispatch({
                type: ADD_HORARIO_DISPONIVEL,
                payload: item
            })
        });
    }
};


export const horariosAulaFetch = (horario) => {
    return dispatch => {
        dispatch({type: MODIFICA_HORARIOS, payload: horario})
    }
}

export const modificarProcessamento = (processamento) => {
    return dispatch => {
        dispatch({type: MODIFICA_PROCESSAMENTO, payload: processamento})
    }
}


export const horariosDiasCheck = (idUsuario, dias, horarios) => dispatch =>
    api.post(`/agendamento/horarios`, {_id: idUsuario, _days: dias, _hours: horarios})
        .then(res => {
            const diasHoraiosDisponiveis = _.map(res.data)
            dispatch({type: MODIFICA_DIAS_HORARIOS_DISPONIVEIS, payload: diasHoraiosDisponiveis})
            dispatch({type: MODIFICA_HORARIOS, payload: []})
            dispatch({type: MODIFICA_DIAS, payload: []})
        })

export const agendarAula = (idUsuario, idSala) => dispatch =>
    api.post('/efetuar/agendamento', {_idAluno: idUsuario, _idAgendamento: idSala})
        .then(
            dispatch({type: MODIFICA_DIAS_HORARIOS_DISPONIVEIS, payload: []})
        )

export const cancelarAgendamento = (_id) => dispatch =>
    api.post('/cancelar/agendamento', {_id})
        .then(
            agendamentos => dispatch({type: LISTA_AGENDAMENTOS_USUARIO, payload: agendamentos.data})
        )

export const diasDisponiveisAgendamento = (id) => dispatch =>
    api.post(`/agendamento/horarios-disponiveis`, {_id: id})
        .then(res => {
            return res.data
        })