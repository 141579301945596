import api from "../services/api";
import {MODIFICA_CONTEUDO_ONLINE, MODIFICA_LIVROS_CONTEUDO_ONLINE, MODIFICA_CONTEUDO_ONLINE_KIDS} from "./types"

export const conteudoOnlineUsuarioFetch = (idUsuario) => dispatch =>
    api.post('/student/conteudo-online', {_id: idUsuario}).then(
        response => {
            return dispatch({type: MODIFICA_CONTEUDO_ONLINE, payload: response.data})
        }
    )

export const conteudoOnlineKidsUsuarioFetch = (idUsuario) => dispatch =>
    api.post('student/conteudo-online-kids', {_id: idUsuario}).then(
        response => {
            return dispatch({type: MODIFICA_CONTEUDO_ONLINE_KIDS, payload: response.data})
        }
    )

export const getBook = () => dispatch =>
    api.get('https://livros.rockfellerbrasil.com.br/01_chrome/index.html').then(
        response => console.log(response)
    )
export const conteudoOnlineLivroFetch = (idUsuario, idLivro) => dispatch =>
    api.post('/student/conteudo-online-livro', {_id: idUsuario, _idBook: idLivro}).then(
        response => {
            return dispatch({type: MODIFICA_LIVROS_CONTEUDO_ONLINE, payload: response.data})
        }
    )