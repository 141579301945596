import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
//

export default class DropDownAnswerKeys extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    render() {
        return (
            <div className="dropdown-tutorial">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggle}
                                 id="button-boletim-dropdown"
                                 className="btn card-header border-left-orange p-1">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <strong className="text-nowrap">
                                            {
                                                (this.props.type === 'STUDENT_ONDEMAND') ? 'Tutorial OnDemand' : this.props.type === 'STUDENT_PRESENCIAL' ? 'Tutorial Adulto' : 'Tutorial Duo'
                                            }

                                        </strong>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon
                                            icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                            size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left m-1">
                                    <div className="col-12 text-nowrap div-dropdown">
                                        {
                                            (this.props.type === 'STUDENT_ONDEMAND') ?
                                                <iframe src="https://player.vimeo.com/video/321727362" width="100%"
                                                        height="360"
                                                        frameBorder="0"
                                                        allowFullScreen
                                                /> :
                                                this.props.type === 'STUDENT_PRESENCIAL' ?
                                                    <iframe src={'https://player.vimeo.com/video/354704075'}
                                                            width="100%"
                                                            height="360"
                                                            frameBorder="0"
                                                            allowFullScreen/>
                                                    :
                                                    <iframe src={'https://player.vimeo.com/video/327813743'}
                                                            width="100%"
                                                            height="360"
                                                            frameBorder="0"
                                                            allowFullScreen/>
                                        }
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


