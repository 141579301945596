import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
//

export default class DropDownAnswerKeys extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    render() {
        return (
            <div className="dropdown-answer-keys mb-2">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggle}
                                 id="button-boletim-dropdown"
                                 className="btn card-header border-left-orange p-1">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <strong className="text-nowrap">
                                            Answer Keys
                                        </strong>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon
                                            icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                            size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left m-1">
                                    <div className="col-12 text-nowrap div-dropdown">
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-insight w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/AnswerKeys/01 Insight.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Insight</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-connection w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/AnswerKeys/02 Connection.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Connection</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-impulse w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/AnswerKeys/03 Impulse.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Impulse</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-overcome w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/AnswerKeys/04 Overcome.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Overcome</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-master w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/AnswerKeys/05 Master.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Master</strong>
                                            </a>
                                        </div>
                                        <hr/>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


