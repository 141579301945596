import React, {Component} from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from './../../../services/utilsPayment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMoneyCheck} from '@fortawesome/free-solid-svg-icons'
import b64 from 'base-64'
//
import bandeirasImg from './../../../assets/visa_mastercard_hipercard.png'
import {pagamentoCartao} from './../../../actions/PagamentoAction'
import {modificaCarrinhoItens} from './../../../actions/AppAction'


class Card extends Component {

    state = {
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: '',
        issuer: '',
        quantityParcel: 0,
        loadingPayment: false
    }

    handleCallback = ({issuer}, isValid) => {
        if (isValid) {
            this.setState({issuer})
        }
    }

    handleInputFocus = ({target}) => {
        this.setState({
            focused: target.name,
        })
    }

    handleInputChange = ({target}) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value)
        } else if (target.name === 'quantityParcel') {
            target.value = (target.value > 0) ? target.value : 1
            if (target.value > 0)
                this.props.modificaCarrinhoItens(this.props.carrinho, this.props.usuario, target.value)
        }

        this.setState({[target.name]: target.value})
    }

    handleSubmit = async e => {
        this.setState({loadingPayment: true})
        e.preventDefault()
        if (parseInt(this.state.quantityParcel) === 0) {
            alert('Selecione a quantidade de parcelas')
        } else {

            const formData = [...e.target.elements]
                .filter(d => d.name)
                .reduce((acc, d) => {
                    acc[d.name] = b64.encode(d.value)
                    return acc
                }, {})


            await this.props.pagamentoCartao(this.props.usuario, this.props.carrinho, this.props.carrinhoValor, formData)
                .then(success => {
                    if (success.payload.data.response === false) {
                        alert(success.payload.data.erroMessage)
                    } else {
                        this.form.reset()
                        alert('Pagamento realizado com sucesso!')
                        this.props.modificaCarrinhoItens([], this.props.usuario)
                        this.props.history.push('/')
                    }
                    this.setState({loadingPayment: false})
                })
                .catch(
                    error => {
                        if (error.response && error.response.data)
                            alert(error.response.data);
                        else
                            alert('Erro ao tentar realizar o pagamento, aguarde 5 minutos e tente novamente');
                        this.props.history.push('/minhas-compras')
                        this.setState({loadingPayment: false})
                    }
                )

        }
        //this.form.reset()
    };

    getValueParcel = (total, index) => {
        let value = total;
        if (index > 7) {
            for (let i = 7; i <= index; i++) {
                value += value * 0.01;
            }
        }
        const juro = value - total;
        return ((parseFloat(value)) / (index + 1)).toFixed(2).replace('.', ',') + ` Juros de R$${(juro).toFixed(2).replace('.', ',')}`
    };

    getQuaantityParcel = (cart) => {
        let quantityParcel = 6;
        const students = [];
        cart.map(item => {
            if (students[item.student] && students[item.student][item.courseId]) {
                students[item.student][item.courseId] += 1;
                quantityParcel = 12;
            } else {
                if(!students[item.student])
                    students[item.student] = [];

                students[item.student][item.courseId] = 1;
            }

            if(item.quantityParcel > quantityParcel) {
                quantityParcel = item.quantityParcel;
            }
        });
        return quantityParcel;
    }

    render() {
        const {name, number, expiry, cvc, focused, issuer} = this.state;
        const {carrinho, carrinhoValor, carrinhoValorFixed} = this.props;
        let fixed = carrinhoValorFixed;

        if (!fixed)
            fixed = carrinhoValor;
        let quantityParcel = this.getQuaantityParcel(carrinho);

        return (
            <div className="pagamento-cartao mt-3">
                <div className="row">
                    <Cards
                        number={number}
                        name={name}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focused}
                        callback={this.handleCallback}
                        placeholders={{name: 'My Rockfeller'}}
                        acceptedCards={['visa', 'mastercard', 'Hipercard']}
                    />
                </div>

                <form className="mt-3" ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input
                            type="tel"
                            name="number"
                            className="form-control"
                            placeholder="Número"
                            pattern="[\d| ]{16,22}"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Nome (como no cartão)"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="tel"
                                name="expiry"
                                className="form-control"
                                placeholder="Validade"
                                pattern="\d\d/\d\d"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="tel"
                                name="cvc"
                                className="form-control"
                                placeholder="CVC"
                                pattern="\d{3,4}"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                        </div>
                    </div>
                    <input type="hidden" name="issuer" value={issuer}/>
                    <div className="row mt-3">
                        <div className="col-12">
                            <select className="form-control" id="quantityParcels" name="quantityParcel" required
                                    onChange={this.handleInputChange}
                            >
                                <option value="0">Selecione a parcela</option>
                                {
                                    Array.apply(null, {length: quantityParcel}).map((i, index) => (
                                        (parseFloat(fixed) / (index + 1) > 45) ?
                                            <option key={index + 1}
                                                    value={index + 1}>{index + 1} X
                                                R$ {index > 100 ? (
                                                    this.getValueParcel(fixed, index)
                                                ) : (
                                                    //(parseFloat(fixed) / (index + 1)).toFixed(2).replace('.', ',') + ' Sem Juros'
                                                    (parseFloat(fixed) / (index + 1)).toFixed(2).replace('.', ',')
                                                )
                                                } </option>
                                            : null
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-actions mt-3">
                        {
                            (this.state.loadingPayment) ?
                                <div className="text-center"><Spinner/></div> :
                                <button type="submit" className="btn btn-success col-12">
                                    <FontAwesomeIcon icon={faMoneyCheck}/> Pagar
                                </button>
                        }
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            {/*Parcelamento acima de px será cobrado juros de 1%<br/>*/}
                            Total&nbsp;
                            {/*R${((parseFloat(carrinhoValor) + (parseFloat(carrinhoValor) * 0.01))).toFixed(2).replace('.', ',')}<br/><br/>*/}
                            R$ {parseFloat(carrinhoValor).toFixed(2).replace('.', ',')}<br/><br/>
                        </div>
                        <div className="col-12 text-center">
                            Bandeiras de cartão de crédito aceitas<br/>
                            <img width="200" src={bandeirasImg} alt="bandeiras"/>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        carrinho: state.ComprasReducer.carrinho,
        carrinhoValor: state.AppReducer.carrinhoValor,
        carrinhoValorFixed: state.AppReducer.carrinhoValorFixed,
        usuario: state.AppReducer.userData,
    }
)

export default connect(mapStateToProps, {pagamentoCartao, modificaCarrinhoItens})(Card)

