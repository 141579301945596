import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {financeiroUsuarioFetch} from "../../actions/FinaceiroAction"
import ParcelaDropDown from './ParcelaDropdown'


class Financeiro extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        this.props.financeiroUsuarioFetch(this.props.usuario.id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="financeiro-list">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="container">
                            <div className="card">
                                <div className="car-header">
                                    <h4 className="h4 pt-3">Financeiro</h4>
                                </div>
                                <div className="car-body">
                                    {
                                        (this.state.loadingCompleted) ?
                                            this.props.financeiro.map(parcel => (
                                                <ParcelaDropDown key={parcel.id} parcel={parcel}/>
                                            )): <Spinner/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        financeiro: state.FinanceiroReducer.financeiro,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {financeiroUsuarioFetch})(Financeiro)