import {
    MODIFICA_CONTEUDO_ONLINE,
    MODIFICA_LIVROS_CONTEUDO_ONLINE,
    MODIFICA_CONTEUDO_ONLINE_KIDS
} from '../actions/types'

const INITIAL_STATE = {
    conteudoOnline: [],
    conteudoOnlineKids: [],
    livro: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_CONTEUDO_ONLINE:
            return {...state, conteudoOnline: action.payload}
        case MODIFICA_CONTEUDO_ONLINE_KIDS:
            return {...state, conteudoOnlineKids: action.payload}
        case MODIFICA_LIVROS_CONTEUDO_ONLINE:
            return {...state, livro: action.payload}
        default:
            return state;
    }

}