import React, {Component} from 'react'
import {connect} from "react-redux"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingBasket} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
//
import Matricula from './Matricula'

class Body extends Component {

    _renderButon = () => {
        if (this.props.carrinho > 0) {
            return (
                <div className="card-footer">
                    <Link to="/carrinho" className="btn btn-md btn-info col-12">
                        <FontAwesomeIcon icon={faShoppingBasket}/> Ir para o carrinho
                    </Link>
                </div>
            )
        }
        return null;
    };

    render() {
        const franchiseBlock = [];
        let error = this.props.error;

        if (error && error[2] && error[2].status === 402)
            error = error[2].status;
        else if (error && error[2])
            error = error[2];
        else
            error = '';

        return (
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 comprar-material-pedagodico-body">
                <div className="container">

                    <div className="card">

                        <div className="card-header">
                            <h4>Materiais didáticos a serem comprados</h4>
                        </div>

                        <div className="card-body">
                            {
                                (!error) ? (
                                        (this.props.livrosAComprar.length > 0) ? (
                                            this.props.livrosAComprar.map((item) => (
                                                <Matricula key={item.enrollment.id} matricula={item.enrollment}
                                                           history={this.props.history}/>
                                            ))
                                            ): (
                                              <div className="alert alert-info" >Você não possuí um material a comprar</div>
                                        )
                                    )
                                    : (
                                        (error !== 402 ) ? (
                                            <div className="alert alert-warning">
                                                {error.data}
                                            </div>
                                        ) : (
                                            <div className="alert alert-info">
                                                <strong>Prezado Rock Student,</strong> <br/>
                                                Informamos que neste semestre, excepcionalmente, a compra do seu material
                                                didático
                                                deverá ser feita diretamente na recepção da escola.
                                            </div>
                                        )
                                    )

                            }
                        </div>

                        {this._renderButon()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        id: state.AppReducer.userData.id,
        livrosAComprar: state.ComprasReducer.livrosAComprar,
        carrinho: state.AppReducer.carrinhoValor,
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, {})(Body)