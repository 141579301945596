import React from "react"
import {Redirect, Route} from "react-router-dom"



const PrivateRoute = ({component: Component, isAuthenticated, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => (isAuthenticated === true) ? (<Component {...props} />) :
                (isAuthenticated === false ? (<Redirect to={"/login"}/>) : (<Redirect to={"/"}/>))
            }
        />
    )
}

export default PrivateRoute

