import React, {Component} from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from './../../services/utilsPayment';
import {formatMoney} from './../../services/dataFormat';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMoneyCheck} from '@fortawesome/free-solid-svg-icons';
import b64 from 'base-64';
//
import bandeirasImg from './../../assets/visa_mastercard_hipercard.png';
import {matriculaRenovacaoPagamentoCartaoFetch} from './../../actions/RenovacaoMatriculaAction';

class StepPagamentoCartao extends Component {

    state = {
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: '',
        issuer: '',
        loadingPayment: false
    }

    handleCallback = ({issuer}, isValid) => {
        if (isValid) {
            this.setState({issuer})
        }
    }

    handleInputFocus = ({target}) => {
        this.setState({
            focused: target.name,
        })
    }

    handleInputChange = ({target}) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value)
        } else if (target.name === 'quantityParcel') {
            target.value = (target.value > 0) ? target.value : 1
        }

        this.setState({[target.name]: target.value})
    }

    handleSubmit = async e => {
        const {matricula} = this.props;
        this.setState({loadingPayment: true});
        e.preventDefault()


        const formData = [...e.target.elements]
            .filter(d => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value
                return acc
            }, {})

        await this.props.matriculaRenovacaoPagamentoCartaoFetch(matricula.id, formData, this.props.escola)
            .then(success => {
                this.setState({loadingPayment: false})
            }).catch(
                error => {
                    if (error.response && error.response.data)
                        alert(error.response.data);
                    else
                        alert('Erro ao tentar realizar o pagamento, aguarde 5 minutos e tente novamente');
                    this.setState({loadingPayment: false})
                }
            )
        //this.form.reset()
    }

    render() {
        const {name, number, expiry, cvc, focused, issuer} = this.state;
        const {matricula} = this.props;
        return (
            <div className="pagamento-cartao mt-3">
                <div className="row border-bottom m-2">
                    <div className="col-12">
                        <h4 className="center text-info">Valor 12 X {formatMoney(matricula.value)}</h4>
                    </div>
                </div>
                <div className="row">
                    <Cards
                        number={number}
                        name={name}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focused}
                        callback={this.handleCallback}
                        placeholders={{name: 'My Rockfeller'}}
                        acceptedCards={['visa', 'mastercard', 'Hipercard']}
                    />
                </div>

                <form className="mt-3" ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input
                            type="tel"
                            name="number"
                            className="form-control"
                            placeholder="Número"
                            pattern="[\d| ]{16,22}"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Nome (como no cartão)"
                            required
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                        />
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="tel"
                                name="expiry"
                                className="form-control"
                                placeholder="Validade"
                                pattern="\d\d/\d\d"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                type="tel"
                                name="cvc"
                                className="form-control"
                                placeholder="CVC"
                                pattern="\d{3,4}"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                        </div>
                    </div>
                    <input type="hidden" name="issuer" value={issuer}/>
                    <div className="form-actions mt-3">
                        {
                            (this.state.loadingPayment) ?
                                <div className="text-center"><Spinner/></div> :
                                <button type="submit" className="btn btn-success col-12">
                                    <FontAwesomeIcon icon={faMoneyCheck}/> Pagar
                                </button>
                        }
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            Bandeiras de cartão de crédito aceitas<br/>
                            <img width="200" src={bandeirasImg} alt="bandeiras"/>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        matricula: state.RenovacaoMatriculaReducer.matriculaRenovacao,
        escola: state.RenovacaoMatriculaReducer.escola,
    }
)

export default connect(mapStateToProps, {matriculaRenovacaoPagamentoCartaoFetch})(StepPagamentoCartao)

