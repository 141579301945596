import React, {Component} from "react"
import {Route, Switch, BrowserRouter, HashRouter, Redirect} from "react-router-dom"
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {sessionService} from 'redux-react-session'
//
import {RESEARCH} from "../services/pesquisa";
import {TOKEN_KEY} from "../services/auth";
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import Perfil from '../pages/Perfil'
import AgendarAulaPresencial from '../pages/AgendarAulaPresencial'
import AgendarAulaPresencialHorarios from '../pages/AgendarAulaPresencialHorarios'
import ComprarMaterialPedagogico from "../pages/ComprarMaterialPedagogico"
import Carrinho from "../pages/Carrinho"
import Pagamento from "../pages/Pagamento"
import MinhasCompras from "../pages/MinhasCompras"
import Boletins from "../pages/Boletins"
import Financeiro from "../pages/Financeiro"
import Suporte from "../pages/Suporte"
import HorariosSuporte from "../pages/Suporte/components/HorariosSuporte"
import Notificacao from "../pages/Notificacao"
import Composition from "../pages/Composition"
import AtividadeComplementar from "../pages/AtividadeComplementar"
import ConteudoOnline from "../pages/ConteudoOnline"
import ConteudoOnlineLivro from "../pages/ConteudoOnlineLivro"
import ConteudoOnlineAtividade from "../pages/ConteudoOnlineAtividade"
import AtividadeComplementarLivro from "../pages/AtividadeComplementarLivro"
import AtividadeComplmenetarAtividade from "../pages/AtividadeComplmenetarAtividade"
import ConteudoOnlineKids from "../pages/ConteudoOnlineKids"
import ConteudoOnlineKidsAula from "../pages/ConteudoOnlineKidsAula"
import MaterialApoio from "../pages/MaterialApoio"
import WriteComposition from "../pages/Composition/WriteComposition"
import Pesquisa from "../pages/Pesquisa"
import RecuperarSenha from "../pages/RecuperarSenha"
import NovaSenha from "../pages/NovaSenha"
import Interection from "../pages/Interection"
import SolicitarRecisao from "../pages/SolicitarRecisao"
import RenovacaoMatricula from "../pages/RenovacaoMatricula"
import Certificados from "../pages/Certificados"
import PrivateRoute from "../components/PrivateRoute"
import {modificaUserData} from '../actions/AppAction'
import {getPermission} from '../services/checkPermission';


class Router extends Component {

    state = {
        loadingStateFinish: false
    }

    componentWillMount() {
        this.props.modificaUserData().then(() => {
            this.setState({loadingStateFinish: true})
        })
    }


    _verificaAcesso = (isAuthenticated, role) => {
        let acesso = true
        const permissions = getPermission(this.props.user);

        if (isAuthenticated)
            if (!(this.props.user.token !== null && this.props.user.token !== undefined))
                acesso = false

        if (role)
            if (permissions.indexOf(role) < 0)
                acesso = 'not_access';
        return acesso;
    }

    render() {
        if (this.state.loadingStateFinish) {
            return (
                <HashRouter basename="/">
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" component={Login}/>
                            <Route path="/resetting-password" component={RecuperarSenha}/>
                            <Route path="/create-password/:email/duo" component={RecuperarSenha}/>
                            <Route path="/user/:userId/type/:type/token/:token/password-change/" component={NovaSenha}/>

                            <PrivateRoute exact path="/" isAuthenticated={this._verificaAcesso(true)}
                                          component={Dashboard}/>
                            <PrivateRoute exact path="/perfil" isAuthenticated={this._verificaAcesso(true)}
                                          component={Perfil}/>
                            <PrivateRoute exact path="/agendar-aula-presencial"
                                          isAuthenticated={this._verificaAcesso(true, 'agendar-aula-presencial')}
                                          component={AgendarAulaPresencial}/>
                            <PrivateRoute exact path="/agendar-aula-presencial-horarios"
                                          isAuthenticated={this._verificaAcesso(true, 'agendar-aula-presencial')}
                                          component={AgendarAulaPresencialHorarios}/>

                            <PrivateRoute exact path="/comprar-material-pedagogico"
                                          isAuthenticated={this._verificaAcesso(true, 'comprar-material-pedagogico')}
                                          component={ComprarMaterialPedagogico}/>
                            <PrivateRoute exact path="/carrinho"
                                          isAuthenticated={this._verificaAcesso(true, 'comprar-material-pedagogico')}
                                          component={Carrinho}/>
                            <PrivateRoute exact path="/pagamento"
                                          isAuthenticated={this._verificaAcesso(true, 'comprar-material-pedagogico')}
                                          component={Pagamento}/>

                            <PrivateRoute exact path="/minhas-compras"
                                          isAuthenticated={this._verificaAcesso(true, 'minhas-compras')}
                                          component={MinhasCompras}/>

                            <PrivateRoute exact path="/boletins"
                                          isAuthenticated={this._verificaAcesso(true, 'boletins')}
                                          component={Boletins}/>

                            <PrivateRoute exact path="/financeiro"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={Financeiro}/>

                            <PrivateRoute exact path="/agendar-suporte"
                                          isAuthenticated={this._verificaAcesso(true, 'agendar-suporte')}
                                          component={Suporte}/>
                            <PrivateRoute exact path="/horario-suporte"
                                          isAuthenticated={this._verificaAcesso(true, 'agendar-suporte')}
                                          component={HorariosSuporte}/>

                            <PrivateRoute exact path="/notificacao"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={Notificacao}/>

                            <PrivateRoute exact path="/composition"
                                          isAuthenticated={this._verificaAcesso(true, 'composition')}
                                          component={Composition}/>
                            <PrivateRoute exact path="/composition/:id/write"
                                          isAuthenticated={this._verificaAcesso(true, 'composition')}
                                          component={WriteComposition}/>

                            <PrivateRoute exact path="/atividades-complementares"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={AtividadeComplementar}/>
                            <PrivateRoute exact path="/:id/atividades-complementares-livro"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={AtividadeComplementarLivro}/>
                            <PrivateRoute exact path="/:id/unit/:unit/atividades-complementares-atividade"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={AtividadeComplmenetarAtividade}/>

                            <PrivateRoute exact path="/material-de-apoio"
                                          isAuthenticated={this._verificaAcesso(true, 'material-de-apoio')}
                                          component={MaterialApoio}/>

                            <PrivateRoute exact path="/conteudo-online"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={ConteudoOnline}/>
                            <PrivateRoute exact path="/:id/conteudo-online-livro"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={ConteudoOnlineLivro}/>
                            <PrivateRoute exact path="/:id/aula/:idAtividade/na/:na/conteudo-online-atividade"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={ConteudoOnlineAtividade}/>
                            <PrivateRoute exact path="/conteudo-online-kids"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={ConteudoOnlineKids}/>
                            <PrivateRoute exact path="/:id/conteudo-online-kids-livro"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={ConteudoOnlineKidsAula}/>

                            <PrivateRoute exact path="/questionario"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={Pesquisa}/>
                            <PrivateRoute exact path="/interaction"
                                          isAuthenticated={this._verificaAcesso(true)}
                                          component={Interection}/>

                            <PrivateRoute
                                exact
                                path="/certificados"
                                isAuthenticated={this._verificaAcesso(true, 'certificados')}
                                component={Certificados}
                            />

                            <PrivateRoute exact path="/recisao"
                                          isAuthenticated={this._verificaAcesso(true, 'administrativo')}
                                          component={SolicitarRecisao}/>
                            <Route exact path="/renovacao-matricula"
                                   component={RenovacaoMatricula}/>

                            <Route exact path="/exit"
                                   component={() => {
                                       localStorage.removeItem(RESEARCH);
                                       localStorage.removeItem(TOKEN_KEY)
                                       localStorage.removeItem(RESEARCH)
                                       sessionService.deleteSession()
                                       sessionService.deleteUser()
                                       return (
                                           <Redirect to='/login'/>
                                       );
                                   }}/>

                            <Route path="*" component={event => {
                                alert('Pagina não encontrada!');
                                return (
                                    <Redirect to='/'/>
                                );
                            }}/>
                        </Switch>
                    </BrowserRouter>
                </HashRouter>
            )
        } else {
            return (<Spinner/>)
        }
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData,
    }
)

export default connect(mapStateToProps, {modificaUserData})(Router)
