import React, {Component} from 'react'
import {
    faCircle
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


export default class Suporte extends Component {
    render() {
        return (
            <div className="row" key={this.props.suporte.id}>
                <div className="col-1">
                    <FontAwesomeIcon icon={faCircle} color="yellow"/>
                </div>
                <div className="col-10">
                    <p>
                        <span>{this.props.suporte.date} - {this.props.suporte.startTime} às {this.props.suporte.endTime}</span>
                        <br/>Professor(a): {this.props.suporte.teacher}
                    </p>
                </div>
            </div>
        )
    }
}