import React, {Component} from 'react'
import {connect} from "react-redux"
import CKEditor from 'ckeditor4-react'
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperPlane, faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
//
import {compositionsSalvarParcial, compositionsEnviarProfessor} from './../../../actions/CompositionsAction'
import _ from "lodash";
import {logoutUsuario} from '../../../actions/LoginAction';


class Editor extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: '<p></p>',
            salvando: false,
            dataBefore: '<p></p>',
            key: '',
            palavras: 0,
            error: '',
        };

        this.onEditorChange = this.onEditorChange.bind(this);
        //save in local storage
        setInterval(() => {
            if (this.state.data !== this.state.dataBefore && this.state.salvando === false) {
                let text = this.state.data;
                text = this.cleanString(text);

                localStorage.setItem(this.state.key, text);
            }
        }, 5000)

        // Save in server
        setInterval(() => {
            if (this.state.data !== this.state.dataBefore && this.state.salvando === false) {
                this.setState({salvando: true});
                let text = this.state.data;
                text = this.cleanString(text);

                this.props.compositionsSalvarParcial(this.props.usuario.id, this.props.composition.id, text, this.props.composition.courseClass.type).then(
                    () => {
                        this.setState({salvando: false})
                        this.setState({dataBefore: this.state.data})
                    }
                ).catch(
                    error => {
                        if (error && _.map(error).length > 1 && _.map(error)[2] && _.map(error)[2].status === 401) {
                            this.props.modificarUsuario('');
                            this.props.modificarSenha('');
                            this.props.logoutUsuario();
                            alert('Sua sessão expirou, faça o login novamente!');
                            this.props.history.push('/');
                        } else if (error.response && error.response.data) {
                            if (typeof error.response.data === 'string')
                                alert(error.response.data);
                            else
                                alert('Remova os emojis presentes no texto e tente novamente!')
                        } else
                            alert('Falha ao tentar salvar a composition!');

                        this.setState({salvando: false})
                        this.setState({dataBefore: this.state.data})
                    }
                )
            }

        }, 45000)
    }

    cleanString = input => {
        var output = "";
        var lastChar = "";
        const normalChars = "a-zA-Z0-9\\s`~!@#$%^&*()_+-={}|:;<>?,.àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ\/\"\'\\\\[\\]";
        for (var i=0; i<input.length; i++) {
            if ((input.charCodeAt(i) <= 127 || normalChars.includes(input.charAt(i)) !== false) && (lastChar !== '  ' || input.charAt(i) !== '  ')) {
                output += input.charAt(i);
                lastChar = input.charAt(i);
            }
        }
        return output;
    }

    _removeEmoji = str => {
        let text = str;
        text = text.replace('\ud83d', '');
        text = text.replace('\ud83C', '');
        text = text.replace('\udff3', '');
        text = text.replace('\ufffd', '');
        text = text.replace('\ufe0f', '');
        text = text.replace('\udde6', '');
        text = text.replace('\udde9', '');
        text = text.replace('\udd28', '');
        text = text.replace('\uddee', '');
        text = text.replace('\ude48', '');
        text = text.replace('\ude0a', '');
        text = text.replace('\udd14', '');
        text = text.replace('\ud83e', '');
        text = text.replace('\udc8b', '');
        text = text.replace('\ude05', '');
        text = text.replace('\udf19', '');
        text = text.replace('\ude00', '');
        text = text.replace('\ude03', '');
        text = text.replace(/\uFFFD/g, '');
        text = text.replace('�', '');
        text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        return text;
    };

    onEditorChange(evt) {
        this.setState({
            data: evt.editor.getData()
        });

        let text = this.state.data;
        text = this._removeEmoji(text);

        this.setQuantidadePalavras(text);
    }

    async componentWillMount() {
        const key = `@joy-composition-${this.props.composition.id}-${this.props.usuario.id}`;
        if (this.props.composition && this.props.composition.id) {
            this.setState({key: key})
        }

        if (this.props.composition && this.props.composition.composition && this.props.composition.composition.text) {
            await this.setState({data: this.props.composition.composition.text})
            this.setState({dataBefore: this.props.composition.composition.text})
        } else if (localStorage.getItem(key)) {
            const text = localStorage.getItem(key);
            await this.setState({data: text});
            this.setState({dataBefore: text})
        } else if (this.props.composition && this.props.composition.composition && this.props.composition.composition.text) {
            this.setState({data: this.props.composition.composition.text})
            this.setState({dataBefore: this.props.composition.composition.text})
        }

        if (this.state.data) {
            const text = this.state.data;
            await this.setQuantidadePalavras(text);
        }
    }

    setQuantidadePalavras = text => {
        let palavras = text;
        palavras = palavras.replace(/(<([^>]+)>)/ig, " ");
        palavras = palavras.replace('&nbsp;', ' ');
        palavras = palavras.split(' ');
        let cont = 0;
        palavras.map(p => {
            if (p.length >= 2)
                cont++
        });
        this.setState({palavras: cont});
    };

    _enviarProfessor = () => {

        confirmAlert({
            title: `Tem certeza de que deseja enviar sua composition agora?`,
            message: `Esta ação não pode ser desfeita`,
            buttons: [
                {
                    label: 'Enviar agora',
                    onClick: () => {
                        this.setState({salvando: true});
                        let text = this.state.data;
                        text = this._removeEmoji(text);
                        this.props.compositionsEnviarProfessor(this.props.usuario.id, this.props.composition.id, text, this.props.composition.courseClass.type).then(
                            () => {
                                this.setState({salvando: false})
                                localStorage.removeItem(this.state.key)
                                this.props.history.push('/composition')
                            }
                        ).catch(
                            error => {
                                if (error && _.map(error).length > 1 && _.map(error)[2] && _.map(error)[2].status === 401) {
                                    this.props.modificarUsuario('');
                                    this.props.modificarSenha('');
                                    this.props.logoutUsuario();
                                    alert('Sua sessão expirou, faça o login novamente!');
                                    this.props.history.push('/');
                                } else if (error.response && error.response.data)
                                    alert(error.response.data);
                                else
                                    alert('Falha ao tentar enviar para o professor!')
                                this.setState({salvando: false})
                            }
                        )
                    }
                },
                {
                    label: 'Enviar depois',
                    onClick: () => {
                    }
                }
            ]
        })

    };

    _renderCKEditor = () => {
        return (
            <div className="editor">
                <CKEditor
                    data={this.state.data}
                    onChange={this.onEditorChange}
                    config={
                        {
                            toolbar: [
                                ['fontSize'],
                                ['NumberedList', 'BulletedList'],
                                ['Indent', 'Outdent'],
                                ['Link', 'Unlink'],
                                ['About']
                            ],
                            contentsCss: ["body {font-size: 15pt;}"],
                        }
                    }
                />

                {
                    (!this.props.composition.idComposition || parseInt(this.props.composition.statusComposition) === 1) ?
                        <div className='col-12 text-left mt-3 mb-3 text-right'>
                            <small className="text-muted mt-3 mb-3"> Contador de palavras: {this.state.palavras}</small>
                        </div> : null
                }

                {
                    (this.state.salvando) ? <div className="badge bg-light text-center col-12 align-middle">
                            <span><Spinner color="green"/> Salvando</span>
                        </div> :
                        <button type="button" className="btn btn-orange btn-fw waves-effect w-100"
                                onClick={this._enviarProfessor}>
                            <FontAwesomeIcon icon={faPaperPlane}/> Enviar para o professor
                        </button>
                }
            </div>
        )
    }

    _renderCompositionResult = () => (
        <div className="composition-result">
            <h4><strong>Nota: {this.props.composition.composition.nota} </strong></h4>
            <hr/>
            {/*<div className="row text-left">
                <div className="col-6">
                    <small className="small">
                        <strong>Development: </strong> {this.props.composition.composition.notaDevelopment} <br/>
                        <strong>Grammar: </strong> {this.props.composition.composition.notaGrammar}
                    </small>
                </div>
                <div className="col-6 text-left">
                    <small>
                        <strong>Vocabulary: </strong> {this.props.composition.composition.notaVocabulary}<br/>
                        <strong>Spelling: </strong> {this.props.composition.composition.notaSpelling}
                    </small>
                </div>
            </div>*/}
        </div>
    )

    componentDidMount() {
        let list, i
        list = document.querySelectorAll('.textEditor a')

        for (i = 0; i < list.length; ++i) {
            list[i].removeAttribute('href');

            const title = list[i].getAttribute('title')
            list[i].addEventListener('click', () => {
                document.getElementById('content-observation').classList.add('p-3')
                document.getElementById('content-observation').innerHTML = title
            })

            list[i].addEventListener('mouseout', () => {
                document.getElementById('content-observation').classList.remove('p-3')
                document.getElementById('content-observation').innerHTML = ''
            })

        }
    }

    render() {
        return (
            <div className="App">
                {(this.props.composition.idComposition && parseInt(this.props.composition.statusComposition) === 3 && (
                    !this.props.composition.isNewComposition || this.props.composition.isValidComposition
                )) ?
                    (this._renderCompositionResult()) : null
                }
                {
                    (this.props.composition.idComposition && (parseInt(this.props.composition.statusComposition) === 2 || parseInt(this.props.composition.statusComposition) === 4)) ?
                        <div className="alert alert-warning">Aguardando correção do professor.</div> : null
                }

                <div className="text-left">
                    <hr/>
                    {
                        (this.props.composition.idComposition && parseInt(this.props.composition.statusComposition) === 3) ?
                            <div className="border p-3 col-12">
                                <div contentEditable="false" className="textEditor w-90" id="texto_corrigido"
                                     dangerouslySetInnerHTML={{__html: this.props.composition.composition.textCorrection}}
                                >
                                </div>
                            </div> : (this.props.composition.idComposition && (parseInt(this.props.composition.statusComposition) === 2 || parseInt(this.props.composition.statusComposition) === 4)) ?
                            <div contentEditable="false" className="textEditor" id="texto_corrigido"
                                 dangerouslySetInnerHTML={{__html: this.props.composition.composition.text}}
                            >
                            </div>
                            : this._renderCKEditor()
                    }
                    <div className="alert-info" id="content-observation">

                    </div>
                </div>
                {(this.props.composition.idComposition && parseInt(this.props.composition.statusComposition) === 3) ?
                    <div className="row strong h5 border ml-1 mr-1 mt-3 p-3 alert alert-warning">
                        Observação: {this.props.composition.composition.observation}
                    </div> : null
                }
                <Link to="/composition" className="btn w-100 btn-secondary btn-fw waves-effect mt-3">
                    <FontAwesomeIcon icon={faAngleLeft}/> Voltar
                </Link>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {logoutUsuario, compositionsSalvarParcial, compositionsEnviarProfessor})(Editor)



