import React from "react"
import "react-notifications-component/dist/theme.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
//
import './css.css';

export default class NotificacaoInfo extends React.Component {

    _getNow = () => {
        const date = new Date()
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {notificacao} = this.props;
        return (
            <div className="notificacao-info col-12 mt-1 mb-3">
                <div className={(notificacao.date_BR === this._getNow()) ? "row bg border-left-green" : " row bg border-left-red"}>
                    <div className="col-8 text-left">
                        <div className="col-12">
                           <strong> {notificacao.title}</strong>
                        </div>
                        <div className="col-12">
                            {notificacao.description}
                        </div>
                    </div>
                    <div className="col-4 text-right">
                        <Link to={notificacao.url}>
                            <FontAwesomeIcon icon={faAngleRight} size="32" color="#ddd"/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}