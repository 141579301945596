import React from "react"
import {connect} from "react-redux"
import "react-notifications-component/dist/theme.css"
//
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import NotificacaoInfo from './NotificacaoInfo'


class Notificacao extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="suporte-list dashboard">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="row">
                            <div className="container">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="h4 pt-3">Minhas notificações</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                this.props.notificacaoList ? (
                                                    this.props.notificacaoList.length > 0 ?(
                                                    this.props.notificacaoList.map((item) => (
                                                        <NotificacaoInfo key={item.id} notificacao={item}/>
                                                    ))) : (
                                                    <div className="alert alert-warning text-center col-12">Nenhuma notificação disponivél</div>
                                                    )
                                                ): null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => (
    {
        notificacaoList: state.AppReducer.notificacaoList,
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, null)(Notificacao)