import React, {Component} from 'react'
import {connect} from "react-redux"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBook, faShoppingBasket} from '@fortawesome/free-solid-svg-icons'
//
import {adicionarCarrinho, removerCarrinho} from './../../../actions/ComprasAction'
import {modificaCarrinhoItens} from './../../../actions/AppAction'


class Produto extends Component {

    state = {
        adicionadoAoCarrinho: false
    }

    componentWillMount() {
        this.props.carrinho.map((item) => {
            if(item.id === this.props.produto.id && item.enrollment === this.props.produto.enrollment)
                this.setState({adicionadoAoCarrinho: true})
        })
    }

    _renderButon() {

        if (this.state.adicionadoAoCarrinho) {
            return (
                <button type="button" className="btn btn-warning"
                        onClick={
                            () => {
                                this.props.removerCarrinho(this.props.produto)
                                this.props.modificaCarrinhoItens(this.props.carrinho, this.props.user)
                                this.setState({adicionadoAoCarrinho: false})
                            }
                        }
                >
                    <i><FontAwesomeIcon icon={faShoppingBasket}/></i> Remover do carrinho
                </button>
            )
        }
        return (
            <button type="button" className="btn btn-default bg-light"
                    onClick={
                        () => {
                            this.props.adicionarCarrinho(this.props.produto)
                            this.props.modificaCarrinhoItens(this.props.carrinho, this.props.user)
                            this.setState({adicionadoAoCarrinho: true})
                            //this.props.history.push('/carrinho')
                        }
                    }
            >
                <i><FontAwesomeIcon icon={faShoppingBasket}/></i> Adicionar ao carrinho
            </button>
        )
    }

    render() {
        const {produto} = this.props;
        return (
            <div className="col-xs-3 col-sm-6 col-md-3">
                <div className="card-body">
                    <h1>
                        <i><FontAwesomeIcon icon={faBook}/></i>
                    </h1>

                    <h4 className="notranslate" translate="no">{produto.name}</h4>
                    <h4>
                        <span className="text-orange small">{produto.price}</span><br/>
                    </h4>

                    {this._renderButon()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        carrinho: state.ComprasReducer.carrinho,
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, {adicionarCarrinho, removerCarrinho, modificaCarrinhoItens})(Produto)