import React from 'react'
import Link from "react-router-dom/Link";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
//
import {connect} from "react-redux"
import {matriculaRenovacaoPagamentoFetch} from "../../actions/RenovacaoMatriculaAction";
import {formatMoney} from "../../services/dataFormat";

class StepPagamento extends React.Component {

    getDate = str => {
        if (!str || str === "")
            return '';

        const date_str = str.split('-');
        const date = new Date(parseInt(date_str[0]), parseInt((date_str[1] - 1)), date_str[2]);
        return date;
    }

    render() {
        const {matricula, erro} = this.props;

        const startContract = this.getDate(matricula.startContract);
        const fisrtPayment = this.getDate(matricula.firstPayment);

        return (
            <div className="content h-100">
                <div
                    className="content-body h-75 d-flex flex-column justify-content-center align-middle align-items-cente">
                    {
                        (matricula && matricula.enrollment && !erro) ? (
                            <div className="row">
                                <div className="col-12 font-text-strong-renovacao">
                                    <h4>O seu contrato foi renovado com sucesso.</h4><br/><br/>
                                </div>
                                <div className="col-12 font-text-renovacao">
                                    <h6 className="text-center">Início
                                        em {startContract ? startContract.toLocaleDateString(): ''}, com o primeiro
                                        vencimento em {fisrtPayment ? fisrtPayment.toLocaleDateString() : ''} no valor
                                        de {formatMoney(matricula.value)}</h6>
                                </div>
                                {
                                    parseInt(matricula.status) === 8 && (
                                        <div className="col-12 font-text-renovacao">
                                            <h6 className="text-center">
                                                É importante lembrar que você só poderá iniciar seus estudos depois que
                                                fizer o nivelamento.<br/>
                                                Se desejar fazer o nivelamento agora, <a
                                                href={`https://matricula.rockfeller.com.br/nivelamento.php?email-aluno=${matricula.email}&sala=AD0C67`}>Clique
                                                aqui</a>.
                                            </h6>
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-12 font-text-strong-renovacao">
                                    <span>Ocorreu um erro durante a renovação.</span><br/><br/>
                                </div>
                                <div className="col-12 font-text-renovacao">
                                    <h4 className="text-info">{erro}</h4>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        matricula: state.RenovacaoMatriculaReducer.matriculaRenovacao,
        pagamento: state.RenovacaoMatriculaReducer.pagamento,
        erro: state.RenovacaoMatriculaReducer.erro,

    }
)

export default connect(mapStateToProps, {matriculaRenovacaoPagamentoFetch})(StepPagamento)


