import api from "../services/api";
import {
    SET_MATRICULA_RENOVACAO,
    SET_STEP_MATRICULA_RENOVACAO,
    SET_STEP_MATRICULA_ERRO,
    SET_STEP_ESCOLA,
    SET_ESCOLAS_FORM,
} from "./types";

export const matriculaRenovacaoUsuarioFetch = () => dispatch =>
    api.get('/my-enrollment-to-renew').then(
        matricula => {
            return dispatch({type: SET_MATRICULA_RENOVACAO, payload: matricula.data});
        }
    )

export const matriculaRenovacaoPagamentoFetch = (id, franchiseId) => dispatch =>
    api.post(`/${id}/renew-enrollment-duo`, {'paymentType': 'BOLETO', 'franchiseId': franchiseId}).then(
        res => {
            dispatch({type: SET_MATRICULA_RENOVACAO, payload: res.data})
            return dispatch({type: SET_STEP_MATRICULA_RENOVACAO, payload: 5});
        }
    ).catch(
        e => {
            dispatch({type: SET_STEP_MATRICULA_ERRO, payload: e.data});
            return dispatch({type: SET_STEP_MATRICULA_RENOVACAO, payload: 5});
        }
    )

export const matriculaRenovacaoPagamentoCartaoFetch = (id,data, franchiseId) => dispatch =>
    api.post(`/${id}/renew-enrollment-duo`, {'paymentType': 'CARTAO_DE_CREDITO', 'data': data, 'franchiseId': franchiseId}).then(
        res => {
            dispatch({type: SET_MATRICULA_RENOVACAO, payload: res.data})
            return dispatch({type: SET_STEP_MATRICULA_RENOVACAO, payload: 5});
        }
    ).catch(
        e => {
            dispatch({type: SET_STEP_MATRICULA_ERRO, payload: e.data});
            return dispatch({type: SET_STEP_MATRICULA_RENOVACAO, payload: 5});
        }
    )

export const loadEscolasAction = () => dispatch =>
    api.get(`/franchises-active`).then(
        res => dispatch({type: SET_ESCOLAS_FORM, payload: res.data})
    )
export const setStepAction = (step) => ({
    type: SET_STEP_MATRICULA_RENOVACAO,
    payload: step
});

export const setEscolaAction = id => ({
    type: SET_STEP_ESCOLA,
    payload: id
});

export const pagamentoCartaoAction = () => ({
    type: SET_STEP_MATRICULA_RENOVACAO,
    payload: 4
});