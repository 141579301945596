import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import _ from 'lodash'
//
import {logoutUsuario, modificarSenha, modificarUsuario} from '../../actions/LoginAction'
import {matriculaRenovacaoUsuarioFetch} from '../../actions/RenovacaoMatriculaAction';
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import StepContrato from '../../components/RenovacaoDuoSteps/StepContrato';
import StepEscola from '../../components/RenovacaoDuoSteps/StepEscola';
import StepPagamento from '../../components/RenovacaoDuoSteps/StepPagamento';
import StepPagamentoConcluido from '../../components/RenovacaoDuoSteps/StepPagamentoConcluido';
import './styles.css'
import StepPagamentoCartao from "../../components/RenovacaoDuoSteps/StepPagamentoCartao";

class RenovacaoMatricula extends Component {

    state = {
        step: 1,
        contrato: false,
        loadingCompleted: false,
    }

    componentWillMount() {
        this.setState({loadingCompleted: false})
        this.props.matriculaRenovacaoUsuarioFetch().then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            error => {
                this.setState({loadingCompleted: true});
                if (error && _.map(error).length > 1 && _.map(error)[2] && _.map(error)[2].status === 401) {
                    this.props.modificarUsuario('')
                    this.props.modificarSenha('')
                    this.props.logoutUsuario()
                    //alert('Sua sessão expirou, faça o login novamente!')
                    window.location.reload()
                }
            }
        )
    }


    render() {
        const {step} = this.props;
        return (
            <div id="renovacao-matricula">
                {this.props.usuario.id && <Header/>}
                <div className="row h-100">
                    {this.props.usuario.id && <Menu/>}
                    <div className={this.props.usuario.id ? "col-12 col-md-8 col-lg-9 col-xl-10" : "col-12"}>
                        <div className="card h-100">
                            <div className="card-header">
                                <h4 className="h4">{this.props.usuario.id ? 'Renovação de matrícula' : 'Bem Vindo de volta'}</h4>
                            </div>
                            <div className="card-body h-100">
                                {
                                    this.state.loadingCompleted ? (
                                        (this.props.matricula) ? (
                                            parseInt(step) === 1 ? (<StepContrato/>) : (
                                                parseInt(step) === 2 ? (<StepEscola/>) : (
                                                    parseInt(step) === 3 ? (<StepPagamento/>) : (
                                                        parseInt(step) === 4 ? (<StepPagamentoCartao/>) :
                                                            (<StepPagamentoConcluido/>)
                                                    )
                                                )
                                            )
                                        ) : (
                                            <div className="alert alert-warning">Você não possuí contrato a
                                                renovar!</div>
                                        )
                                    ) : (
                                        <div className="center"><Spinner/></div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        matricula: state.RenovacaoMatriculaReducer.matriculaRenovacao,
        step: state.RenovacaoMatriculaReducer.step

    }
)

export default connect(mapStateToProps, {matriculaRenovacaoUsuarioFetch, modificarUsuario, logoutUsuario, modificarSenha})(RenovacaoMatricula)



