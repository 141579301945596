import api from './../services/api'
import {
    MODIFICA_MINHAS_COMPRAS,
} from '../actions/types'

export const comprasUsuarioFetch = (idUsuario) => dispatch =>
    api.post('/student/buy-material/my-purchases', {_id: idUsuario}).then(
        compras => {
            return dispatch({type: MODIFICA_MINHAS_COMPRAS, payload: compras.data})
        }
    )