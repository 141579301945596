import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {atividadesLivrosUsuarioFetch} from './../../actions/AtividadeComplementarAction'


class AtividadeComplementarLivro extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        const id = parseInt(this.props.match.params.id)
        this.props.atividadesLivrosUsuarioFetch(this.props.usuario.id, id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            error => {
                if (error.response && error.response.data )
                    alert(error.response.data)
                else
                    alert('Falha ao tentar acessar as aulas do livro!')
                this.props.history.push('/atividades-complementares')
            }
        )
    }

    render() {
        return (
            <div className="conteudo-online-livro">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        {
                            (this.state.loadingCompleted) ?
                                <div className="card">
                                    <div className="card-header text-left">
                                        <div className="float-left mr-3">
                                            <img className="img-thumbnail img-zoom"
                                                 src={this.props.aulas.livro.miniaturaSrc} width="70px" height="70px"/>
                                        </div>
                                        <div className="div p-3">
                                            <strong className="text-info strong">{this.props.aulas.livro.nome}</strong>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row conteudo-atividade-complementar">
                                            {
                                                (this.props.aulas.livro.file) ?
                                                    <div className="alert alert-info">
                                                        <small style="color: grey">Este livro possui um CD anexado, para
                                                            obtê-lo clique em
                                                        </small>
                                                        <a className="btn btn-sm btn-default"
                                                           href=""
                                                           title="Download">
                                                            <FontAwesomeIcon icon={faDownload}/> Faça o
                                                            download </a>
                                                    </div> : null
                                            }
                                            {
                                                this.props.aulas.aulas.map(item => (
                                                    <div key={item.aula.unit} className="col-md-3 col-sm-12 fade-in">
                                                        <Link to={`/${this.props.aulas.livro.id}/unit/${item.aula.unit}/atividades-complementares-atividade`}>
                                                            <img className="img-thumbnail" src={item.aula.img}/>
                                                        </Link>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div> : <div className="w-100 text-center"><Spinner/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        aulas: state.AtividadeComplementarReducer.aulas,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {atividadesLivrosUsuarioFetch})(AtividadeComplementarLivro)



