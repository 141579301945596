import {
    MODIFICA_USUARIO,
    MODIFICA_SENHA,
    LOGIN_EM_ANDAMENTO,
    LOGIN_ERROR_MESSAGE,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    RECOVER_PASSWORD
} from '../actions/types';

const INITIAL_STATE = {
    usuario: '',
    senha: '',
    erroLogin: '',
    loading_login: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_USUARIO:
            return {...state, usuario: action.payload}
        case MODIFICA_SENHA:
            return {...state, senha: action.payload}
        case LOGIN_EM_ANDAMENTO:
            return {...state, loading_login: action.payload}
        case LOGIN_ERROR_MESSAGE:
            return {...state, erroLogin: action.payload}
        case LOGIN_SUCCESS:
            return action.payload
        case LOGOUT_SUCCESS:
            return action.payload
        case RECOVER_PASSWORD:
            return action.payload
        default:
            return state;
    }

}
