import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session'
//
import LoginReducer from './LoginReducer'
import AppReducer from './AppReducer'
import AgendarAulaPresencialReducer from './AgendarAulaPresencialReducer'
import ComprasReducer from './ComprasReducer'
import PagamentoReducer from './PagamentoReducer'
import MinhasComprasReducer from './MinhasComprasReducer'
import BoletinsReducer from './BoletinsReducer'
import FinanceiroReducer from './FinanceiroReducer'
import SuporteReducer from './SuporteReducer'
import CompositionReducer from './CompositionReducer'
import AtividadeComplementarReducer from './AtividadeComplementarReducer'
import ConteudoOnlineReducer from './ConteudoOnlineReducer'
import MaterialApioReducer from './MaterialApioReducer'
import RenovacaoMatriculaReducer from './RenovacaoMatriculaReducer'

export default combineReducers({
    sessionReducer,
    LoginReducer,
    AppReducer,
    AgendarAulaPresencialReducer,
    ComprasReducer,
    PagamentoReducer,
    MinhasComprasReducer,
    BoletinsReducer,
    FinanceiroReducer,
    SuporteReducer,
    CompositionReducer,
    AtividadeComplementarReducer,
    ConteudoOnlineReducer,
    MaterialApioReducer,
    RenovacaoMatriculaReducer
});