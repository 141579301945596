import React from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDoorOpen, faDoorClosed} from '@fortawesome/free-solid-svg-icons'


export default class AvaliacaoComposition extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {item} = this.props

        return (
            <tr key={item.id}>
                <th>{item.data.bookClass}</th>
                <th className={(item.nota >= 20 * 0.7) ? "text-info" : "text-warning"}>{(item.nota) ? parseFloat(item.nota).toFixed(2).replace('.', ',') : 0}</th>
                <th>
                    {/*
                     <span className="float-right text-black-50" onClick={() => this.toggle()}>
                        <FontAwesomeIcon icon={(this.state.dropdownOpen) ? faDoorOpen : faDoorClosed}/>
                    </span>
                */}
                </th>

                <Modal centered isOpen={this.state.dropdownOpen} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>
                        <p className="h4 strong">{item.data.courseClass.id}({item.data.courseClass.bookName})
                            - {item.data.bookClass}
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-8 h5">
                                <strong>
                                    Development
                                </strong>
                            </div>
                            <div className="col-4 h5">
                                        <span
                                            className={(item.notaDevelopment >= 20 * 0.7) ? "text-info" : "text-warning"}>
                                        {item.notaDevelopment}
                                        </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 h5">
                                <strong>
                                    Grammar
                                </strong>
                            </div>
                            <div className="col-4 h5">
                                        <span className={(item.notaGrammar >= 20 * 0.7) ? "text-info" : "text-warning"}>
                                        {item.notaGrammar}
                                        </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 h5">
                                <strong>
                                    Spelling
                                </strong>
                            </div>
                            <div className="col-4 h5">
                                        <span
                                            className={(item.notaSpelling >= 20 * 0.7) ? "text-info" : "text-warning"}>
                                        {item.notaSpelling}
                                        </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 h5">
                                <strong>
                                    Vocabulary
                                </strong>
                            </div>
                            <div className="col-4 h5">
                                        <span
                                            className={(item.notaVocabulary >= 20 * 0.7) ? "text-info" : "text-warning"}>
                                        {item.notaVocabulary}
                                        </span>
                            </div>
                        </div>
                        <div className="row bg-secondary text-light strong">
                            <div className="col-12 text-center ">
                                {item.observation}
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </tr>
        )
    }
}