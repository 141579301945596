import api from './../services/api'
import {
    MODIFICA_LIVROS_MATERIAL_APOIO,
    MUSICS_CD
} from '../actions/types'

export const materialApoioAudio = () => dispatch =>
    api.get('/material/apoio/audio').then(
        resp => {
            return dispatch({type: MODIFICA_LIVROS_MATERIAL_APOIO, payload: resp.data})
        }
    );

export const listMusics = (cd, musics) => dispatch =>
    api.post('/cd/list-audios', {id: cd}).then(
        resp => {
            musics[cd] = resp.data;
            return dispatch({type: MUSICS_CD, payload: musics})
        }
    );