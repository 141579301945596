import React, {Component} from 'react'
import axios from "axios";
import {connect} from "react-redux";
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import api from '../../services/api.js';
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {cepMask, maskCelular} from '../../services/mask';
import {setDataStudent} from '../../actions/AppAction';

class Perfil extends Component {

    state = {
        celular: '',
        cep: '',
        cepBefore: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
        isSeted: false,
        bairroIsSeted: false,
        loading: true,
        message: '',
        erro: '',
    }

    componentDidMount() {
        const {usuario} = this.props;
        api.get(`/student/${usuario.id}/info`).then(
            res => {
                const {cellphone, zipCode, city, complement, district, number, street, uf} = res.data;
                this.setState({
                    celular: cellphone,
                    cep: zipCode,
                    rua: street,
                    numero: number,
                    complemento: complement,
                    bairro: district,
                    cidade: city,
                    uf: uf,
                    loading: false,
                });
            }
        )
    }

    handleInputChange = ({target}) => {
        if (target.name === 'cep') {
            target.value = cepMask(target.value);
        } else if (target.name === 'telefone') {
            target.value = maskCelular(target.value);
        }

        this.setState({[target.name]: target.value})
    }

    getDataCep = () => {
        const {cep, cepBefore} = this.state;

        if (cep && cep !== cepBefore) {
            axios.get(`https://viacep.com.br/ws/${cep}/json`).then(
                res => {
                    const {bairro, localidade, uf} = res.data;
                    this.setState({
                        cepBefore: this.state.cep,
                        bairro,
                        cidade: localidade,
                        uf,
                        isSeted: true,
                        bairroIsSeted: ((bairro) ? true : false),
                    });
                }
            );
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {setDataStudent, usuario} = this.props;

        this.setState({loading: true, message: '', erro: ''});

        setDataStudent(usuario.id, {
            cellphone: this.state.celular,
            zipCode: this.state.cep,
            street: this.state.rua,
            number: this.state.numero,
            complement: this.state.complemento,
            district: this.state.bairro,
            city: this.state.cidade,
            uf: this.state.uf,
        }).then(
            () => {
                this.setState({loading: false, message: 'Seus dados foram alterados com sucesso!'})
            }
        ).catch(
            e => {
                this.setState({loading: false, erro: 'Houve um erro ao tentar alterar seus dados.'})
            }
        )
    }

    render() {
        return (
            <div className="agendar-aula-presencial">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10 carrinho-body">
                        <div className="container">
                            {
                                this.state.loading ? (
                                    <div className="text-center col-12">
                                        <Spinner/>
                                    </div>
                                ) : (
                                    <div className="card text-left">

                                        <div className="card-header text-center">
                                            <div className="row">
                                                <div className="col-10">
                                                    <h4>Editar Perfil</h4>
                                                    {
                                                        this.state.message && (
                                                            <p className="text-success">{this.state.message}</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">

                                            <form method="POST" onSubmit={this.handleSubmit} className="form">

                                                <div className="well">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h4>Informações de contato</h4>
                                                        </div>
                                                        <div className="col-12">
                                                            <label>Telefone</label>
                                                            <input
                                                                value={this.state.telefone}
                                                                onChange={this.handleInputChange}
                                                                className="form-control"
                                                                name="telefone"
                                                                type="text"
                                                                maxLength="14"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="well">

                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <h4>Endereço</h4>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-12">
                                                            <label>Cep</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.getDataCep}
                                                                value={this.state.cep}
                                                                className="form-control"
                                                                name="cep"
                                                                type="text"
                                                                maxLength="9"
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-8">
                                                            <label>Rua</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onFocus={this.getDataCep}
                                                                value={this.state.rua}
                                                                className="form-control"
                                                                name="rua"
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-4">
                                                            <label>Número</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onFocus={this.getDataCep}
                                                                value={this.state.numero}
                                                                className="form-control"
                                                                name="numero"
                                                                type="number"
                                                                max="99999"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-12">
                                                            <label>Complemento</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onFocus={this.getDataCep}
                                                                value={this.state.complemento}
                                                                className="form-control"
                                                                name="complemento"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 mb-2">

                                                        <div className="col-12">
                                                            <label>Bairro</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onFocus={this.getDataCep}
                                                                value={this.state.bairro}
                                                                readOnly={this.state.bairroIsSeted}
                                                                className="form-control"
                                                                name="bairro"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-6">
                                                            <label>Cidade</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onFocus={this.getDataCep}
                                                                value={this.state.cidade}
                                                                readOnly
                                                                className="form-control"
                                                                name="cidade"
                                                                type="text"
                                                            />
                                                        </div>

                                                        <div className="col-6">
                                                            <label>UF</label>
                                                            <input
                                                                onChange={this.handleInputChange}
                                                                onFocus={this.getDataCep}
                                                                value={this.state.uf}
                                                                readOnly
                                                                className="form-control"
                                                                name="uf"
                                                                type="text"
                                                                maxLength="2"
                                                                minLength="2"
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                                {
                                                    this.state.erro && (
                                                        <div className="row mt-2 mb-2">
                                                            <div className="col">
                                                                <p className="text-danger">{this.state.erro}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <div className="row mt-4">
                                                    <div className="col">
                                                        <button type="submit" className="btn btn-success">Alterar
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>

                                        </div>

                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
    }
);

export default connect(mapStateToProps, {setDataStudent})(Perfil)
