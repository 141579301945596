import React, {Component} from 'react'
import {connect} from "react-redux"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft, faCheck, faCreditCard, faBarcode, faCopy, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {URL} from "../../../services/api";
//
import PagamentoCartao from './Card'
import {gerarBoleto, clearBoleto} from './../../../actions/PagamentoAction'


class Pagamento extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this._renderButonBoleto = this._renderButonBoleto.bind(this);
        this._getBoleto = this._getBoleto.bind(this);
        this.state = {
            activeTab: 'cartao',
            gerandoBoleto: false,
            copied: false
        };
    }

    componentWillMount() {
        this.props.clearBoleto()
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    _renderButonBoleto() {
        if (this.state.gerandoBoleto) {
            return (
                <Spinner/>
            )
        } else if (this.props.compraBoleto.dataBoleto) {
            return (
                <div className="text-center">
                    <div id="return-boleto">
                        <div className="row">
                            <p className="h3 small">{this.props.compraBoleto.dataBoleto.digitableLine}</p>
                        </div>

                        <div className="row mt-3">
                            {
                                (this.props.compraBoleto.dataBoleto && this.props.compraBoleto.dataBoleto.digitableLine) && (
                                    <div className="col-6">
                                        <CopyToClipboard text={this.props.compraBoleto.dataBoleto.digitableLine}
                                                         onCopy={() => {
                                                             this.setState({copied: true})
                                                             alert('Copiado')
                                                         }}
                                        >
                                            <button className="btn btn-lg waves-effect btn-orange">
                                        <span className="h6 small">
                                            <FontAwesomeIcon icon={faCopy}/><br/>Copiar código
                                        </span>
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                )
                            }
                            <div className="col-6">
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={`${URL}/student-payment/${this.props.usuario.id}/parcel/${this.props.compraBoleto.dataBoleto.idParcel}/inovice/0/type/1/print`}
                                   className="btn btn-lg waves-effect btn-orange">
                                    <span className="h6 small"><FontAwesomeIcon icon={faFilePdf}/><br/>Visualizar boleto</span>
                                </a>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="alert alert-warning">
                                Boleto gerado com sucesso! É necessário que você aguarde algumas horas para efetuar o
                                pagamento.
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <button type="button" className="btn btn-secondary btn-lg waves-effect"
                    onClick={this._getBoleto.bind(this)}>
                <FontAwesomeIcon icon={faCheck}/> Gerar boleto bancário
            </button>
        )
    }

    _getBoleto() {
        this.setState({gerandoBoleto: true})
        this.props.gerarBoleto(this.props.usuario, this.props.carrinho, this.props.carrinhoValor).then(
            () => this.setState({gerandoBoleto: false})
        ).catch(error => {
            this.setState({gerandoBoleto: false})
            if (error.response && error.response.data)
                alert(error.response.data)
            else
                alert('Falha ao tentar gerar o boleto!')
        })
    }

    render() {
        return (
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 card-pagamento">
                <div className="container text-center">
                    <div className="card">
                        <div className="card-header m-3">
                            <div className="row">
                                <div className="col-10">
                                    <h5 className="h5 mt-2">Efetuar pagamento</h5>
                                </div>
                                <div className="col-2 text-right">
                                    <Link to="/comprar-material-pedagogico" className="btn btn-secondary"
                                          title="Continuar comprando">
                                        <FontAwesomeIcon icon={faAngleLeft}/>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="col-12">
                                <div className="col-12  text-center">
                                    <h5 className="h5">O total da sua compra foi <span
                                        className="text-orange">R${this.props.carrinhoValor.toFixed(2).replace(".", ",")}</span>
                                    </h5>
                                </div>
                            </div>
                            <hr/>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === 'cartao'})}
                                        onClick={() => {
                                            this.toggle('cartao');
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCreditCard}/><br/>
                                        <h6>Cartão de crédito</h6>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === 'boleto'})}
                                        onClick={() => {
                                            this.toggle('boleto');
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faBarcode}/><br/>
                                        <h6>Boleto</h6>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="cartao">
                                    <PagamentoCartao history={this.props.history}/>
                                </TabPane>
                                <TabPane tabId="boleto">
                                    <div className="btn btn-default btn-lg waves-effect">
                                        <div className="text-center">
                                            {this._renderButonBoleto()}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        carrinho: state.ComprasReducer.carrinho,
        carrinhoValor: state.AppReducer.carrinhoValor,
        usuario: state.AppReducer.userData,
        compraBoleto: state.AppReducer.compraBoleto
    }
)

export default connect(mapStateToProps, {gerarBoleto, clearBoleto})(Pagamento)

