import React, {Component} from 'react'
import {connect} from "react-redux"
import {confirmAlert} from 'react-confirm-alert'
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import ConteudoOnlineItem from "./../../components/ConteudoOnlineKidsItem"
import './styles.css'
import {conteudoOnlineKidsUsuarioFetch} from './../../actions/ConteudoOnlineAction'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"


class ConteudoOnlineKids extends Component {

    state = {
        loadingCompleted: false,
        error: null
    }

    componentWillMount() {
        if (this.props.usuario.coursesIds && this.props.usuario.coursesIds.indexOf(2) < 0) {
            confirmAlert({
                title: `Olá ${this.props.usuario.name.split(' ')[0]}`,
                message: <h6 className="text-justify">Seu portal Rockfeller mudou e está cheio de novidades! <br/>Você será redirecionada para o
                    novo portal, onde terá acesso a todo o novo conteúdo.<br/> Vamos conhecer?</h6>,
                buttons: [
                    {
                        label: 'Acessar o novo portal',
                        onClick: () =>
                            window.location.href = 'https://play.rockfeller.com.br/'
                    },
                ]
            });
        }

        this.props.conteudoOnlineKidsUsuarioFetch(this.props.usuario.id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            e => {
                if (e.response && e.response.data)
                    this.setState({loadingCompleted: true});
                else
                    this.setState({loadingCompleted: true, error: 'Verifique sua conexão com a internet.'})
            }
        )
    }

    render() {
        return (
            <div className="atividade-complementar">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card">
                            <div className="card-header text-left">
                                <h4 className="h4 text-center">Conteúdo Disponível</h4>
                                <small className="small">
                                    O conteúdo a ser exibido é disponíbilizado após a compra do material pedagógico.
                                </small>
                            </div>
                            <div className="card-body">
                                <div className="row conteudo-atividade-complementar">
                                    {
                                        (this.state.loadingCompleted) ?
                                            (this.props.conteudoOnline.length) ?
                                                this.props.conteudoOnline.map(item => (
                                                    <ConteudoOnlineItem key={item.id} atividade={item}
                                                                        history={this.props.history}/>
                                                ))
                                                : (
                                                    this.state.error ? (
                                                        <div className="alert alert-warning" role="alert">
                                                            {this.state.error}
                                                        </div>
                                                    ) : (
                                                        <div className="alert alert-warning" role="alert">
                                                            Nenhum registro de compra foi encontrado para <strong
                                                            translate="no">{this.props.usuario.name}</strong>. <br/>
                                                            Para ter acesso ao conteúdo oline, é necessário realizar a
                                                            compra do
                                                            material.
                                                        </div>
                                                    )
                                                )
                                            : <div className="w-100 text-center"><Spinner/></div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        conteudoOnline: state.ConteudoOnlineReducer.conteudoOnlineKids,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {conteudoOnlineKidsUsuarioFetch})(ConteudoOnlineKids)



