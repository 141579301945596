import CryptoJS from "crypto-js";
import {sessionService} from 'redux-react-session';

export const TOKEN_KEY = '@joy-Token'
export const JOY_USER = '@joy-user'

export const isAuthenticated = () => {
    return (localStorage.getItem(TOKEN_KEY) !== null)
}

//export const getToken = () => {return(localStorage.getItem(TOKEN_KEY))}
export const getToken = () => {return(decryptHash(getItemLocalStorage(TOKEN_KEY)))}

export const getUser = () => {return(localStorage.getItem(JOY_USER))}

export const getRole = role => {
    const roles = localStorage.getItem(JOY_USER)
    return roles.indexOf(role)
}

export const login = token => {
    //localStorage.setItem(TOKEN_KEY, token)
    addItemLocalStorage(token, TOKEN_KEY);
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
}

const passphrase = 'JOY-API-MYROCKFELLER-9er9p5sroj51chb2a3io9jdhsh';

export function encriptString(plain_text) {
    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);
    //for more random entropy can use : https://github.com/wwwtyro/cryptico/blob/master/random.js instead CryptoJS random() or another js PRNG

    var key = CryptoJS.PBKDF2(passphrase, salt, {hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999});

    var encrypted = CryptoJS.AES.encrypt(plain_text, key, {iv: iv});

    var data = {
        ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
        salt: CryptoJS.enc.Hex.stringify(salt),
        iv: CryptoJS.enc.Hex.stringify(iv)
    }

    return JSON.stringify(data);
}

export function decryptHash(encrypted_json_string) {
    if(typeof encrypted_json_string === 'object') {
        return encrypted_json_string;
    }

    if(typeof encrypted_json_string == 'string' && encrypted_json_string.substr(1, 2) === 'ey')
        return encrypted_json_string;

    var obj_json = JSON.parse(encrypted_json_string);

    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);

    var key = CryptoJS.PBKDF2(passphrase, salt, {hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999});


    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function addItemLocalStorage(data, key) {
    const hash = encriptString(JSON.stringify(data));
    return localStorage.setItem(key, hash)
}

export function getItemLocalStorage(key) {
    const hash = localStorage.getItem(key);
    if (hash) {
        if(typeof hash == 'string')
            return hash;
        return JSON.parse(decryptHash(hash));
    }
    return null;
}

export function getItemDecodedLocalStorage(key) {
    const hash = localStorage.getItem(key);
    if (hash) {
        return JSON.parse(decryptHash(hash));
    }
    return null;
}

export async function addSession(data) {
    let hash = await encriptString(data);
    await sessionService.saveSession(hash);
}

export async function getSession() {
    let hash = await sessionService.loadSession();
    return decryptHash(hash);
}

export async function addUserSession(user) {
    let hash = await encriptString(JSON.stringify(user));
    await sessionService.saveUser(hash);
}

export async function getUserSession() {
    const hash = await sessionService.loadUser();
    const user = await decryptHash(hash);
    if(typeof user == 'object')
        return user;
    return JSON.parse(user);
}