import React, {Component} from 'react'
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import DropDownGrammarIndexes from "../../components/DropDownGrammarIndexes"
import DropDownAnswerKeys from "../../components/DropDownAnswerKeys"
import DropDownTutorial from "../../components/DropDownTutorial"
import DropDownPresencialRegular from "../../components/DropDownRoteiroDeEstudos"
import DropDownDownloadAudios from "../../components/DropDownDownloadAudios"
import './styles.css'

class MaterialApoio extends Component {
    render() {
        return (
            <div className="conteudo-online-livro">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="row">
                            <div className="container">

                                <div className="card">

                                    <div className="card-header">
                                        <h4 className="h4 pt-3">Materiais de apoio</h4>
                                    </div>

                                    {
                                        (this.props.user.type !== 'STUDENT_DUO' && this.props.user.courses.indexOf('INGLêS') >= 0) ?
                                            <div>
                                                <DropDownAnswerKeys/>
                                                <DropDownGrammarIndexes/>
                                            </div>
                                            : null
                                    }
                                    {
                                        (this.props.user.courses.indexOf('INGLêS') >= 0 && (this.props.user.divisions.indexOf('Adults') >= 0 || this.props.user.type !== 'STUDENT_PRESENCIAL')) && (
                                            <DropDownTutorial type={this.props.user.type}/>
                                        )
                                    }
                                    {
                                        (this.props.user.courses.indexOf('INGLêS') >= 0 && this.props.user.type === 'STUDENT_PRESENCIAL') &&
                                        <DropDownPresencialRegular/>
                                    }
                                    {
                                        (this.props.user.courses.indexOf('INGLêS') >= 0 && this.props.user.type !== 'RESPONSIBLE_STUDENT' && ((this.props.user.methods.indexOf('ADULTO') >= 0 || this.props.user.methods.indexOf('ADULTO2') >= 0 || this.props.user.methods.indexOf('ON DEMAND') >= 0 || this.props.user.divisions.indexOf('Adults') >= 0) && this.props.user.type !== 'STUDENT_DUO')) && (
                                            <div className="dropdown-answer-keys mb-2">
                                                <div className="row">
                                                    <div className="container">
                                                        <div className="card">

                                                            <div id="button-material-apoio"
                                                                 className="btn card-header border-left-orange p-1">
                                                                <Link to='/atividades-complementares'
                                                                      className="text-nowrap link-none row">
                                                                    <div className="col-10 text-left">
                                                                        <strong className="text-nowrap">
                                                                            Mais exercícios livres
                                                                        </strong>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <FontAwesomeIcon
                                                                            icon={faAngleRight}
                                                                            size="1x"/>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <DropDownDownloadAudios/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, null)(MaterialApoio)


