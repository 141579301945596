import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
import {URL} from "../../services/api";
//
import ListMusic from './../ListMusic'

export default class DropDownCds extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    render() {
        return (
            <div className="dropdown-presencial-regular text-wrap">
                <div className="row">
                    <div className="container">
                        <div className="card">
                            <div onClick={this.toggle}
                                 id="button-boletim-dropdown"
                                 className="btn card-header border-left-yellow p-1 mt-2 mb-3">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <strong className="text-nowrap" translate="no">
                                            {this.props.livro.book.name}
                                        </strong>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon
                                            icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                            size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? '' : 'd-none'}>
                                {
                                    this.props.livro.cds.map(cd => (
                                        <div key={cd.id} className="col-12 mb-2">
                                           <h4 translate="no" className='h4 text-muted'>{cd.name}</h4>
                                            <ListMusic cd={cd}/>
                                            <a
                                                className="btn btn-secondary border-left-insight w-100 text-left"
                                                href={`${URL}/student-payment/${this.props.user.id}/token/${this.props.user.token}/cd_download/${cd.id}`}
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Download dos áudios</strong>
                                            </a>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


