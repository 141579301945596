import React from 'react'
import {Provider} from 'react-redux'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {sessionService} from 'redux-react-session'
import {createStore, applyMiddleware} from 'redux'
import ReduxThunk from 'redux-thunk'
//
import Routes from './routes'
import reducers from './reducers'
import './styles/global.css'

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk))
const options = {refreshFromLocalStorage: true, refreshOnCheckAuth: true, driver: 'LOCALSTORAGE'};
sessionService.initSessionService(store, options)

const App = () => (
    <Provider store={store}>
        <Routes/>
    </Provider>
)

export default App
