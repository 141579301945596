import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//
import {horariosAulaFetch} from '../../../actions/AgendarAulaPresencialAction'

class CheckboxHorario extends Component {
    render() {
        return (
            <div className="col-4">
                <div className="cinza">
                    <label
                        className={
                            this.props.check ? 'btn cinza w-100 mt-3 bg-orange' : 'btn cinza w-100 mt-3 bg-clear'
                        }
                         //className="btn w-100"
                        id={this.props.title}
                        htmlFor={this.props.id}
                    >
                        <i><FontAwesomeIcon icon={this.props.icon}/></i> <br/>{this.props.title}
                    </label>
                    <input
                        className={`d-none`}
                        type="checkbox"
                        name={this.props.title}
                        id={this.props.id}
                        value={this.props.title}
                        onClick={
                            async () => {
                                await this.props.horariosAulaFetch(this.props.title)
                                this.props.checkUpdate(this.props.title)
                            }
                        }
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        horarios: state.AgendarAulaPresencialReducer.horarios,
    }
)

export default connect(mapStateToProps, {horariosAulaFetch})(CheckboxHorario)
