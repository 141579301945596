import React, {Component} from 'react'
import {connect} from "react-redux"
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
//
import {agendarAula} from './../../../actions/AgendarAulaPresencialAction'


class Cadeira extends Component {

    _submit = (_idAluno, _idSala) => {
        let title = 'Deseja agendar sua aula presencial';
        if (this.props.user.method === 'On Demand')
            title = 'Deseja agendar sua aula no Rock Spot';

        confirmAlert({
            title: title,
            message: `${this.props.cadeira.startTime}H ás ${this.props.cadeira.endTime}H?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () =>
                        this.props.agendarAula(_idAluno, _idSala).then(() => {
                                alert('Agendamento realizado com sucesso!')
                                this.props.history.push('/')
                            }
                        )
                },
                {
                    label: 'Não',
                    onClick: () => {
                    }
                }
            ]
        })
    }

    render() {
        return (
            <button key={this.props.cadeira.index}
                    onClick={() => {
                        this._submit(this.props.idUsuario, this.props.cadeira.id)
                    }}
                    className={`cadeira cadeira${this.props.cadeira.index} btn`}
            >
                <img src={this.props.img}/>
            </button>
        )
    }
}

const mapStateToProps = state => (
    {
        idUsuario: state.AppReducer.userData.id,
        user: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {agendarAula})(Cadeira)