import {
    MODIFICA_FINANCEIRO_LIST
} from '../actions/types';

const INITIAL_STATE = {
    financeiro: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_FINANCEIRO_LIST:
            return {...state, financeiro: action.payload}
        default:
            return state;
    }
}