import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux";
import {listMusics} from "../../actions/MaterialApoioAction";
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import './styles.css';

class ListMusic extends Component {

    state = {
        loading: true
    };

    componentWillMount() {
        this.props.listMusics(this.props.cd.id, this.props.musics).then(
            () => this.setState({loading: false})
        ).catch(
            () => this.setState({loading: false})
        )
    }

    render() {
        return (
            <div className='col-12'>
                {
                    (this.state.loading) ? <Spinner/> :
                        <div>
                            {
                                (this.props.musics[this.props.cd.id]) ? this.props.musics[this.props.cd.id].map((music, key) => (
                                    <div key={key} className='row'>
                                       <div className="col-10">
                                           <figure className='container'>
                                               <figcaption>Aula <strong>{music.name}</strong></figcaption>
                                               <audio controls preload="auto" className='w-100'
                                                      src={music.uri}
                                               >
                                                   <source src={music.uri}/>
                                               </audio>
                                           </figure>
                                       </div>
                                        <div className="col-2">
                                            <br/>
                                            <a className="btn btn-info" href={`https://joy.rockfellerbrasil.com.br/my-rockfeller/origin/conteudo/name/${music.name}/book/${music.file}/download-audio`} target="_blank">
                                                <FontAwesomeIcon icon={faDownload}/>
                                            </a>
                                        </div>
                                    </div>
                                )) : null
                            }
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        musics: state.MaterialApioReducer.musics,
    }
);

export default connect(mapStateToProps, {
    listMusics
})(ListMusic)


