import React, {Component} from "react"
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserAlt, faLock} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
//
import Logo from "../../assets/logo-login.svg"
import {
    modificarSenha,
    modificarUsuario,
    autenticarUsuario,
    loginEmAndamento,
    loginErrorMessage
} from "./../../actions/LoginAction"
import "./styles.css"

class Login extends Component {

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        if(query.get('username')) {
            this.props.modificarUsuario(query.get('username'));
        }
    }

    _renderBtnAcessar() {

        if (this.props.loading_login) {
            return (
                <Spinner/>
            )
        }
        return (
            <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                onClick={this._autenticarUsuario}
            >
                Entrar
            </button>
        )
    }


    render() {
        return (

            <div className="home">

                <form className="form">
                    <div className="form-signin row">

                        <div className="col-12">
                            <img className="mb-5" src={Logo} alt="My Rockfeller logo" width="180px" onClick={() => window.location.reload(true)}/>
                        </div>

                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <label>
                                    <FontAwesomeIcon icon={faUserAlt}/>
                                </label>
                                <input
                                    type="text"
                                    id="inputEmail"
                                    className="form-control"
                                    placeholder="Usuário"
                                    onChange={texto => this.props.modificarUsuario(texto.target.value)}
                                    value={this.props.usuario}
                                    required="required"
                                    autoFocus="autoFocus"
                                    name="login"
                                />
                            </div>
                        </div>

                        <div className="col-12 mb-3">
                            <div className="form-group">
                                <label>
                                    <FontAwesomeIcon icon={faLock}/>
                                </label>
                                <input
                                    type="password"
                                    id="inputPassword"
                                    className="form-control"
                                    placeholder="Senha"
                                    onChange={texto => this.props.modificarSenha(texto.target.value)}
                                    required
                                    name="password"
                                />
                            </div>
                            {
                                (this.props.usuarioLogin.isRenewDuo) ? (
                                    <div className="text-center alert alert-light text-danger">
                                        Você possui uma matricula a renovar, clique aqui para realizar a renoção do contrato
                                    </div>
                                ) : (
                                (this.props.erroLogin) &&
                                    <h6 className="text-center alert alert-light text-danger">{this.props.erroLogin}</h6>
                                )
                            }
                        </div>

                        <div className="col-12 mb-3">
                            {this._renderBtnAcessar()}
                        </div>

                        <div className="col-12">
                            <p className="mt-1 mb-3">
                                <Link to="/resetting-password">Esqueceu a senha?</Link>
                            </p>
                        </div>

                    </div>
                </form>
            </div>
        );
    }

    _autenticarUsuario = () => {
        const {usuario, senha} = this.props

        this.props.loginEmAndamento(true)
        this.props.autenticarUsuario(usuario, senha)
            .then(() => {
                this.props.history.push('/')
            })
            .catch(error => {
                if (error === 'Sentimos sua falta. Volta pra Rock, estamos esperando você =)')
                    this.props.loginErrorMessage(error);
                else if(error === 'MATRICULA_RENOVACAO' || this.props.usuarioLogin.isRenewDuo ) {
                    console.log('AQUI')
                    this.props.history.push('/renovacao-matricula');
                } else
                    this.props.loginErrorMessage();
                this.props.loginEmAndamento(false)
            })

    }

}

const mapStateToProps = state => (
    {
        usuario: state.LoginReducer.usuario,
        usuarioLogin: state.AppReducer.userData,
        senha: state.LoginReducer.senha,
        erroLogin: state.LoginReducer.erroLogin,
        loading_login: state.LoginReducer.loading_login,
    }
)

export default connect(mapStateToProps, {
    modificarUsuario,
    modificarSenha,
    autenticarUsuario,
    loginErrorMessage,
    loginEmAndamento
})(Login)
