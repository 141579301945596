import React, {Component} from 'react'
import {connect} from "react-redux"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import CabecalhoAgendarAulaPresencialHorarios from './components/CabecalhoAgendarAulaPresencialHorarios'
import './style.css'

class AgendarAulaPresencialHorarios extends Component {

    componentWillMount() {
        if(this.props.diasHoraiosDisponiveis.length === 0)
            this.props.history.push('/agendar-aula-presencial')
    }

    render() {
        return (
            <div className="agendar-aula-presencial-horarios">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <CabecalhoAgendarAulaPresencialHorarios history={this.props.history}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        diasHoraiosDisponiveis: state.AgendarAulaPresencialReducer.diasHoraiosDisponiveis,

    }
)

export default connect(mapStateToProps, {})(AgendarAulaPresencialHorarios)



