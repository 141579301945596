import React from 'react'
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
//
import AvaliacaoOral from './AvaliacaoOral'
import AvaliacaoComposition from './AvaliacaoComposition'


export default class BoletimIngles extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggleAvaliacaoOral.bind(this);
        this.state = {
            dropdownOpenAvaliacaoOral: false,
            dropdownOpenAvaliacaoEscrita: false,
            dropdownOpenComposition: false,
            dropdownOpenFinalGrade: false,
        };
    }

    toggleAvaliacaoOral() {
        this.setState(prevState => ({
            dropdownOpenAvaliacaoOral: !prevState.dropdownOpenAvaliacaoOral
        }));
    }

    toggleAvaliacaoEscrita() {
        this.setState(prevState => ({
            dropdownOpenAvaliacaoEscrita: !prevState.dropdownOpenAvaliacaoEscrita
        }));
    }

    toggleComposition() {
        this.setState(prevState => ({
            dropdownOpenComposition: !prevState.dropdownOpenComposition
        }));
    }

    toggleFinalGrade() {
        this.setState(prevState => ({
            dropdownOpenFinalGrade: !prevState.dropdownOpenFinalGrade
        }));
    }

    render() {
        const {resultado} = this.props

        return (
            <div className="boletim boletim-ingles mb-2 row m-2">
                <table className="table table-striped">
                    <thead>
                    <tr onClick={() => this.toggleAvaliacaoOral()}>
                        <th className="text-center">Avaliações Orais</th>
                        <th className={(resultado.media >= 20 * 0.7) ? "text-info" : "text-warning"}>
                            {resultado.media.toFixed(2).replace('.', ',')}
                        </th>
                        <th>
                            <FontAwesomeIcon className="float-right"
                                             icon={(this.state.dropdownOpenAvaliacaoOral) ? faAngleUp : faAngleDown}
                                             size="2x"/>
                        </th>
                    </tr>
                    </thead>
                    {
                        (this.state.dropdownOpenAvaliacaoOral) ?

                            <tbody>
                            {
                                (resultado.avaliationsBookClasses.length > 0) ?
                                    resultado.avaliationsBookClasses.map(item => (
                                        <AvaliacaoOral item={item} key={item.name}/>
                                    )) : null
                            }
                            <tr className="bg-secondary">
                                <th>Total</th>
                                <th className={(resultado.media >= 20 * 0.7) ? "text-info" : "text-warning"}>{resultado.media.toFixed(2).replace('.', ',')}</th>
                                <th></th>
                            </tr>
                            </tbody>
                            : null
                    }

                    <thead>
                    <tr onClick={() => this.toggleComposition()}>
                        <th className="text-center" translate="no">Bring It Out</th>
                        <th className={(resultado.compositionValue >= 20 * 0.7) ? "text-info" : "text-warning"}>
                            {resultado.compositionValue ? resultado.compositionValue.toFixed(2).replace('.', ',') : 0}
                        </th>
                        <th>
                            <FontAwesomeIcon className="float-right"
                                             icon={(this.state.dropdownOpenComposition) ? faAngleUp : faAngleDown}
                                             size="2x"/>
                        </th>
                    </tr>
                    </thead>
                    {
                        (this.state.dropdownOpenComposition) ?
                            <tbody>
                            {
                                resultado.compositions.map(item => (
                                    <AvaliacaoComposition item={item} key={item.id}/>
                                ))
                            }
                            <tr className="bg-secondary">
                                <th>Total</th>
                                <th className={(resultado.compositionValue && resultado.compositionValue >= 20 * 0.7) ? "text-info" : "text-warning"}>{resultado.compositionValue ? resultado.compositionValue.toFixed(2).replace('.', ',') : 0}</th>
                                <th></th>
                            </tr>
                            </tbody>
                            : null
                    }

                    <thead>
                    <tr onClick={() => this.toggleAvaliacaoEscrita()}>
                        <th className="text-center">Final Test</th>
                        <th className={(resultado.totalWriteTests >= 20 * 0.7) ? "text-info" : "text-warning"}>
                            {(resultado.totalWriteTests) ? parseFloat(resultado.totalWriteTests).toFixed(2).replace('.', ',') : '-'}
                        </th>
                        <th>
                            <FontAwesomeIcon className="float-right"
                                             icon={(this.state.dropdownOpenAvaliacaoEscrita) ? faAngleUp : faAngleDown}
                                             size="2x"/>
                        </th>
                    </tr>
                    </thead>
                    {
                        (this.state.dropdownOpenAvaliacaoEscrita) ?
                            <tbody>
                            <tr className="bg-secondary text-center">
                                <th colSpan={3}
                                    className={(resultado.totalWriteTests >= 20 * 0.7) ? "text-info" : "text-warning"}>{(resultado.totalWriteTests) ? parseFloat(resultado.totalWriteTests).toFixed(2).replace('.', ',') : '-'}
                                </th>
                            </tr>
                            </tbody>
                            : null
                    }


                    <thead>
                    <tr onClick={() => this.toggleFinalGrade()}>
                        <th className="text-center">Final Grade</th>
                        <th className={(resultado.finalGrade >= 20 * 0.7) ? "text-info" : "text-warning"}>
                            {(resultado.finalGrade) ? parseFloat(resultado.finalGrade).toFixed(2).replace('.', ',') : '-'}
                        </th>
                        <th><FontAwesomeIcon className="float-right"
                                             icon={(this.state.dropdownOpenComposition) ? faAngleUp : faAngleDown}
                                             size="2x"/></th>
                    </tr>
                    </thead>
                    {
                        (this.state.dropdownOpenFinalGrade) ?
                            <tbody>
                            <tr className="bg-secondary text-center">
                                <th colSpan={3}
                                    className={(resultado.finalGrade >= 20 * 0.7) ? "text-info" : "text-warning"}>{(resultado.finalGrade) ? parseFloat(resultado.finalGrade).toFixed(2).replace('.', ',') : '-'}</th>
                            </tr>
                            </tbody>
                            : null
                    }
                </table>
            </div>
        )
    }
}