import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {NavLink as RRNavLink, Link} from "react-router-dom"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap'
import {
    faDollarSign,
    faShoppingBasket,
    faBook,
    faGlobe,
    faChartLine,
    faEdit,
    faUserClock,
    faRunning,
    faHome,
    faLightbulb,
    faLifeRing,
    faGraduationCap,
    faBookReader,
    faPlay,
    faCaretUp,
    faCaretDown,
    faUsersCog,
    faClock,
    faPlus,
    faBell,
    faUserAlt,
} from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
//
import Logo from "../../assets/logo-app.svg"
import {logoutUsuario, modificarSenha, modificarUsuario} from '../../actions/LoginAction'
import {modificaUserData, carregarNotificacoes} from '../../actions/AppAction'
import './styles.css'
import "react-notifications-component/dist/theme.css"
import {getPermission} from "../../services/checkPermission";


class Header extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            isOpenMenuAgendar: false,
            isOpenMenuAdministrativo: false,
            permissions: [],
        };
    }

    componentWillMount() {
        this.props.modificaUserData();
        this.props.carregarNotificacoes(this.props.user.id).then(
            response => {
                this.addNotification(response)
            }
        ).catch(
            error => {
                if (error && _.map(error).length > 1 && _.map(error)[2] && _.map(error)[2].status === 401) {
                    this.props.modificarUsuario('')
                    this.props.modificarSenha('')
                    this.props.logoutUsuario()
                    //alert('Sua sessão expirou, faça o login novamente!')
                    window.location.reload()
                }

            }
        )

        const permissions = getPermission(this.props.user);
        if (permissions && permissions.length)
            this.setState({permissions});
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        return (
            <div className="row text-left" id="header" translate="no">

                <Navbar className="laranja mb-3 w-100" dark>

                    <NavbarToggler className="d-block d-md-none collapsed" onClick={this.toggle}/>
                    <NavbarBrand className="logo" href="/"><img src={Logo} alt="" width="158"/></NavbarBrand>

                    <div className="pull-right">

                        {
                            (this.state.permissions.indexOf('comprar-material-pedagogico') >= 0) && (
                                (this.props.carrinhoValor > 0) ?
                                    <div className="item d-inline-block">
                                        <Link className="item-menu carrinho" to="/carrinho"
                                              title="cesta de compras">
                                            <i>
                                                <FontAwesomeIcon icon={faShoppingBasket}/>
                                                <span className="notificacao-valor">
                                                <label>R${this.props.carrinhoValor.toFixed(2).replace(".", ",")}</label>
                                             </span>
                                            </i>
                                        </Link>
                                    </div>
                                    : <div className="item d-inline-block">
                                        <Link className="item-menu carrinho" to="/carrinho" title="cesta de compras">
                                            <i>
                                                <FontAwesomeIcon icon={faShoppingBasket} className="mr-3"/>
                                            </i>
                                        </Link>
                                    </div>
                            )
                        }


                        {(false) && (
                            (this.props.notificacao > 0) ? (
                                <div className="item d-inline-block">
                                    <Link className="item-menu" to="/notificacao" title="notificação">
                                        <i>
                                            <FontAwesomeIcon icon={faBell}/>
                                            <span className="notificacao-valor">
                                                <label>{(parseInt(this.props.notificacao) < 10) ? '0' + parseInt(this.props.notificacao) : (parseInt(this.props.notificacao) > 99) ? '99+' : parseInt(this.props.notificacao)}</label>
                                            </span>
                                        </i>
                                    </Link>
                                </div>
                            ) : (
                                <div className="item d-inline-block">
                                    <Link className="item-menu" to="/notificacao" title="notificação">
                                        <i>
                                            <FontAwesomeIcon icon={faBell} className="mr-3"/>
                                        </i>
                                    </Link>
                                </div>
                            )
                        )}

                    </div>

                    <Collapse id="menu" isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink tag={RRNavLink} to="/"><i><FontAwesomeIcon
                                    icon={faHome}/></i> Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RRNavLink} to="/financeiro"><i><FontAwesomeIcon
                                    icon={faDollarSign}/></i> Financeiro</NavLink>
                            </NavItem>
                             <NavItem>
                                <NavLink tag={RRNavLink} to="/perfil"><i><FontAwesomeIcon
                                    icon={faUserAlt}/></i> Perfil</NavLink>
                            </NavItem>
                            {
                                (this.state.permissions.indexOf('minhas-compras') >= 0) &&
                                <NavItem>
                                    <NavLink tag={RRNavLink} to="/minhas-compras"><i><FontAwesomeIcon
                                        icon={faShoppingBasket}/></i> Minhas compras</NavLink>
                                </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('comprar-material-pedagogico') >= 0) &&
                                <NavItem>
                                    <NavLink tag={RRNavLink} to="/comprar-material-pedagogico"><i><FontAwesomeIcon
                                        icon={faBook}/></i> Comprar material pedagógico</NavLink>
                                </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('simulado-exames-internacionais') >= 0) &&
                                <NavItem>
                                    <a className="nav-link" target="_blank"
                                       href={`https://rockfeller.edusynch.com/sign-up?school=${this.props.user.franchiseCNPJ}`}><i><FontAwesomeIcon
                                        icon={faGlobe}/></i> Simulado de exames internacionais
                                    </a>
                                </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('rockfeller-play') >= 0) &&
                                    <NavItem>
                                        <a className="nav-link" target="_blank"
                                           href={`https://play.rockfeller.com.br/`}>
                                            <i><FontAwesomeIcon icon={faPlay}/></i> Rockfeller Play
                                        </a>
                                    </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('boletins') >= 0) &&
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/boletins"><i><FontAwesomeIcon
                                            icon={faChartLine}/></i> Boletins</NavLink>
                                    </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('certificados') >= 0) &&
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/certificados"><i><FontAwesomeIcon
                                            icon={faGraduationCap}/></i> Certificados</NavLink>
                                    </NavItem>
                            }

                            {
                                (this.state.permissions.indexOf('composition') >= 0) && (
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/composition"><i><FontAwesomeIcon
                                            icon={faEdit}/></i> Bring It Out</NavLink>
                                    </NavItem>
                                )
                            }
                            {
                                (this.state.permissions.indexOf('conteudo-online-kids') >= 0) ?
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/conteudo-online-kids"><i><FontAwesomeIcon
                                            icon={faBookReader}/></i> Conteúdo Online</NavLink>
                                    </NavItem>
                                    : (this.state.permissions.indexOf('conteudo-online') >= 0) &&
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/conteudo-online"><i><FontAwesomeIcon
                                            icon={faBookReader}/></i> Conteúdo Online</NavLink>
                                    </NavItem>
                            }

                            {
                                (this.state.permissions.indexOf('english-central') >= 0) &&
                                    <NavItem>
                                        <a className="nav-link" target="_blank"
                                           href={`https://pt.englishcentral.com/partner/rockfeller`}
                                        >
                                            <i><FontAwesomeIcon icon={faLightbulb}/></i> Plus
                                        </a>
                                    </NavItem>
                            }

                            {
                                (this.state.permissions.indexOf('agendar-aula-presencial') >= 0) &&
                                    <NavItem>
                                        {
                                            (this.props.user.type === 'STUDENT_DUO') ? (
                                                <>
                                                    <a className="nav-link"
                                                       onClick={() => this.setState({openAgendarAula: !this.state.openAgendarAula})}>
                                                        <i><FontAwesomeIcon icon={faUserClock}/></i>
                                                        <span className="font-normal">Aulas presenciais</span>
                                                        {
                                                            this.state.openAgendarAula ? (
                                                                <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretUp}/></i>
                                                            </span>
                                                            ) : (
                                                                <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretDown}/></i>
                                                            </span>
                                                            )
                                                        }
                                                    </a>
                                                    {
                                                        this.state.openAgendarAula && (
                                                            <ul>
                                                                <NavLink className="ml-1" tag={RRNavLink}
                                                                         to="/agendar-aula-presencial">Agendar</NavLink>
                                                                <NavLink className="ml-1" tag={RRNavLink}
                                                                         to="/interaction">Aulas
                                                                    concluídas</NavLink>
                                                            </ul>
                                                        )

                                                    }
                                                </>
                                            ) : (
                                                <NavLink className="ml-1" tag={RRNavLink}
                                                         to="/agendar-aula-presencial">Agendar aula no Rockspot
                                                </NavLink>
                                            )
                                        }

                                    </NavItem>
                            }
                            {

                            }
                            {
                                this.state.permissions.indexOf('administrativo') >= 0 && (
                                    <NavItem>
                                        <a className="nav-link"
                                           onClick={() => this.setState({isOpenMenuAdministrativo: !this.state.isOpenMenuAdministrativo})}>
                                            <i><FontAwesomeIcon icon={faUsersCog}/></i>
                                            <span className="font-normal">Administrativo</span>
                                            {
                                                this.state.isOpenMenuAdministrativo ? (
                                                    <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretUp}/></i>
                                                            </span>
                                                ) : (
                                                    <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretDown}/></i>
                                                            </span>
                                                )
                                            }
                                        </a>
                                        {
                                            this.state.isOpenMenuAdministrativo && (
                                                <ul>
                                                    <NavLink className="ml-1" tag={RRNavLink}
                                                             to="/recisao">Solicitar Rescisão
                                                    </NavLink>
                                                </ul>
                                            )

                                        }

                                    </NavItem>)
                            }

                            {
                                (this.state.permissions.indexOf('agendar-suporte') >= 0) &&
                                <NavItem>
                                    <NavLink tag={RRNavLink} to="/agendar-suporte"><i><FontAwesomeIcon
                                        icon={faClock}/></i> Agendar suporte</NavLink>
                                </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('material-de-apoio') >= 0) &&
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/material-de-apoio">
                                            {
                                                (this.props.user.type === 'STUDENT_DUO') ?
                                                    <span><i><FontAwesomeIcon
                                                        icon={faLifeRing}/></i> Vídeo tutorial</span> :
                                                    <span><i><FontAwesomeIcon icon={faLifeRing}/></i> Materiais de apoio</span>
                                            }
                                        </NavLink>
                                    </NavItem>
                            }
                            {
                                (this.state.permissions.indexOf('atividades-complementares') >= 0) &&
                                    <NavItem>
                                        <NavLink tag={RRNavLink} to="/atividades-complementares"><i><FontAwesomeIcon
                                            icon={faPlus}/></i> Atividades Complementares</NavLink>
                                    </NavItem>
                            }
                            <NavItem>
                                <NavLink tag={RRNavLink} to="/login" onClick={() => this.logoutUsuario()}
                                         href="/">
                                    <i><FontAwesomeIcon icon={faRunning}/></i>
                                    Sair
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }


    logoutUsuario = () => {
        this.props.modificarUsuario('')
        this.props.modificarSenha('')
        this.props.logoutUsuario()
    }

}

const mapStateToProps = state => (
    {
        notificacao: state.AppReducer.notificacaoTotal,
        notificacaoList: state.AppReducer.notificacaoList,
        carrinhoItens: state.AppReducer.carrinhoItens,
        carrinhoValor: state.AppReducer.carrinhoValor,
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, {
    logoutUsuario,
    modificarUsuario,
    modificarSenha,
    modificaUserData,
    carregarNotificacoes
})(Header)
