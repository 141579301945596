import React, {Component} from 'react'
//
import cadeiraAzulDireita from './../../../assets/azul-direita.svg'
import cadeiraAzulEsquerda from './../../../assets/azul-esquerda.svg'
import cadeiraCinzaEsquerda from './../../../assets/cinza-esquerda.svg'
import cadeiraCinzaDireita from './../../../assets/cinza-direita.svg'
import salaImg from './../../../assets/sala.svg'
import Cadeira from './Cadeira'

export default class Sala extends Component {

    state = {
        cadeirasEsquerda: [],
        cadeirasDireita: [],
        inicioDireita: 0
    }

    componentWillMount() {
        let cadeirasEsquerda = this.state.cadeirasEsquerda
        let cadeirasDireita = this.state.cadeirasDireita
        let metade = 4;
        if (this.props.horario.ammount > 0) {
            const ocupadas = this.props.horario.qtdStudent;
            metade = Math.round(this.props.horario.ammount / 2)

            for (let i = 1; i <= metade; i++) {

                let status = 'livre'
                if (i <= ocupadas)
                    status = 'ocupada'
                const cadeira = {
                    'index': i,
                    'status': status,
                    'id': this.props.horario.id,
                    'startTime': this.props.horario.startTime,
                    'endTime': this.props.horario.endTime,
                }
                cadeirasEsquerda.push(cadeira)
            }

            for (let i = 1; (i+metade) <= this.props.horario.ammount; i++) {

                let status = 'livre'
                if ((i+metade) <= ocupadas)
                    status = 'ocupada'

                const inicioDireita = 8 - parseInt(this.props.horario.ammount / 2);
                const cadeira = {
                    'index': inicioDireita + i,
                    'status': status,
                    'id': this.props.horario.id,
                    'startTime': this.props.horario.startTime,
                    'endTime': this.props.horario.endTime,
                }

                cadeirasDireita.push(cadeira)
            }
        }
        this.setState({cadeirasEsquerda, cadeirasDireita})
    }

    render() {
        return (
            <div className="col-12 col-md-6 mb-3 sala">
                <img src={salaImg} alt="sala" className="bg"/>
                <h4>{this.props.horario.startTime} às {this.props.horario.endTime}</h4>
                {
                    this.state.cadeirasEsquerda.map((cadeira) => (
                        <Cadeira history={this.props.history} key={cadeira.index} cadeira={cadeira}
                                 img={(cadeira.status === 'livre') ? cadeiraAzulEsquerda : cadeiraCinzaEsquerda}/>
                    ))
                }
                {
                    this.state.cadeirasDireita.map((cadeira) => (
                        <Cadeira history={this.props.history} key={cadeira.index} cadeira={cadeira}
                                 img={(cadeira.status === 'livre') ? cadeiraAzulDireita : cadeiraCinzaDireita}/>
                    ))
                }
            </div>
        )
    }
}