import axios from "axios"
//
import api from "../services/api";
import {MODIFICA_ATIVIDADE_COMPLEMENTAR, MODIFICA_LIVROS_ATIVIDADE_COMPLEMNETAR} from "./types"

export const atividadesUsuarioFetch = (idUsuario) => dispatch =>
    api.post('/student/atividade-complementar', {_id: idUsuario}).then(
        response => {
            return dispatch({type: MODIFICA_ATIVIDADE_COMPLEMENTAR, payload: response.data})
        }
    )

export const atividadesLivrosUsuarioFetch = (idUsuario, idLivro) => dispatch =>
    api.post('student/atividade-complementar/livro', {_id: idUsuario, _idBook: idLivro}).then(
        response => {
            return dispatch({type: MODIFICA_LIVROS_ATIVIDADE_COMPLEMNETAR, payload: response.data})
        }
    )

export const getSlide = (id, unit) => dispatch =>
    axios.get(`https://joy.rockfellerbrasil.com.br/aula/aaclass.php?id=${id}-${unit}`).then(
        response => {
            console.log(response)
        }
    )
