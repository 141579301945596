import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Link} from 'react-router-dom'
//
import pesquisaImg from './../../assets/pesquisa_feedback.png';


class ModalPesquisa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        return (
            <div>
                <Button color="danger" className='d-none' onClick={this.toggle}>{this.props.buttonLabel}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>
                        <img src={pesquisaImg} width='70%'
                             className="img-responsive"/>
                    </ModalHeader>
                    <ModalBody>
                        {this.props.pesquisa.research.messageWellcome}
                    </ModalBody>
                    <ModalFooter>
                        <Link to='/questionario' className='btn btn-info    ' style={{width: '100%'}} color="primary">
                            Clique aqui para responder
                        </Link>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalPesquisa;