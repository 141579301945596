import React, {Component} from 'react'
import {connect} from "react-redux"
import {confirmAlert} from "react-confirm-alert"
import _ from "lodash"
//
import Header from './../../../components/Header'
import Menu from './../../../components/Menu'
import {suportesDiasCheck, suporteAgendarAction, resetarDias} from './../../../actions/SuporteAction'
import {getDiaString} from "../../../services/dataFormat";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class HorariosSuporte extends Component {

    state = {
        enviandoDados: false,
        diaSelecionado: 0.
    }

    componentWillMount() {
        const {suportes} = this.props
        let value = 0
        if (suportes.length > 0)
            value = (parseInt(suportes.length / 2))

        this.setState({diaSelecionado: value})
    }

    componentDidMount() {
        if (this.props.suportes.length === 0)
            this.props.history.push('/agendar-suporte')
    }

    _renderTitle = () => {
        const {suportes} = this.props
        if (suportes.length > 0 && this.state.diaSelecionado < suportes.length) {
            const date = new Date(suportes[this.state.diaSelecionado].date)
            return (
                <p>
                    Agendando para o
                    dia <span>{suportes[this.state.diaSelecionado].date_BR}({getDiaString(date.getUTCDay())})</span>
                </p>
            )
        }
    }

    _renderBtnPaginator = (type) => {
        const {suportes} = this.props

        let btn = faAngleLeft
        let disable = false
        let value = this.state.diaSelecionado
        if (type === 'RIGHT') {
            if (this.state.diaSelecionado >= (suportes.length - 1))
                disable = true
            btn = faAngleRight
            value++
        } else {
            if (this.state.diaSelecionado <= 0)
                disable = true
            value--
        }

        return (
            <button
                className="btn btn-lg btn-secondary"
                onClick={() => this.setState({diaSelecionado: value})}
                disabled={disable}
            >
                <FontAwesomeIcon icon={btn}/>
            </button>
        )
    }

    _getHorarios() {
        return _.map(this.props.suportes[this.state.diaSelecionado].supports)
    }

    _submit = (_suporte, professor, startTime, endTime) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1>Deseja se agendar no suporte</h1>
                        <p>
                            {`${startTime}H ás ${endTime}H`}<br/>
                            {`Com o professor(a) ${professor}?`}
                        </p>
                        <div className="row center text-center">
                            <div className="col-6 offset-3">
                                <div className="react-confirm-alert-button-group">
                                    <button
                                        onClick={() => {
                                            this.props.suporteAgendarAction(this.props.usuario.id, _suporte).then(() => {
                                                    alert('Suporte agendado com sucesso!')
                                                    this.props.history.push('/')
                                                }
                                            ).catch(
                                                error => {
                                                    if (error.response && error.response.data )
                                                        alert(error.response.data)
                                                    else
                                                        alert('Falha ao tentar realizar o agendamento!')
                                                    this.props.history.push('/agendar-suporte')
                                                }
                                            )
                                            this.props.resetarDias()
                                            onClose()
                                        }}

                                    >Sim
                                    </button>
                                    <button onClick={() => onClose()}>Não</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }

    render() {
        return (
            <div className="suporte-horarios dashboard">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="row ml-2">
                            <div className="container">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="h4 p-3">Horários disponíveis para suporte</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="data-confirmacao box-cinza">
                                            <div className="row mr-2 ml-1">
                                                <div className="col-2">
                                                    {this._renderBtnPaginator('LEFT')}
                                                </div>
                                                <div className="col-8">
                                                    {this._renderTitle()}
                                                </div>
                                                <div className="col-2">
                                                    {this._renderBtnPaginator('RIGHT')}
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <hr/>

                                        <div className="row">
                                            {
                                                (this.props.suportes.length > 0) ? this._getHorarios().map((data) => (
                                                    <div key={data.id}
                                                         className="col-sm-12 col-md-6 col-xl-4 mb-3 mt-3 text-center">
                                                        <a onClick={() => this._submit(data.id, data.teacher, data.startTime, data.endTime)}
                                                           className="btn w-100">
                                                            <div
                                                                className="bg-dark text-center text-light  rounded-top  p-1">
                                                                {data.startTime}H ÁS {data.endTime}H<br/>
                                                                <strong>{data.teacher}</strong>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        suportes: state.SuporteReducer.suportes

    }
)

export default connect(mapStateToProps, {suportesDiasCheck, suporteAgendarAction, resetarDias})(HorariosSuporte)