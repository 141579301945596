import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
//
import DropDownPresencialRegular from './../DropDownPresencialRegular'
import DropDownPresencialSemiIntensivo from './../DropDownPresencialSemiIntensivo'

export default class DropDownRoteiroDeEstudos extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    render() {
        return (
            <div className="dropdown-presencial-regular mb-2">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggle}
                                    id="button-boletim-dropdown"
                                    className="btn card-header border-left-orange p-1">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <strong className="text-nowrap">
                                            Roteiro de estudos
                                        </strong>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon
                                            icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                            size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left m-1">
                                    <div className="col-12 text-nowrap div-dropdown">
                                        <DropDownPresencialRegular/>
                                        <DropDownPresencialSemiIntensivo/>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


