import {
    MODIFICA_COMPOSITIONS,
    COMPOSITION_SAVE_PARCIAL
} from '../actions/types'


const INITIAL_STATE = {
    compositions: null,
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case MODIFICA_COMPOSITIONS:
            return { ...state, compositions: action.payload }
        case COMPOSITION_SAVE_PARCIAL:
            return action.payload
        default:
            return state;
    }

}