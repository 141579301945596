import {
    SET_MATRICULA_RENOVACAO,
    SET_STEP_MATRICULA_RENOVACAO,
    SET_CONTRACT_MATRICULA_RENOVACAO,
    SET_STEP_MATRICULA_COMPLETO,
    SET_STEP_MATRICULA_ERRO,
    SET_STEP_ESCOLA,
    SET_ESCOLAS_FORM
} from '../actions/types';

const INITIAL_STATE = {
    matriculaRenovacao: [],
    pagamento: [],
    step: 1,
    ecola: null,
    escolas: [],
    erro: '',
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MATRICULA_RENOVACAO:
            return {...state, matriculaRenovacao: action.payload};
        case SET_STEP_MATRICULA_RENOVACAO:
            return {...state, step: action.payload};
        case SET_CONTRACT_MATRICULA_RENOVACAO:
            return {...state, contrato: action.payload};
        case SET_STEP_ESCOLA:
            return {...state, escola: action.payload};
        case SET_ESCOLAS_FORM:
            return {...state, escolas: action.payload};
        case SET_STEP_MATRICULA_COMPLETO:
            return {...state, pagamento: action.payload};
        case SET_STEP_MATRICULA_ERRO:
            return {...state, erro: action.payload};
        default:
            return state;
    }
}