import React from 'react'
//
import {connect} from "react-redux"
import {matriculaRenovacaoPagamentoFetch, pagamentoCartaoAction} from "../../actions/RenovacaoMatriculaAction";
import {formatMoney} from "../../services/dataFormat";
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"

class StepPagamento extends React.Component {

    state = {
        loading: false
    }

    getDate = str => {
        if (!str)
            return '';

        const date_str = str.split('-');
        const date = new Date(parseInt(date_str[0]), parseInt((date_str[1] - 1)), date_str[2]);
        return date;
    }

    submitContrato = () => {
        this.props.pagamentoCartaoAction();
    }

    submitContratoBoleto = () => {
        this.setState({loading: true});
        this.props.matriculaRenovacaoPagamentoFetch(this.props.matricula.id, this.props.escola).then(
            () => this.setState({loading: false})
        );
    }

    renderLoading = () => (
        <div className="row">
            <div className="col-12 center">
                <Spinner />
            </div>
        </div>
    );

    renderPagamento = (matricula) => (
        <div className="content h-100">
            <div className="content-body h-75 d-flex flex-column justify-content-center align-middle align-items-center">
                <div className="row">
                    <div className="col-12 font-text-renovacao">
                        <span>Como você prefere pagar suas mensalidades?<br/> Temos a opção de boleto bancário ou cobranças recorrentes em seu cartão de crédito.  Na cobrança recorrente, todo mês é descontado automaticamente o valor da mensalidade, sem comprometer o limite do seu cartão.</span><br/><br/>
                    </div>
                    <div className="col-12">
                        <h4 className="text-info font-text-strong-renovacao">o valor do curso de Inglês DUO é de 12x de {formatMoney(matricula.value)}</h4>
                    </div>
                </div>
            </div>
            <div className="input mt-2 h-25">
                <br/>
                <button onClick={this.submitContratoBoleto} className="btn btn-info mr-2">Boleto Bancário</button>
                <button onClick={this.submitContrato} className="btn btn-info">Cartão de Crédito</button>
            </div>
        </div>
    );

    render() {
        const {matricula} = this.props;

        return (
            this.state.loading ? this.renderLoading() : this.renderPagamento(matricula)
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        matricula: state.RenovacaoMatriculaReducer.matriculaRenovacao,
        escola: state.RenovacaoMatriculaReducer.escola,

    }
)

export default connect(mapStateToProps, {matriculaRenovacaoPagamentoFetch, pagamentoCartaoAction})(StepPagamento)


