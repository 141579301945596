import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import Editor from './component/Editor'
import './styles.css'
import {compositionsUsuarioFetch} from './../../actions/CompositionsAction'

class Composition extends Component {


    state = {
        composition: null
    }

    componentWillMount() {
        const id = this.props.match.params.id
        this.props.compositionsUsuarioFetch(this.props.usuario.id).then(
            () => {
                this.props.composition.schedules.map(item => {
                    if (parseInt(item.id) === parseInt(id))
                        this.setState({composition: item})
                    return item
                })
            }
        )
    }

    _renderTitle() {
        let {composition} = this.state

        if (composition) {
            return (
                <h4 className="small text-left">
                    <strong>{composition.courseClass.courseName} {composition.courseClass.methodName} {composition.courseClass.moduleName}</strong><br/>
                    Livro: <strong>{composition.courseClass.bookName} <br/></strong>
                    {
                        (composition.courseClass.teacher) ?
                            <span>Professor: <strong>{composition.courseClass.teacher}</strong><br/></span>
                            : null
                    }
                    {
                        (composition.composition.bookClass) ?
                            <span>Professor: <strong>{composition.composition.bookClass}</strong></span>
                            : null
                    }
                </h4>
            )
        }
        return null
    }

    render() {
        return (
            <div className="compositions">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card pb-5 mb-5">
                            <div className="card-header">
                                <h4 className="h4 strong">Bring It Out</h4>
                                {this._renderTitle()}
                            </div>
                            <div className="card-body">
                                {(this.state.composition)?(<Editor history={this.props.history} composition={this.state.composition}/>):<Spinner/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        composition: state.CompositionReducer.compositions,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {compositionsUsuarioFetch})(Composition)



