import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
//

export default class DropDownGrammarIndexes extends Component {

    constructor(props) {
        super(props);

        this.toggleAnswerKeys = this.toggleAnswerKeys.bind(this);

        this.state = {
            dropdownAnswerKeysOpen: false,
        }
    }

    toggleAnswerKeys() {
        this.setState(prevState => ({
            dropdownAnswerKeysOpen: !prevState.dropdownAnswerKeysOpen
        }))
    }

    render() {
        return (
            <div className="dropdown-grammar-keys mb-2">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggleAnswerKeys}
                                    id="button-boletim-dropdown"
                                    className="btn card-header border-left-orange p-1">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <strong className="text-nowrap">
                                            Grammar Indexes
                                        </strong>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon
                                            icon={(this.state.dropdownAnswerKeysOpen) ? faAngleUp : faAngleDown}
                                            size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownAnswerKeysOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left m-1">
                                    <div className="col-12 text-nowrap div-dropdown">
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-insight w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/GrammarIndexes/01. INSIGHT_Grammar-Quick Tips.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Insight</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-connection w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/GrammarIndexes/GRAMMAR - QUICK TIPS - CONNECTION.pdf?e=download"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Connection</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-impulse w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/GrammarIndexes/03. IMPULSE_Grammar-Quick Tips.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Impulse</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-overcome w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/GrammarIndexes/04. OVERCOME_Grammar-Quick Tips.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Overcome</strong>
                                            </a>
                                        </div>
                                        <div className="row mb-2">
                                            <a
                                                className="btn btn-secondary border-left-master w-100 text-left"
                                                href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/GrammarIndexes/05. MASTER_Grammar-Quick Tips.pdf"
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faDownload}/> <strong>Master</strong>
                                            </a>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


