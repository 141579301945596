import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons'
//
import BoletimIngles from './BoletimIngles'
import BoletimKids from './BoletimKids'
import BoletimEspanhol from './BoletimEspanhol'
import BoletimAdults from './BoletimAdults'


export default class DropdownBoletim extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {boletim} = this.props

        return (
            <div className="dropdown-boletins mb-2">
                <div className="row">
                    <div className="container">
                        <div className="card">
                            <div onClick={this.toggle}
                                 id="button-boletim-dropdown"
                                 className={
                                     (boletim.situation === 1) ? "btn border-left-orange card-header" :
                                                     (boletim.situation === 5) ? "border-left-darkgray card-header" :
                                                         (boletim.situation === 6) ? "btn border-left-green-yellow card-header" :
                                                             "btn border-left-green-yellow card-header"
                                 }
                            >
                                <div className="row">
                                    <div className="col-7 text-left" translate="no">
                                        {boletim.methodName} {boletim.bookName} - {boletim.studentName}
                                    </div>
                                    <div className="col-3">
                                        <span
                                            className={(parseFloat(boletim.totalResult) >= 70 ? "center text-info strong" : "center text-warning strong")}>{parseFloat(boletim.totalResult).toFixed(2).replace('.', ',')}
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon className="float-right"
                                                         icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                                         size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row' : 'row d-none'}>
                                <div className="card-body">
                                    <div className="col-12 ml-1 mt-1 text-left" translate="no">
                                        Turma: <strong>{boletim.courseName} {boletim.mehtodName} {boletim.moduleName}</strong> -
                                        Livro: <strong>{boletim.bookName}</strong> <br/>
                                        Professor(a): <strong>{boletim.teacherName}</strong><br/>
                                        Faltas: <strong>{boletim.misses}</strong>/<strong>{boletim.classAmount}</strong><br/>
                                        Tarefas
                                        feitas: <strong>{boletim.totalHomeWorksDone}</strong>/<strong>{boletim.totalHomeWorks}</strong><br/>
                                        Situação:
                                        {
                                            (boletim.situation === 1) ?
                                                <span className="badge badge-success"> Ativo</span> :
                                                (boletim.situation === 5) ?
                                                    <span className="badge badge-secondary"> Terminado</span> :
                                                    (boletim.situation === 6) ? <span
                                                        className="badge badge-green-yellow"> Em formação</span> : null
                                        }
                                    </div>
                                    <div className="col-12 ml-1 mt-1 text-left">
                                        {
                                            (boletim.type === 'Adulto') ? <BoletimIngles resultado={boletim.results}/> :
                                                (boletim.type === 'Adults') ?
                                                    <BoletimAdults resultado={boletim.results} courseClass={boletim} /> :
                                                    (boletim.type === 'Kids') ?
                                                        <BoletimKids resultado={boletim.results}/> :
                                                        <BoletimEspanhol resultado={boletim.results}/>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}