import React, {Component} from 'react';
import {connect} from "react-redux";
import Spinner from "react-activity/lib/Spinner";
import "react-activity/lib/Spinner/Spinner.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import b64 from 'base-64';
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {carregarCertificados} from "../../actions/AppAction"
import {URL} from "./../../services/api";

class Certificados extends Component {

    state = {
        loadingCompleted: false
    };

    async componentWillMount() {
        await this.props.carregarCertificados().then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        const {certificados, usuario} = this.props;

        const token = b64.encode(usuario.token);

        return (
            <div className="interection-list">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="container">
                            <div className="card">
                                <div className="car-header">
                                    <h4 className="h4 pt-3">{usuario.type === 'STUDENT_PRESENCIAL' ? 'Report Cards' : 'Awards'}</h4>
                                </div>
                                <div className="car-body">
                                    {
                                        (this.state.loadingCompleted) ?
                                            (
                                                (certificados && certificados.length) ? (
                                                    certificados.map(certificado => (
                                                        <div key={`${certificado.type}-${certificado.id}`}
                                                             className="m-2">
                                                            <div className="row">
                                                                <div className="container">

                                                                    <div className="card p-3">
                                                                        <div className="row">
                                                                            <div className="col-10 text-left"
                                                                                 translate="no">
                                                                                <strong>{certificado.enrollment} - {certificado.methodName} {certificado.bookName} {certificado.teacherName && certificado.teacherName}</strong>
                                                                            </div>
                                                                            <div className="col-2 text-right">
                                                                                <a target="_blank" href={`${URL}/rockfeller-public/student-report-card/${usuario.id}/token/${token}/book/${certificado.bookId}/enrollment/${certificado.enrollment}/item/${certificado.id}/print`}
                                                                                   className="btn btn-success">
                                                                                    <FontAwesomeIcon
                                                                                        className="pull-right mr-1"
                                                                                        icon={faFilePdf}
                                                                                        size="1x"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : <div className="alert alert-warning"> Você ainda não possuí um
                                                    report card!</div>

                                            )
                                            : <Spinner/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        certificados: state.AppReducer.certificados,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {carregarCertificados})(Certificados)