import React, {Component} from 'react'
import {connect} from "react-redux"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft, faMoneyBillWave} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'
//
import Produto from './Produto'

class CarrinhoCompra extends Component {
    render() {
        return (
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 carrinho-body">
                <div className="container">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-10">
                                    <h4>Carrinho de compras</h4>
                                </div>
                                <div className="col-2 text-right">
                                    <Link to="/comprar-material-pedagogico" className="btn btn-secondary"
                                          title="Continuar comprando">
                                        <FontAwesomeIcon icon={faAngleLeft}/>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            {
                                (this.props.carrinho.length > 0) ?
                                    <div className="row">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Matrícula</th>
                                                    <th>Livro</th>
                                                    <th>Valor</th>
                                                    <th></th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {this.props.carrinho.map((item) => (
                                                    <Produto key={`${item.id}-${item.enrollment}`} produto={item}/>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-12">
                                            <div className="alert alert-warning">
                                                <p>Informações importantes:</p>
                                                <p>Os livros adquiridos deverão ser retirados na
                                                    unidade <strong>{this.props.user.franchiseUnitId}</strong></p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <Link to="/pagamento" className="btn btn-success btn-block waves-effect">
                                                <FontAwesomeIcon icon={faMoneyBillWave}/> Efetuar pagamento
                                            </Link>
                                        </div>
                                    </div>
                                    : <div className="alert alert-info col-12">Nenhum produto adicionado ao
                                        carrinho.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData,
        carrinho: state.ComprasReducer.carrinho,
    }
)

export default connect(mapStateToProps, {})(CarrinhoCompra)