export const cepMask = value => {
    const index = value.substring(5, 6);

    value = value.replace(/\D/g,'');
    if (index !== '-' && index !== '') {
        const start = value.substring(0, 5);
        const end = value.substring(5, value.length)
        return start + '-' + end;
    }
    return value;
}

export const maskCelular = str => {
    let start = '';
    let end = '';

    let value = str.replace(/\D/g,'');

    if (value !== '') {
        if (value[0] && value[0] !== '(') {
            value = `(${value}`;
        }

        if (value[3] && value[3] !== ')') {
            start = value.substring(0, 3);
            end = value.substring(3, value.length);
            value = `${start})${end}`;
        }

        if(value[9] && value[9] !== '-') {
            start = value.substring(0, 9);
            end = value.substring(9, value.length);
            value = `${start}-${end}`;
        }
    }
    return value;
}