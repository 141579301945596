import React, {Component} from "react"
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaperPlane, faBackward} from '@fortawesome/free-solid-svg-icons'
//
import Logo from "../../assets/logo-login.svg"
import {resetingPassword} from "./../../actions/LoginAction"
import "./styles.css"
import {Link} from "react-router-dom";

class RecuperarSenha extends Component {

    state = {
        email: '',
        loading: false,
        error: ''
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true})
        this.props.resetingPassword(this.state.email).then(
            () => {
                this.setState({loading: false});
                alert('Um e-mail com o link para rededefinição de senha foi enviado, verique sua caixa de e-mail!');
                this.props.history.push('/');
            }
        ).catch(error => {
            this.setState({loading: false});
            if (error.response && error.response.data)
                alert(error.response.data);
            else
                alert('Houve um erro ao tentar recuperar a sua senha, tente novamente mais tarde!');
        })
    };


    componentDidMount() {
        if(this.props.match && this.props.match.params && this.props.match.params.email) {
            const email = this.props.match.params.email;
            this.setState({email});
        }
    }

    render() {
        return (

            <div className="home card">

                <div className="card-body">
                    <div className="row">
                        <div className="container">

                            <div className='text-center'>
                                <div className="col-12 mb-3">
                                    <img src={Logo} alt="My Rockfeller logo" width="100"/>
                                    <br/>
                                    <br/>
                                </div>

                                <div className="alert alert-light col-12 col-md-6 col-xl-6 offset-md-3 offset-xl-3">
                                    <div className="mt-3 mb-3">
                                        <h3>Recuperar senha</h3>
                                        <p>Informe seu email cadastrado e clique em enviar. Você receberá um e-mail com
                                            o
                                            link
                                            de
                                            definição de senha.</p>
                                    </div>
                                    <form className="mt-3" ref={f => (this.form = f)} onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Informe o seu e-mail"
                                                required
                                                autoFocus
                                                value={this.state.email}
                                                onChange={text => this.setState({email: text.target.value})}
                                            />
                                        </div>
                                        <div className="form-actions mt-3">
                                            {
                                                (this.state.loading) ?
                                                    <div className="text-center"><Spinner/></div> :
                                                    <button translate="no" type="submit"
                                                            className="btn btn-info col-12">
                                                        <FontAwesomeIcon icon={faPaperPlane}/> Enviar
                                                    </button>
                                            }
                                            <Link translate="no" to="/" className="btn col-12 btn-secondary mt-3">
                                                <FontAwesomeIcon icon={faBackward}/> Voltar
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(null, {resetingPassword})(RecuperarSenha)
