import React from 'react'
import {Link} from "react-router-dom"
//


export default class ConteudoOnlineKidsItem extends React.Component {
    render() {
        const {atividade} = this.props

        return (
            <div className="col-4 col-md-3 p-1">
                <div className="card card-body p-1">
                    <div className="item">
                        {(atividade.book) ?
                            <Link to={`/${this.props.atividade.book}/conteudo-online-livro`}>
                                <img src={`${atividade.miniaturaSrc}`} className="w-100 r-t" alt={`${atividade.nome}`}/>

                                <div className="p">
                                <span className="h6">
                                    <div className="text-info">
                                        {atividade.nome}
                                    </div>

                                </span>
                                </div>

                            </Link>
                            :
                            <Link to={`/${atividade.id}/conteudo-online-kids-livro`}>
                                <img src={`${atividade.miniaturaSrc}`} className="w-100 r-t" alt={`${atividade.nome}`}/>

                                <div className="p">
                                <span className="h6">
                                    <div className="text-info">
                                        {atividade.nome}
                                    </div>

                                </span>
                                </div>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        )
    }
}