import React from 'react'
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
//
import {connect} from "react-redux"
import {URL} from "../../services/api"

class ConteudoOnlineItem extends React.Component {

    state = {
        url: ''
    }

    componentWillMount() {
        if (parseInt(this.props.atividade.kt) === 1 && parseInt(this.props.atividade.idAula) > 0) {
            this.setState({url: `/${this.props.atividade.id}/conteudo-online-livro`})
        } else {
            this.setState({url: `/${this.props.atividade.id}/conteudo-online-livro`})
        }
    }

    render() {
        const {atividade} = this.props

        return (
            <div className="col-4 col-md-3 p-1">
                <div className="card card-body p-1">
                    <div className="item">
                        <Link to={this.state.url}>
                            <img src={`${atividade.miniaturaSrc}`} className="w-100 r-t" alt={`${atividade.nome}`}/>

                            <div className="p">
                                <span className="h6">
                                    <div className="text-info">
                                        {atividade.nome}
                                    </div>

                                </span>
                                {
                                    (atividade.cdId && atividade.download) ?
                                        <div className="float-right top-10">
                                            <a className="btn btn-danger bmd-btn-fab active rounded-circle"
                                               href={`${URL}/student-payment/${this.props.usuario.id}/token/${this.props.usuario.token}/cd_download/${atividade.cdId}`}
                                               title="Download"
                                            >
                                                    <FontAwesomeIcon icon={faDownload}/>
                                            </a>
                                        </div>
                                        : null
                                }
                            </div>

                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, null)(ConteudoOnlineItem)


