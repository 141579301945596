import React, {Component} from 'react'
import {
    faAngleRight, faCircle
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from "react-router-dom";
//

export default class Notificacao extends Component {

    _getNow = () => {
        const date = new Date();
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    };

    _formatDate = str => {
        const date = new Date(str);
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    }


    render() {
        const {notificacao} = this.props;
        return (
            <div className="row">
                <div className="col-1">
                    <FontAwesomeIcon icon={faCircle}
                                     color={(this._formatDate(notificacao.date) <= this._getNow()) ? 'red' : 'green'}/>
                </div>
                <div className="col-10 text-left">
                    <p>
                        <span
                            className="text-decoration-clear">{/*notificacao.date_BR*/} {notificacao.title}</span>
                        <br/>
                        {
                            notificacao.url ? (
                                <Link to={notificacao.url}>
                                    <span className="text-black">{notificacao.description}</span>
                                </Link>
                            ) :(
                                <span>{notificacao.description}</span>
                            )
                        }
                    </p>
                </div>
            </div>
        )
    }
}
