import React, {Component} from "react"
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faBackward} from '@fortawesome/free-solid-svg-icons'
//
import Logo from "../../assets/logo-login.svg"
import {changePassword} from "./../../actions/LoginAction"
import {loadUserById} from "./../../actions/AppAction"
import "./styles.css"
import {Link} from "react-router-dom";

class NovaSenha extends Component {

    state = {
        password: '',
        confirmPassword: '',
        loading: false,
        error: ''
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const {userId, type, token} = this.props.match.params;

        this.setState({loading: true});

        if (this.state.password === this.state.confirmPassword) {
            if (this.state.password.length < 6) {
                alert('Sua senha deve ter no mínimo 6 caracteres.')
            } else {
                this.props.changePassword(userId, type, token, this.state.password).then(
                    () => {
                        this.setState({loading: false});
                        alert('Sua senha foi alterada com sucesso!');
                        this.props.history.push('/');
                    }
                ).catch(error => {
                    this.setState({loading: false});
                    if (error.response && error.response.data && !error.response.data.code)
                        alert(error.response.data);
                    else
                        alert('Houve um erro ao tentar alterar a sua senha, tente novamente mais tarde!');
                })
            }
        } else {
            alert('Senhas não conferem.');
        }
    };

    async componentWillMount() {
        const token = this.props.match.params.token;

        await this.props.loadUserById(token).catch(error => {
            if (error.response && error.response.data && !error.response.data.code)
                alert(error.response.data);
        });
        console.log(this.props)
    }

    render() {
        return (

            <div className="home card">

                <div className="card-body">
                    <div className="row">
                        <div className="container">

                            <div className="col-12 mb-3">
                                <img src={Logo} alt="My Rockfeller logo" width="100"/>
                                <br/>
                                <br/>
                            </div>

                            <div className="alert alert-light col-12 col-md-6 col-xl-6 offset-md-3 offset-xl-3">
                                <div className="mt-3 mb-3">
                                    <h3>Definir senha</h3>
                                    <p>Informe a nova senha de acesso..</p>
                                </div>
                                <form className="mt-3" ref={f => (this.form = f)} onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Informe sua nova senha"
                                            required
                                            autoFocus
                                            minLength={6}
                                            onChange={text => this.setState({password: text.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            className="form-control"
                                            placeholder="Confirme sua nova senha"
                                            required
                                            minLength={6}
                                            onChange={text => this.setState({confirmPassword: text.target.value})}
                                        />
                                    </div>
                                    <div className="form-actions mt-3">
                                        {
                                            (this.state.loading) ?
                                                <div className="text-center"><Spinner/></div> :
                                                <button translate="no" type="submit" className="btn btn-info col-12">
                                                    <FontAwesomeIcon icon={faSave}/> Alterar
                                                </button>
                                        }
                                        <Link translate="no" to="/" className="btn col-12 btn-secondary mt-3">
                                            <FontAwesomeIcon icon={faBackward}/> Voltar
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData
    }
)


export default connect(mapStateToProps, {changePassword, loadUserById})(NovaSenha)
