import api from './../services/api'
import {MODIFICA_SUPORTE_LIST, SUPPORT_CADASTRO_SUCESSO, RESETAR_DIAS_AGENDAMENTO} from './types'

export const suportesDiasCheck = (id, dias) => dispatch =>
    api.post('supports/days', {_id: id, _days: dias}).then(
        response => dispatch({type: MODIFICA_SUPORTE_LIST, payload: response.data})
    )

export const suporteAgendarAction = (id, suporte) => dispatch =>
    api.post('support/new', {_id: id, _idSupport: suporte}).then(
        response => dispatch({type: SUPPORT_CADASTRO_SUCESSO, payload: response.data})
    )

export const resetarDias = () => dispatch => ({type: RESETAR_DIAS_AGENDAMENTO, payload: []})

export const diasDisponiveisSuporte = (id) => dispatch =>
    api.post(`/supports/days-hours`, {_id: id})
        .then(res => {
            return res.data
        })