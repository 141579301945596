import {sessionService} from 'redux-react-session'
//
import api from './../services/api'

import {
    MODIFICA_USUARIO,
    MODIFICA_SENHA,
    LOGIN_EM_ANDAMENTO,
    LOGIN_ERROR_MESSAGE,
    LOGOUT_SUCCESS, MODIFICA_USER_DATA,
    RECOVER_PASSWORD
} from '../actions/types'
import {TOKEN_KEY, addItemLocalStorage, addSession, addUserSession} from '../services/auth'
import {RESEARCH} from '../services/pesquisa'

export const modificarUsuario = (texto) => ({
    type: MODIFICA_USUARIO,
    payload: texto
})

export const modificarSenha = (texto) => ({
    type: MODIFICA_SENHA,
    payload: texto
})

export const loginEmAndamento = status => ({
    type: LOGIN_EM_ANDAMENTO,
    payload: status
})

export const loginErrorMessage = (error = null) => ({
    type: LOGIN_ERROR_MESSAGE,
    payload: (error) ? error : 'Usuário ou senha inválido'
})

export const autenticarUsuario = (usuario, senha) => dispatch =>
    api.post(`/login`, {_username: usuario, _password: senha})
        .then(res => {
            if (res.data.student && !res.data.isRenewDuo) {
                //localStorage.setItem(TOKEN_KEY, res.data.token);
                addItemLocalStorage(res.data.token, TOKEN_KEY);

                localStorage.removeItem(RESEARCH);
                //sessionService.saveSession(res.data.token);
                addSession(res.data.token);

                let user = res.data;
                user['carrinho'] = [];

                //sessionService.saveUser(user);
                addUserSession(user);

                return dispatch(loginSuccess(res.data));
            } else if(res.data.isRenewDuo) {
                //localStorage.setItem(TOKEN_KEY, res.data.token);
                addItemLocalStorage(res.data.token, TOKEN_KEY);

                dispatch(loginSuccess(res.data));
                throw 'MATRICULA_RENOVACAO'
            } else {
                throw 'Sentimos sua falta. Volta pra Rock, estamos esperando você =)'
            }
        });

export const resetingPassword = (email) => dispatch =>
    api.post('/users/resetting-password', {email}).then(
        res => dispatch({type: RECOVER_PASSWORD, payload: res.data})
    );

export const changePassword = (id, type, token, password) => dispatch =>
    api.post('users/change-password', {id, token, type, password}).then(
        res => dispatch({type: RECOVER_PASSWORD, payload: res.data})
    );

export const loginSuccess = (user) => ({
    type: MODIFICA_USER_DATA,
    payload: user
})

export const logoutUsuario = () => {
    return dispatch => {
        localStorage.removeItem(RESEARCH);
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(RESEARCH)
        sessionService.deleteSession()
        sessionService.deleteUser()
        dispatch({type: LOGOUT_SUCCESS, payload: []})
    }
}

