import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
//

export default class DropDownPresencialSemiIntensivo extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    render() {
        return (
            <div className="dropdown-presencial-regular text-wrap">
                <div className="row">
                    <div className="container">
                        <div className="card">
                            <button onClick={this.toggle}
                                    id="button-boletim-dropdown"
                                    className="btn card-header border-left-yellow p-1 mt-2 mb-3">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <strong className="text-nowrap">
                                            Presencial Semi Intensivo
                                        </strong>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon
                                            icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                            size="1x"/>
                                    </div>
                                </div>
                            </button>

                            <div className={(this.state.dropdownOpen) ? '' : 'd-none'}>

                                <div className="col-12 mb-2">
                                    <a
                                        className="btn btn-secondary border-left-insight w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/01 Insight/Study guide Insight - Seg-Qua-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide Insight(Seg-Qua |
                                        Ter-Qui)</strong>
                                    </a>
                                </div>
                                <div className="col-12 mb-2">
                                    <a
                                        className="btn btn-secondary border-left-insight w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/01 Insight/Study guide Insight - Ter-Qui-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide
                                        Insight(Sábado)</strong>
                                    </a>
                                </div>

                                <div className="col-12 mb-2 mt-3">
                                    <a
                                        className="btn btn-secondary border-left-connection w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/02 Connection/Study guide Connection - Seg-Qua-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide Connection(Seg-Qua |
                                        Ter-Qui)</strong>
                                    </a>
                                </div>
                                <div className="col-12 mb-2">
                                    <a
                                        className="btn btn-secondary border-left-connection w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/02 Connection/Study guide Connection - Ter-Qui-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide
                                        Connection(Sábado)</strong>
                                    </a>
                                </div>

                                <div className="col-12 mb-2 mt-3">
                                    <a
                                        className="btn btn-secondary border-left-impulse w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/03 Impulse/Study guide Impulse - Seg-Qua-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide Impulse(Seg-Qua |
                                        Ter-Qui)</strong>
                                    </a>
                                </div>
                                <div className="col-12 mb-2">
                                    <a
                                        className="btn btn-secondary border-left-impulse w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/03 Impulse/Study guide Impulse - Ter-Qui-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide
                                        Impulse(Sábado)</strong>
                                    </a>
                                </div>

                                <div className="col-12 mb-2 mt-3">
                                    <a
                                        className="btn btn-secondary border-left-overcome w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/04 Overcome/Study guide Overcome - Seg-Qua-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide Overcome(Seg-Qua |
                                        Ter-Qui)</strong>
                                    </a>
                                </div>

                                <div className="col-12 mb-2">
                                    <a
                                        className="btn btn-secondary border-left-overcome w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/04 Overcome/Study guide Overcome - Ter-Qui-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide
                                        Overcome(Sábado)</strong>
                                    </a>
                                </div>

                                <div className="col-12 mb-2 mt-3">
                                    <a
                                        className="btn btn-secondary border-left-master w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/05 Master/Study Guide Master - Ter-Qui-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide Master(Seg-Qua |
                                        Ter-Qui)</strong>
                                    </a>
                                </div>

                                <div className="col-12">
                                    <a
                                        className="btn btn-secondary border-left-master w-100 text-left"
                                        href="https://joy.rockfellerbrasil.com.br/uploads/MaterialDeApoio/RoteiroDeEstudos/02 Presencial Semi Intensivo/05 Master/Study guide Master - Seg-Qua-Sexta.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faDownload}/> <strong>Study Guide
                                        Master(Sábado)</strong>
                                    </a>
                                </div>

                                <hr/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


