import React, {Component} from 'react'
import {connect} from "react-redux"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import AtividadeComplementarItem from "./../../components/AtividadeComplementarItem"
import './styles.css'
import {atividadesUsuarioFetch} from './../../actions/AtividadeComplementarAction'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"


class AtividadeComplementar extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        this.props.atividadesUsuarioFetch(this.props.usuario.id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="atividade-complementar">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card">
                            <div className="card-header text-left">
                                <h4 className="h4 text-center">Atividades Disponíveis</h4>
                                <small className="small">
                                    O conteúdo a ser exibido é disponibilizado após a compra do material pedagógico.
                                </small>
                            </div>
                            <div className="card-body">
                                <div className="row conteudo-atividade-complementar">
                                    {
                                        (this.state.loadingCompleted) ?
                                            (this.props.atividades.length)? this.props.atividades.map(item => (
                                                <AtividadeComplementarItem key={item.id} atividade={item}
                                                                           history={this.props.history}/>
                                            )):<div className="w-100 text-center alert alert-info">Você não possui nenhuma atividade!</div>
                                            : <div className="w-100 text-center"><Spinner/></div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        atividades: state.AtividadeComplementarReducer.atividades,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {atividadesUsuarioFetch})(AtividadeComplementar)



