import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './styles.css'
import {carregarInterectionList} from "../../actions/AppAction"
import InterectionDropDown from './InterectionDropDown'


class Interection extends Component {

    state = {
        loadingCompleted: false
    };

    async componentWillMount() {
        await this.props.carregarInterectionList().then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="interection-list">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="container">
                            <div className="card">
                                <div className="car-header">
                                    <h4 className="h4 pt-3">Interaction class</h4>
                                </div>
                                <div className="car-body">
                                    {
                                        (this.state.loadingCompleted) ?
                                            this.props.interections.map(item => (
                                                <InterectionDropDown key={item.id} interection={item} />
                                            )): <Spinner/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        interections: state.AppReducer.interections,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {carregarInterectionList})(Interection)