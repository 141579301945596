import React, {Component} from 'react'
import {connect} from 'react-redux'
//
import {diasAulaFetch} from '../../../actions/AgendarAulaPresencialAction'

class CheckboxDia extends Component {
    render() {
        let title = '';
        if (this.props.valor && this.props.valor.hours && this.props.valor.hours.length > 1)
            this.props.valor.hours.map(item => title = title + item + ', ');
        return (
            <div className="col-6 col-xl-4">
                <label
                    title={title}
                    className={
                        this.props.dias.indexOf(this.props.valor.data) >= 0 ? 'btn cinza w-100 mt-3 bg-orange' : 'btn cinza w-100 mt-3 bg-clear'
                    }
                    id={this.props.valor.data}
                    htmlFor={this.props.index}
                >
                    <strong>{this.props.title}</strong><br/>
                    <small>{this.props.diaString}</small>
                </label>
                <input
                    className="d-none"
                    type="checkbox"
                    name={this.props.index}
                    id={this.props.index}
                    value={this.props.valor.data}
                    onClick={
                        () => {
                            this.props.diasAulaFetch(this.props.valor);
                        }
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        dias: state.AgendarAulaPresencialReducer.dias
    }
)

export default connect(mapStateToProps, {diasAulaFetch})(CheckboxDia)
