import React, {Component} from "react"
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave} from '@fortawesome/free-solid-svg-icons'
//
import {motivosCancelarMatriculaList, solicitarCancelamentoMatricula} from "./../../actions/AppAction"
import "./styles.css"
import Header from "../../components/Header";
import Menu from "../../components/Menu";

class SolicitarRecisao extends Component {

    state = {
        loading: false,
        error: '',
        message: null,
        form: [],
        questionsSelect: [],
        questionsComment: [],
        allQuestions: [],
    };

    async componentDidMount() {
        this.setState({loading: true});
        await this.props.motivosCancelarMatriculaList().catch(
            error => {
                if (error.response && error.response.data) {
                    this.setState({message: error.response.data, error: true});
                } else
                    this.setState({
                        message: 'Ocorreu um erro enquanto solicitava o formulário, tente novamente mais tarde',
                        error: true
                    });
                this.setState({loading: false})
            }
        );
        await this.setQuestions();
        this.setState({loading: false})
    }

    handleInputChange = ({target}) => {
        const form = this.state.form;
        form[target.name] = target.value;
        this.setState({form});
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const form = this.state.form;
        const messageSucess = this.props.questionario.message_success;
        this.setState({loading: true});

        const isValid = await this.validateForm(form);
        if (isValid === true) {
            this.props.solicitarCancelamentoMatricula(this.props.questionario.id, Object.entries(form))
                .then(
                    () => {
                        this.setState({message: messageSucess});
                        //this.props.history.push('/')
                        this.setState({loading: false})
                    }
                ).catch(error => {
                    if (error.response && error.response.data) {
                        this.setState({message: error.response.data, error: true});
                    } else
                        this.setState({
                            message: 'Ocorreu um erro enquanto solicitava o cancelamento, tente novamente mais tarde',
                            error: true
                        });
                    this.setState({loading: false})
                }
            );
        } else if (isValid === false) {
            this.setState({loading: false});
            alert('Você precisa responder umas das questões além da questão aberta!');
        } else {
            alert(`A questão ${isValid} é obrigatório ter um comentário!`);
            this.setState({loading: false});
        }
    };

    validateForm = async form => {
        const {questionsSelect, questionsComment, allQuestions} = this.state;
        let isSelect = false, isComment = false;
        let questionInvalid = null;

        if (Object.keys(form)) {
            await Object.keys(form).map(key => {
                let index = key.replace('[', '_');
                index = index.replace(']', '');

                index = index.split('_');

                if (index[0] === 'comments' && questionsComment.indexOf(parseInt(index[1])) >= 0 && form[key].length > 1) {
                    isComment = true;
                }

                if (index[0] === 'option' && questionsSelect.indexOf(parseInt(index[1])) >= 0 && form[key] > 0 && form[`comments[${index[1]}]`] && form[`comments[${index[1]}]`].length > 1) {
                    isSelect = true;
                } else if (index[0] === 'option' && questionsSelect.indexOf(parseInt(index[1])) >= 0 && form[key] > 0) {
                    isSelect = false;
                    questionInvalid = allQuestions[index[1]].name;
                }

                if (isSelect && isComment)
                    return true;
            });
        }

        if (questionInvalid)
            return questionInvalid;

        return (isSelect && isComment) ? true : false;
    }

    setQuestions = async () => {
        const questionario = this.props.questionario;
        const allQuestions = this.state.allQuestions;
        let questionsSelect = this.state.questionsSelect;
        if (!questionsSelect)
            questionsSelect = [];

        let questionsComment = this.state.questionsComment;
        if (!questionsComment)
            questionsComment = [];

        if (questionario && questionario.id) {
            await questionario.items.map(group => {
                group.items && group.items.map(question => {
                    if (question.response_question && question.response_question.items_response_question) {
                        questionsSelect.push(question.id);
                    } else {
                        questionsComment.push(question.id);
                    }
                    allQuestions[question.id] = question;
                });
            });
            this.setState({questionsSelect, questionsComment});
        }
    }

    render() {
        const questionario = this.props.questionario;

        return (
            <div className="recisao">
                <Header/>
                <div className="row text-center" translate="no">
                    <Menu/>

                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="row">
                            <div className="container">

                                <div className="card">
                                    <div className="card-header">
                                        <h4>Solicitação de rescisão</h4>
                                        <p>{questionario && questionario.message_wellcome}</p>
                                    </div>

                                    <div className="card-body text-left">

                                        {
                                            !this.state.message ? questionario && questionario.id && (
                                                <form action="mt-2" ref={c => (this.form = c)}
                                                      onSubmit={this.handleSubmit}>
                                                    {
                                                        questionario.items.map(group => (
                                                            <div key={group.id} className='row'>
                                                                <div className="col-12" align="center">
                                                                    <h4 className="strong">{group.name}</h4>
                                                                </div>

                                                                <div className="col-12">
                                                                    {
                                                                        group.items && group.items.map(question => (
                                                                            <div className="row mt-3" key={question.id}>
                                                                                <div
                                                                                    className="col-12 col-md-4 col-xl-4">
                                                                                    <h5 className="strong h5">{question.name}</h5>
                                                                                </div>
                                                                                {
                                                                                    question.response_question && question.response_question.items_response_question && (
                                                                                        <>
                                                                                            {
                                                                                                question.type === 'RADIO' ? (
                                                                                                    <div
                                                                                                        className="col-12 col-md-4 col-xl-4">
                                                                                                        {
                                                                                                            question.response_question.items_response_question.map(option => (
                                                                                                                <div
                                                                                                                    key={option.id}
                                                                                                                    className="radio col-12">
                                                                                                                    <label>
                                                                                                                        <input
                                                                                                                            onChange={this.handleInputChange}
                                                                                                                            type="radio"
                                                                                                                            name={`option[${question.id}]`}
                                                                                                                            value={option.id}
                                                                                                                            required
                                                                                                                        /> {option.name}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <select
                                                                                                        required
                                                                                                        placeholder="Selecione uma resposta"
                                                                                                        onChange={this.handleInputChange}
                                                                                                        name={`option[${question.id}]`}
                                                                                                        className="col-12 col-md-4 col-xl-4 form-control">
                                                                                                        >
                                                                                                        <option
                                                                                                            value={0}
                                                                                                            name={`option[${question.id}]`}
                                                                                                        >
                                                                                                            Selecione
                                                                                                            uma
                                                                                                            resposta
                                                                                                        </option>
                                                                                                        {
                                                                                                            question.response_question.items_response_question.map(option => (
                                                                                                                <option
                                                                                                                    key={option.id}
                                                                                                                    value={option.id}

                                                                                                                    name={`option[${question.id}]`}
                                                                                                                >
                                                                                                                    {option.name}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </select>
                                                                                                )

                                                                                            }

                                                                                        </>

                                                                                    )
                                                                                }
                                                                                {
                                                                                    (question.response_question && question.can_comment) && (
                                                                                        <div
                                                                                            className='col-12 col-md-4 col-xl-4'>
                                                                                        <textarea
                                                                                            onChange={this.handleInputChange}
                                                                                            name={`comments[${question.id}]`}
                                                                                            placeholder='Comentário'
                                                                                            className="form-control w-100"
                                                                                            required={false}
                                                                                        >
                                                                                        </textarea>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    !question.response_question && (
                                                                                        <div
                                                                                            className='col-12 col-md-8 col-xl-8'>
                                                                                        <textarea
                                                                                            onChange={this.handleInputChange}
                                                                                            name={`comments[${question.id}]`}
                                                                                            placeholder="Resposta"
                                                                                            className="form-control w-100"
                                                                                            required
                                                                                        >
                                                                                        </textarea>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        ))
                                                                    }

                                                                    <hr/>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="row mt-3">
                                                        <div className="col-6 offset-3" align="center">
                                                            <button type="submit" className="btn btn-block btn-success">
                                                                <FontAwesomeIcon icon={faSave}/>&nbsp;
                                                                Salvar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            ) :
                                                <div
                                                    className={this.state.error ? "alert alert-warning col-12" : "alert alert-info col-12"}>
                                                    {this.state.message}
                                                </div>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData,
        questionario: state.AppReducer.questionario,
    }
);


export default connect(mapStateToProps, {
    motivosCancelarMatriculaList,
    solicitarCancelamentoMatricula
})(SolicitarRecisao)
