import React, {Component} from 'react'
//
import "./styles.css"
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import Body from './Body'

class Dashboard extends Component {
    render() {
        return (
            <div className="dashboard">
                <Header/>
                <div className="row text-center notranslate" translate="no">
                    <Menu/>
                    <Body/>
                </div>
            </div>
        )
    }
}

export default Dashboard
