import React, {Component} from 'react'
import {connect} from "react-redux"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faPaperPlane, faCheck} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {compositionsUsuarioFetch} from './../../actions/CompositionsAction'

class Composition extends Component {

    state = {
        loadingCompleted: false,
        error: ''
    }

    componentWillMount() {
        this.props.compositionsUsuarioFetch(this.props.usuario.id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => {
                this.setState({error: 'Ocorreu um erro, por favor tente mais tarde!'});
                this.setState({loadingCompleted: true})
            }
        )
    }

    _renderTitle() {
        let {composition} = this.props
        if (composition !== null && composition.courseClass !== undefined) {
            return (
                <h4 className="small text-left">
                    {
                        (composition.courseClass.teacher) ?
                            <span>
                                <strong>{composition.courseClass.courseName} {composition.courseClass.methodName} {composition.courseClass.moduleName}</strong><br/>
                                Livro: <strong>{composition.courseClass.bookName} <br/></strong>
                            </span> : null
                    }
                    {
                        (composition.courseClass.teacher) ?
                            <span>Professor: <strong>{composition.courseClass.teacher}</strong></span>
                            : null
                    }
                </h4>
            )
        }
        return null
    }

    render() {
        return (
            <div className="boletins">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card pb-5 mb-5">
                            <div className="card-header">
                                <h4 className="h4 strong">Bring It Out</h4>
                                {
                                    (this.props.composition)?this._renderTitle():null
                                }
                            </div>
                            <div className="card-body">
                                {
                                    (this.state.loadingCompleted)?
                                    (this.props.composition && this.props.composition.schedules) ?
                                        this.props.composition.schedules.map(item => (
                                            (parseInt(item.statusComposition) !== 5)?
                                            <Link to={`/composition/${item.id}/write`}
                                                  className="btn btn-lg btn-secondary mb-3 text-left w-100"
                                                  key={item.id}>
                                                {(item.date_BR) ? <span><strong>{item.date_BR}</strong> -</span> : null}
                                                Aula <strong>{item.bookClass}</strong>
                                                <span className="float-right">
                                                    {
                                                        (item.statusComposition === 1) ? (
                                                                <FontAwesomeIcon icon={faEdit} size="1x"/>) :
                                                            (item.statusComposition === 2 || item.statusComposition === 4) ? (
                                                                    <FontAwesomeIcon icon={faPaperPlane} size="1x"/>) :
                                                                (item.statusComposition === 3) ? (
                                                                    <FontAwesomeIcon icon={faCheck} size="1x"/>) : null
                                                    }
                                                </span>
                                            </Link>:
                                                <div className="btn-lg btn-secondary mb-3 text-left w-100" key={item.id}>
                                                    {(item.date_BR) ? <span><strong>{item.date_BR}</strong> -</span> : null}
                                                    Aula <strong>{item.bookClass}</strong>
                                                    <span className="float-right">
                                                    {
                                                        (item.statusComposition === 1) ? (
                                                                <FontAwesomeIcon icon={faEdit} size="1x"/>) :
                                                            (item.statusComposition === 2) ? (
                                                                    <FontAwesomeIcon icon={faPaperPlane} size="1x"/>) :
                                                                (item.statusComposition === 3) ? (
                                                                    <FontAwesomeIcon icon={faCheck} size="1x"/>) : null
                                                    }
                                                </span>
                                                </div>
                                        )) : (this.state.error)? <div className="col-12 text-center bg-info"><span>{this.state.error}</span></div>: <div className="col-12 text-center bg-info"><span>Você não possui compositions!</span></div>
                                        :<div className="text-center"><Spinner/></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        composition: state.CompositionReducer.compositions,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {compositionsUsuarioFetch})(Composition)



