import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faCalendar, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import {URL} from "../../services/api";
import {connect} from "react-redux";

//

class ParcelaDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {parcel} = this.props

        return (
            <div className="dropdown-parcelas m-2">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggle}
                                 id="button-boletim-dropdown"
                                 className={(parcel.status === 1) ? "btn card-header border-left-yellow p-1" :
                                     (parcel.status === 2) ? "btn card-header border-left-orange" :
                                         (parcel.status === 3 || parcel.status === 5 || parcel.status === 11) ? "btn card-header border-left-darkgray" :
                                             (parcel.status === 4) ? "btn card-header border-left-yellow" :
                                                 "btn card-header border-left-darkgray"
                                 }
                            >
                                <div className="row">
                                    <div className="col-3 text-left">
                                        <span className="text-nowrap">{parcel.id}</span>
                                    </div>
                                    <div className="col-4 text-muted text-nowrap">
                                        <small>R${parseFloat(parcel.value).toFixed(2).replace('.', ',')}</small>
                                    </div>
                                    <div className="col-4">
                                        <small translate="no" className="text-muted small text-nowrap"><FontAwesomeIcon
                                            icon={faCalendar}/> {parcel.dueDate_BR}</small>
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon className="pull-right mr-1"
                                                         icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                                         size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left p-2">
                                    <div className="col-12 div-dropdown">
                                        <p className="d-block">Cliente: <strong>{parcel.client}</strong></p>
                                        <p className="d-block"> Forma de pagamento: <strong>{parcel.payment}</strong>
                                        </p>
                                        <p className="d-block"> Valor: <strong>R${parseFloat(parcel.value).toFixed(2).replace('.', ',')}</strong>
                                        </p>
                                        <p className="d-block"> Status: <strong>
                                            {
                                                (parcel.status === 1) ?
                                                    <span
                                                        className="badge badge-info">A VENCER</span> :
                                                    (parcel.status === 2) ? <span
                                                            className="badge badge-danger">VENCIDA</span> :
                                                        (parcel.status === 3) ? <span
                                                                className="badge badge-success">PAGA DIA {parcel.paymentDate_BR}</span> :
                                                            (parcel.status === 4) ?
                                                                <span
                                                                    className="badge badge-danger">RENEGOCIADO DIA {parcel.paymentDate_BR}</span> :
                                                                (parcel.status === 5 || parcel.status === 7 || parcel.status === 8 || parcel.status === 9) ?
                                                                    <span
                                                                        className="badge badge-green-yellow">BAIXA MANUAL {parcel.paymentDate_BR}</span> :
                                                                    (parcel.status === 11) ? <span
                                                                            className="badge badge-warning">PAGO (CARTÃO DE CRÉDITO) DIA {parcel.paymentDate_BR}</span> :
                                                                        null
                                            }
                                        </strong></p>
                                        {
                                            ((parcel.payment.toUpperCase() === 'BOLETO') && (parseInt(parcel.status) === 1 || parseInt(parcel.status) === 2) && (parcel.purchase || parseInt(parcel.quantityParcel === 1) || (this.props.user.method !== 'On Demand' && this.props.user.type !== 'STUDENT_DUO'))) ?
                                                <a target="_blank"
                                                   href={`${URL}/student-payment/${this.props.user.id}/parcel/${parcel.id}/inovice/0/type/1/print`}>
                                                    <span
                                                        className="h6 btn btn-light text-dark">Visualizar boleto</span>
                                                </a>
                                                : (this.props.user.type === 'STUDENT_DUO' && parcel.send && (parseInt(parcel.status) === 1 || parseInt(parcel.status) === 2) ) ?
                                                <a target="_blank"
                                                   href={`${URL}/student-payment/${this.props.user.id}/parcel/${parcel.id}/inovice/0/type/1/print`}>
                                                    <span
                                                        className="h6 btn btn-light text-dark">Visualizar boleto</span>
                                                </a>
                                                : null
                                        }
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData,
    }
)

export default connect(mapStateToProps, null)(ParcelaDropdown)