import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faCalendar} from '@fortawesome/free-solid-svg-icons'
import {URL} from "../../services/api";
import {connect} from "react-redux";

//

class InterectionDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {interection} = this.props;
        const date = new Date(interection.date);

        return (
            <div className="interection-dropdown m-2">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggle}
                                 id="button-boletim-dropdown"
                            >
                                <div className="row">
                                    <div className="col-11 text-left">
                                        <strong className="notranslate" translate="no">{interection.name}</strong>
                                    </div>
                                    <div className="col-1">
                                        <FontAwesomeIcon className="pull-right mr-1"
                                                         icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                                         size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left p-2">
                                    <div className="col-12 div-dropdown">
                                        <p><strong>Professor(a): </strong>{interection.teacher}</p>
                                        <p><strong>Data: </strong>{date.toLocaleDateString()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData,
    }
)

export default connect(mapStateToProps, null)(InterectionDropDown)