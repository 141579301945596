import React, {Component} from 'react'
//
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import DiasAgendamento from './components/DiasAgendamento'
import './styles.css'

export default class AgendarAulaPresencial extends Component {
    render() {
        return (
            <div className="agendar-aula-presencial">
                <Header/>
                <div className="row">
                    <Menu/>
                    <DiasAgendamento history={this.props.history}/>
                </div>
            </div>
        )
    }
}
