/*
1 Adulto
2 Kids 1
3 Teens
4 Adulto
5 Adulto2
6 TOEFL
7 Practice & Progress
8 Practice & Progress Part II
9 Kids Rocket
10 Kids Blink
11 Workbook
12 Workbook Sophomore
13 Adults
14 Rockfeller Duo
15 On Demand
16 Live Inglês
17 Live Espanhol
*/
export const getPermission = (user) => {
    const permission = [];

    if (!user || !user.methodsIds)
        return permission;

    if (user.type !== 'STUDENT_DUO' || user.isPurchased) {
        permission.push('minhas-compras');
    }

    if (user.type !== 'STUDENT_DUO' || user.isPurchase) {
        permission.push('comprar-material-pedagogico');
    }

    if (user.type !== 'RESPONSIBLE_STUDENT' && user.type !== 'STUDENT_DUO' && false) {
        permission.push('simulado-exames-internacionais');
    }

    if (user.methodsIds.indexOf(13) >= 0 || user.methodsIds.indexOf(14) >= 0 || user.methodsIds.indexOf(15) >= 0 || user.methodsIds.indexOf(16) >= 0) {
        permission.push('rockfeller-play');
    }

    const methodsInvalid = [2, 9, 10];
    if ((user.type === 'RESPONSIBLE_STUDENT' || user.type === 'STUDENT_PRESENCIAL' || user.type === 'RESPONSIBLE_STUDENT') && methodsInvalid.indexOf(user.methodId) < 0) {
        permission.push('boletins');
    }

    if (user.type === 'STUDENT_DUO' || user.type === 'STUDENT_ONDEMAND') {
        permission.push('certificados');
    }

    if ((user.methodsIds.indexOf(15) >= 0 || user.methodsIds.indexOf(13) >= 0 || user.methodsIds.indexOf(16) >= 0) && user.type !== 'STUDENT_DUO') {
        permission.push('composition');
    }

    if(user.coursesIds.indexOf(1) >= 0 && user.type !== 'RESPONSIBLE_STUDENT' && ((user.methodsIds.indexOf(1) >= 0 || user.methodsIds.indexOf(5) >= 0) && user.methodId !== 15 && user.type !== 'STUDENT_DUO')) {
        permission.push('conteudo-online');
    }else if (user.coursesIds.indexOf(1) >= 0 && (user.methodsIds.indexOf(9) >= 0 || user.methodsIds.indexOf(10) >= 0 || user.methodsIds.indexOf(2) >= 0 || user.methodsIds.indexOf(3) >= 0)) {
        permission.push('conteudo-online-kids');
        //permission.push('conteudo-online');
    }

    if (user.methodsIds.indexOf(13) >= 0 || user.methodsIds.indexOf(16) >= 0 || user.methodsIds.indexOf(15) >= 0 || user.type === 'STUDENT_DUO') {
        permission.push('english-central');
    }

    if (user.type === 'STUDENT_ONDEMAND' || user.type === 'STUDENT_DUO') {
        permission.push('agendar-aula-presencial');
    }

    if (user.type === 'STUDENT_DUO') {
        permission.push('administrativo');
    }

    if (user.type === 'STUDENT_PRESENCIAL' && false) {
        permission.push('agendar-suporte');
    }

    if (user.type === 'STUDENT_DUO' || user.methodsIds.indexOf(15) >= 0 || user.methodsIds.indexOf(13) >= 0 || user.methodsIds.indexOf(16) >= 0 || user.methodsIds.indexOf(4) >= 0 || user.methodsIds.indexOf(17) >= 0) {
        permission.push('material-de-apoio');
    }

    if (user.methodsIds.indexOf(15) >= 0 || user.type === 'STUDENT_DUO' && false) {
        permission.push('atividades-complementares');
    }

    return permission;
}