import api from "../services/api";
import {MODIFICA_COMPOSITIONS, COMPOSITION_SAVE_PARCIAL} from "./types";

export const compositionsUsuarioFetch = (idUsuario) => dispatch =>
    api.post('/student/composition-list', {_id: idUsuario}).then(
        response => {
            return dispatch({type: MODIFICA_COMPOSITIONS, payload: response.data})
        }
    )

export const compositionsSalvarParcial = (idUsuario, idAula, texto, tipo) => dispatch =>
    api.post('/student/composition-save-partial', {_id: idUsuario, _idSchedule: idAula, _text: texto, _type: tipo}).then(
        response => {
            return dispatch({type: COMPOSITION_SAVE_PARCIAL, payload: response.data})
        }
    )

export const compositionsEnviarProfessor = (idUsuario, idAula, texto, tipo) => dispatch =>
    api.post('/student/composition-send-teacher', {_id: idUsuario, _idSchedule: idAula, _text: texto, _type: tipo}).then(
        response => {
            return dispatch({type: COMPOSITION_SAVE_PARCIAL, payload: response.data})
        }
    )