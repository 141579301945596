import {
    MODIFICA_LIVROS_MATERIAL_APOIO,
    MUSICS_CD
} from '../actions/types';

const INITIAL_STATE = {
    livros: [],
    musics: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_LIVROS_MATERIAL_APOIO:
            return  {...state, livros: action.payload}
        case MUSICS_CD:
            return  {...state, musics: action.payload}
        default:
            return state;
    }

}