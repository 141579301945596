import React from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDoorOpen, faDoorClosed} from '@fortawesome/free-solid-svg-icons'


export default class AvaliacaoOral extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {item} = this.props
        const itens = (item.itens)?item.itens:item.values
        const media = (item.total)?item.total:item.media
        return (
            <tr key={item.name}>
                <th>{item.name}</th>
                <th className={(parseFloat(media) >= 20 * 0.7) ? "text-info" : "text-warning"}>{parseFloat(media).toFixed(2).replace('.', ',')}</th>
                <th>
                    <span className="float-right text-black-50" onClick={() => this.toggle()}>
                        <FontAwesomeIcon icon={(this.state.dropdownOpen) ? faDoorOpen : faDoorClosed}/>
                    </span>
                </th>

                <Modal centered isOpen={this.state.dropdownOpen} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>
                        <p className="h4 strong">{item.name}&nbsp;&nbsp;&nbsp;
                            <strong
                                className={(parseFloat(media) >= 20 * 0.7) ? "text-info strong  float-right" : "text-warning strong  float-right"}>
                                {parseFloat(media).toFixed(2).replace('.', ',')}
                            </strong>
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        {
                            itens.map(avaliacao => (
                                <div key={avaliacao.id} className="row">
                                    <div className="col-8 h5">
                                        <strong>
                                            {avaliacao.name}
                                        </strong>
                                    </div>
                                    <div className="col-4 h5">
                                        <span className={(item.total >= 20 * 0.7) ? "text-info" : "text-warning"}>
                                        {avaliacao.value}
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </ModalBody>
                </Modal>
            </tr>
        )
    }
}