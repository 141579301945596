import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCloud, faSun, faMoon, faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import CheckboxDia from './CheckboxDia'
import CheckboxHorario from './CheckboxHorario'
import {
    horariosDiasCheck,
    modificarProcessamento,
    diasDisponiveisAgendamento
} from '../../../actions/AgendarAulaPresencialAction'

class FormDiasEHorarios extends Component {

    state = {
        loadingCompleted: false,
        dias: [],
        manhaCheck: false,
        tardeCheck: false,
        noiteCheck: false,
        error: '',
        isDay: false
    }

    componentDidMount() {
        this.props.diasDisponiveisAgendamento(this.props.user.id).then(
            response => {
                this.setState({dias: response})
                this.setState({loadingCompleted: true})
            }
        ).catch(
            error => {
                if (error.response && error.response.data)
                    this.setState({error: error.response.data});
                else
                    this.setState({error: 'Houve um erro no carregamento das aulas, tente novamente mais tarde!'});
            }
        )
    }

    enviarDados = (dias) => {
        /*const horarios = []
        if (this.state.manhaCheck === true)
            horarios.push('manha')
        if (this.state.tardeCheck === true)
            horarios.push('tarde')
        if (this.state.noiteCheck === true)
            horarios.push('noite')*/
        const horarios = this.props.horarios;

        if (dias.length > 0 && horarios.length > 0) {
            this.props.modificarProcessamento(true)
            this.setState({loadingCompleted: false});
            this.props.horariosDiasCheck(this.props.id, dias, horarios)
                .then(() => {
                    if ((this.props.diasHoraiosDisponiveis.length === 0)) {
                        this.setState({error: 'Não a horários disponíveis para fazer agendamento nesse horário!'});
                        this.props.modificarProcessamento(false);
                        this.setState({loadingCompleted: true});
                    } else {
                        this.props.modificarProcessamento(false);
                        this.setState({loadingCompleted: true});
                        this.props.history.push('/agendar-aula-presencial-horarios');
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data)
                        this.setState({error: error.response.data});
                    else
                        this.setState({error: 'Não a horários disponíveis para fazer agendamento nesse horário!'});
                })
        } else {
            alert('Selecione os dia(s) e horário(s) da aula')
        }
    };

    _checkUpdate = async (key) => {
        await this.setState({manhaCheck: this.props.horarios.indexOf('manha') >= 0});
        await this.setState({tardeCheck: this.props.horarios.indexOf('tarde') >= 0})
        await this.setState({noiteCheck: this.props.horarios.indexOf('noite') >= 0})
    };

    _renderButton = () => {
        if (this.props.processamento) {
            return (
                <Spinner/>
            )
        }
        return (
            <button type="button" className="btn btn-success mt-3"
                    onClick={this.enviarDados.bind(this, this.props.dias)}>
                Continuar
            </button>
        )
    };

    render() {
        const {horariosDisponiveis} = this.props;
        return (
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 form-dia-horario">
                {(this.state.loadingCompleted && !this.state.error) ?
                    <div className="container">
                        {
                            (this.state.dias && this.state.dias.recess) ? (
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                        &nbsp;Créditos de agendamento: {this.state.dias.recess}
                                    </div>
                                </div>
                            ) : null
                        }
                        {
                            (this.state.dias.data.length > 0) ?
                                <div>
                                    <p className="jumbotron-heading mb-3 text-center font-weight-bold">
                                        Em que data você pretende agendar a aula presencial?
                                    </p>
                                    <div className="row">
                                        {
                                            this.state.dias.data.map((data, i) => (
                                                <CheckboxDia key={i} index={i} valor={data} diaString={data.dia}
                                                             title={data.title}/>
                                            ))
                                        }
                                    </div>

                                    {
                                        horariosDisponiveis.length > 0 ?
                                            <div className="horarios">
                                                <p className="text-center">Agora selecione o período.</p>

                                                <div className="row">
                                                    {
                                                        (horariosDisponiveis.indexOf('manha') >= 0) ?
                                                            <CheckboxHorario check={this.props.horarios.indexOf('manha') >= 0}
                                                                             checkUpdate={this._checkUpdate}
                                                                             key={'manha'} title='manha' icon={faCloud}
                                                                             id='manha-checkbox'/> : null
                                                    }
                                                    {
                                                        (horariosDisponiveis.indexOf('tarde') >= 0) ?
                                                            <CheckboxHorario check={this.props.horarios.indexOf('tarde') >= 0}
                                                                             checkUpdate={this._checkUpdate}
                                                                             key={'tarde'} title='tarde' icon={faSun}
                                                                             id='tarde-checkbox'/> : null
                                                    }
                                                    {
                                                        (horariosDisponiveis.indexOf('noite') >= 0) ?
                                                            <CheckboxHorario check={this.props.horarios.indexOf('noite') >= 0}
                                                                             checkUpdate={this._checkUpdate}
                                                                             key={'noite'} title='noite' icon={faMoon}
                                                                             id='noite-checkbox'/> : null
                                                    }

                                                </div>
                                            </div> : null
                                    }

                                    {this._renderButton()}
                                </div> : !this.state.error ?
                                <div className="mt-3 p-3 alert alert-warning text-center col-12">
                                    Não a horários disponíveis para fazer agendamento
                                </div> : null

                        }
                    </div> : (!this.state.error) ? <div className="text-center col-12"><Spinner/></div> : null
                }
                {
                    (this.state.error) ? <div className="alert alert-warning" dangerouslySetInnerHTML={{ __html: this.state.error}} ></div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        dias: state.AgendarAulaPresencialReducer.dias,
        horarios: state.AgendarAulaPresencialReducer.horarios,
        processamento: state.AgendarAulaPresencialReducer.processamento,
        horariosDisponiveis: state.AgendarAulaPresencialReducer.horariosDisponiveis,
        id: state.AppReducer.userData.id,
        diasHoraiosDisponiveis: state.AgendarAulaPresencialReducer.diasHoraiosDisponiveis,
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, {
    horariosDiasCheck,
    modificarProcessamento,
    diasDisponiveisAgendamento
})(FormDiasEHorarios)

