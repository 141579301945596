import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
//
import {removerCarrinho} from './../../../actions/ComprasAction'
import {modificaCarrinhoItens} from './../../../actions/AppAction'

class Produto extends Component {
    _removerCarrinho = () => {
        this.props.removerCarrinho(this.props.produto)
        this.props.modificaCarrinhoItens(this.props.carrinho, this.props.user)
        window.location.reload()
    }

    render() {
        return (
            <tr>
                <td>{this.props.produto.enrollment}</td>
                <td translate="no">{this.props.produto.courseName} {this.props.produto.methodName} {this.props.produto.moduleName}-<strong>{this.props.produto.name}</strong></td>
                <td>{this.props.carrinho.length == 1 ? this.props.produto.promotionalPrice : this.props.produto.price}</td>
                <th>
                    <button className="btn btn-danger" onClick={()=> this._removerCarrinho()}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </th>
            </tr>
        )
    }
}

const mapStateToProps = state => (
    {
        carrinho: state.ComprasReducer.carrinho,
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, {removerCarrinho, modificaCarrinhoItens})(Produto)