export const RESEARCH = '@research';

export const isResearchPeding = () => {
    return (localStorage.getItem(RESEARCH) !== null && JSON.parse(localStorage.getItem(RESEARCH)).research && JSON.parse(localStorage.getItem(RESEARCH)).research.id)
};

export const setResearch = (payload) => {
    localStorage.setItem(RESEARCH, payload)
};

export const getResearch = async () => {
    return await localStorage.getItem(RESEARCH)
};

export const removeResearch = async () => {
    return await localStorage.removeItem(RESEARCH);
};

