import api from "../services/api"
import {MODIFICA_COMPRA_BOLETO, PAGAMENTO_CARTAO_SUCESSO} from "./types"


export const gerarBoleto = (usuario, carrinho, totalCarrinho) => dispatch =>
    api.post('/student/payment-boleto', {_id: usuario.id, _cart: carrinho, _value: totalCarrinho}).then(
        boleto => {
            dispatch({type: MODIFICA_COMPRA_BOLETO, payload: boleto.data})
        }
    )

export const clearBoleto = () => dispatch =>
    dispatch({type: MODIFICA_COMPRA_BOLETO, payload: []})

export const pagamentoCartao = (usuario, carrinho, totalCarrinho, formData) => dispatch =>
    api.post('/student/payment-cart', {
        _id: usuario.id,
        _cart: carrinho,
        _totalCart: totalCarrinho,
        _formData: formData
    }).then(
        response => {
            return (dispatch({type: PAGAMENTO_CARTAO_SUCESSO, payload: response}))
        }
    )
