import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faDownload} from '@fortawesome/free-solid-svg-icons'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import {materialApoioAudio} from './../../actions/MaterialApoioAction'
import DropDownCds from './../DropDownCds'
import {connect} from "react-redux";


class DropDownDownloadAudios extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
            loading: true
        }
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    componentWillMount() {
        this.props.materialApoioAudio()
            .then(
                () => this.setState({loading: false})
            ).catch(
                () => this.setState({loading: false})
            )
    }

    render() {
        return (
            <div className="dropdown-answer-keys mb-2">
                <div className="row">
                    <div className="container">
                        {
                            (this.state.loading) ?
                                <div className="card">
                                    <div className="text-center p-3"><Spinner/></div>
                                </div> : (this.props.livros.length > 0) ?
                                <div className="card">

                                    <div onClick={this.toggle}
                                         id="button-boletim-dropdown"
                                         className="btn card-header border-left-orange p-1">
                                        <div className="row">
                                            <div className="col-10 text-left">
                                                <strong className="text-nowrap">
                                                    Download áudios
                                                </strong>
                                            </div>
                                            <div className="col-2">
                                                <FontAwesomeIcon
                                                    icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                                    size="1x"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                        <div className="col-12 mt-1 text-left m-1">
                                            <div className="col-12 text-nowrap div-dropdown">
                                                {
                                                    this.props.livros.map((livro, key) => (
                                                        <DropDownCds key={key} livro={livro} user={this.props.user}/>
                                                    ))
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        livros: state.MaterialApioReducer.livros,
        user: state.AppReducer.userData,
    }
);

export default connect(mapStateToProps, {
    materialApoioAudio
})(DropDownDownloadAudios)


