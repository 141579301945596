import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
//
import {getDiaString} from './../../../services/dataFormat';
import DropdownCompnent from './DropdownComponent';

class CabecalhoAgendarAulaPresencialHorarios extends Component {

    state = {
        diaSelecionarAgendamento: 0,
        dropdownOpen: false
    }

    componentWillMount() {
        const {diasHoraiosDisponiveis} = this.props
        let value = 0
        if (diasHoraiosDisponiveis.length > 0)
            value = (parseInt(diasHoraiosDisponiveis.length / 2))

        this.setState({diaSelecionarAgendamento: value})
    }

    _renderTitle = () => {
        const {diasHoraiosDisponiveis} = this.props
        if (diasHoraiosDisponiveis.length > 0 && this.state.diaSelecionarAgendamento < diasHoraiosDisponiveis.length) {
            const date = new Date(diasHoraiosDisponiveis[this.state.diaSelecionarAgendamento].date.date_English)
            return (
                <p>
                    Agendando para o
                    dia <span>{diasHoraiosDisponiveis[this.state.diaSelecionarAgendamento].date.date_Brazilian}({getDiaString(date.getUTCDay())})</span>
                </p>
            )
        }
    }

    _renderBtnPaginator = (type) => {
        const {diasHoraiosDisponiveis} = this.props

        let btn = faAngleLeft
        let disable = false
        let value = this.state.diaSelecionarAgendamento
        if (type === 'RIGHT') {
            if (this.state.diaSelecionarAgendamento >= (diasHoraiosDisponiveis.length - 1))
                disable = true
            btn = faAngleRight
            value++
        } else {
            if (this.state.diaSelecionarAgendamento <= 0)
                disable = true
            value--
        }

        return (
            <button
                className="btn btn-lg btn-secondary"
                onClick={() => this.setState({diaSelecionarAgendamento: value})}
                disabled={disable}
            >
                <FontAwesomeIcon icon={btn}/>
            </button>
        )
    }

    _getHorariosDia() {
        return _.map(this.props.diasHoraiosDisponiveis[this.state.diaSelecionarAgendamento].itens)
    }

    render() {
        return (
            <div className="cacecalho-agendar-aula-presencial-horarios">

                <div className="row data-confirmacao box-cinza py-2">
                    <div className="col-1">
                        {this._renderBtnPaginator('LEFT')}
                    </div>
                    <div className="col-10">
                        {this._renderTitle()}
                    </div>
                    <div className="col-1">
                        {this._renderBtnPaginator('RIGHT')}
                    </div>
                </div>
                <hr/>
                <p className="text-center mt-3">Agora, selecione o horário entre os diponíveis na lista abaixo!</p>
                {
                    /*<div className="row box-cinza text-center legenda py-2 mb-3">
                    <div className="col-6">
                        <img src={cadeiraCinza} width="15" alt="Cadeira oculpada"/> Cadeira oculpada
                    </div>
                    <div className="col-6">
                        <img src={cadeiraAzul} width="15" alt="Cadeira disponível"/> Cadeira disponível
                    </div>
                </div>*/
                }

                <div className="row">
                    {
                        (this.props.diasHoraiosDisponiveis.length > 0) ? this._getHorariosDia().map((data, i) => {
                            return <DropdownCompnent history={this.props.history} key={i} horarios={data} index={(data[0])?data[0].timeDay:i}/>
                        }) : null
                    }
                </div>
            </div>
        )
    }

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

}

const mapStateToProps = state => (
    {
        diasHoraiosDisponiveis: state.AgendarAulaPresencialReducer.diasHoraiosDisponiveis,

    }
)

export default connect(mapStateToProps, {})(CabecalhoAgendarAulaPresencialHorarios)