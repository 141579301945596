import React from 'react';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {URL} from "../../services/api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'

class ModalPublicidade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const {imagemPublicitaria} = this.props;
        return (
            <div className="text-left">
                <Button color="danger" className='d-none' onClick={this.toggle}>{this.props.buttonLabel}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>
                        <h2 className="strong h2 text-left mb-1">{imagemPublicitaria.title}</h2>
                        <p>{imagemPublicitaria.legend}</p>
                    </ModalHeader>
                    <ModalBody>
                        {
                            imagemPublicitaria.image && (
                                imagemPublicitaria.link ? (
                                    <a href={imagemPublicitaria.link} target="_blank">
                                        <img className="img img-fluid" alt={imagemPublicitaria.title}
                                             src={`${URL}/${imagemPublicitaria.image}`}
                                        />
                                    </a>
                                ) : (
                                    <img className="img img-fluid" alt={imagemPublicitaria.title}
                                         src={`${URL}/${imagemPublicitaria.image}`}
                                    />
                                )
                            )
                        }
                        <br/>
                        {(imagemPublicitaria.link && !imagemPublicitaria.image) && (
                            <a className="btn btn-success" href={imagemPublicitaria.link} target="_blank">
                                <FontAwesomeIcon icon={faChevronCircleRight}/> Ir para {imagemPublicitaria.link}
                            </a>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ModalPublicidade;