import api from "../services/api";
import {MODIFICA_LIVROS_A_COMPRAR, MODIFICA_CARRINHO_ADICIONAR, MODIFICA_CARRINHO_REMOVER} from "./types"
import {getUserSession} from '../services/auth'

export const livrosAComprarUsuarioFetch = () => async dispatch =>
    await getUserSession().then(
        async user => {
           await api.post('/student/buy-material', {_id: user.id}).then(
                livros => {
                    return dispatch({type: MODIFICA_LIVROS_A_COMPRAR, payload: livros.data})
                }
            )
        }
    )

export const adicionarCarrinho = produto => dispatch => dispatch({type: MODIFICA_CARRINHO_ADICIONAR, payload: produto})
export const removerCarrinho = produto => dispatch => dispatch({type: MODIFICA_CARRINHO_REMOVER, payload: produto})