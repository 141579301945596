import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {conteudoOnlineLivroFetch} from "../../actions/ConteudoOnlineAction"
import {confirmAlert} from "react-confirm-alert";


class ConteudoOnlineAtividade extends Component {

    state = {
        loadingCompleted: false,
        aula: []
    }

    componentWillMount() {
        const id = parseInt(this.props.match.params.id)
        const idAula = parseInt(this.props.match.params.idAtividade)

        if (this.props.usuario.coursesIds && this.props.usuario.coursesIds.indexOf(2) < 0) {
            confirmAlert({
                title: `Olá ${this.props.usuario.name.split(' ')[0]}`,
                message: <h6 className="text-justify">Seu portal Rockfeller mudou e está cheio de novidades! <br/>Você será redirecionada para o
                    novo portal, onde terá acesso a todo o novo conteúdo.<br/> Vamos conhecer?</h6>,
                buttons: [
                    {
                        label: 'Acessar o novo portal',
                        onClick: () =>
                            window.location.href = 'https://play.rockfeller.com.br/'
                    },
                ]
            });
        }

        this.props.conteudoOnlineLivroFetch(this.props.usuario.id, id).then(
            () => {
                this.props.livro.aulas.map(aula => {
                    if (parseInt(aula.id) === parseInt(idAula))
                        this.setState({aula})
                })
                this.setState({loadingCompleted: true})
            }
        ).catch(
            error => {
                if (error.response && error.response.data )
                    alert(error.response.data)
                else
                    alert('Falha ao tentar acessar as aulas do livro!')
            }
        )
    }

    render() {
        return (
            <div className="conteudo-online-atividade">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        {
                            (this.state.loadingCompleted) ?
                                <div className="card">
                                    <div className="text-center">
                                        <h4 className="text-info h4">Conteúdo Online: {(this.props.livro.livro.kt) ? 'Kids' : this.state.aula.nome}</h4>
                                        <br/>
                                        <div className="row">
                                            <div className="container">
                                                {
                                                    (parseInt(this.props.livro.livro.kt) === 1) ?
                                                        <object type="text/html"
                                                                data={`https://joy.rockfellerbrasil.com.br/aula/ktclass.php?id=${this.props.match.params.id}-${this.state.aula.idAula}&a=${this.props.match.params.na}`}
                                                                width="960" height="645"
                                                                className="ml-conteudo"
                                                        >
                                                               <embed type="text/html"
                                                                   src={`https://joy.rockfellerbrasil.com.br/aula/ktclass.php?id=${this.props.match.params.id}-${this.state.aula.idAula}&a=${this.props.match.params.na}`}
                                                                   width="960" height="645"/>
                                                        </object> :
                                                        <object type="text/html"
                                                                data={`https://joy.rockfellerbrasil.com.br/aula/aclass.php?id=${this.state.aula.id}`}
                                                                width="960" height="645"  className="ml-conteudo">
                                                            <embed type="text/html"
                                                                   src={`https://joy.rockfellerbrasil.com.br/aula/aclass.php?id=${this.state.aula.id}`}
                                                                   width="960" height="645"/>
                                                        </object>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> : <div className="w-100 text-center"><Spinner/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        livro: state.ConteudoOnlineReducer.livro,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {conteudoOnlineLivroFetch})(ConteudoOnlineAtividade)