import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faDollarSign,
    faShoppingBasket,
    faBook,
    faGlobe,
    faChartLine,
    faEdit,
    faUserClock,
    faRunning,
    faHome,
    faPlus,
    faClock,
    faLightbulb,
    faLifeRing,
    faGraduationCap,
    faBookReader,
    faPlay,
    faUsersCog,
    faCaretDown,
    faCaretUp,
    faUserAlt,
} from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux"
//
import {logoutUsuario, modificarSenha, modificarUsuario} from "./../../actions/LoginAction"
import {modificaUserData} from "./../../actions/AppAction"
import './styles.css'
import {getPermission} from '../../services/checkPermission';


class Menu extends Component {

    logoutUsuario = () => {
        this.props.modificarUsuario('')
        this.props.modificarSenha('')
        this.props.logoutUsuario().then(
            () => this.props.history.push('/login')
        )
    }

    state = {
        openAgendarAula: false,
        openAdministrativo: false,
        permissions: [],
    };

    async componentDidMount() {
        const permissions = await getPermission(this.props.user);
        this.setState({permissions});
    }

    render() {
        return (

            <div
                translate="no"
                className="col-4 col-lg-3 col-xl-2 menu-fixo laranja rounded-right d-none d-md-block collapse show text-left"
                id="menu">
                <ul className="navbar-nav mr-auto">
                    <li className="navbar-itm">
                        <Link className="nav-link" to="/"><i><FontAwesomeIcon
                            icon={faHome}/></i> Home</Link>
                    </li>
                    <li className="navbar-itm">
                        <Link className="nav-link" to="/financeiro"><i><FontAwesomeIcon
                            icon={faDollarSign}/></i> Financeiro</Link>
                    </li>

                    <li className="navbar-itm">
                        <Link className="nav-link" to="/perfil"><i><FontAwesomeIcon
                            icon={faUserAlt}/></i> Perfil</Link>
                    </li>
                    {
                        (this.state.permissions.indexOf('minhas-compras') >= 0) &&
                        <li className="navbar-itm">
                            <Link className="nav-link" to="/minhas-compras">
                                <i><FontAwesomeIcon icon={faShoppingBasket}/></i> Minhas compras
                            </Link>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('comprar-material-pedagogico') >= 0) &&
                        <li className="navbar-itm">
                            <Link className="nav-link" to="/comprar-material-pedagogico">
                                <i><FontAwesomeIcon icon={faBook}/></i> Comprar Material Pedagógico
                            </Link>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('simulado-exames-internacionais') >= 0) &&
                        <li className="navbar-itm">
                            <a className="nav-link" target="_blank"
                               href={`https://rockfeller.edusynch.com/sign-up?school=${this.props.user.franchiseCNPJ}`}><i><FontAwesomeIcon
                                icon={faGlobe}/></i> Simulado de exames internacionais
                            </a>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('rockfeller-play') >= 0) &&
                        <li className="navbar-itm">
                            <a className="nav-link" target="_blank"
                               href={`https://play.rockfeller.com.br/`}><
                                i><FontAwesomeIcon icon={faPlay}/></i> Rockfeller Play
                            </a>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('boletins') >= 0) &&
                        <li className="navbar-itm">
                            <Link className="nav-link" to="/boletins">
                                <i><FontAwesomeIcon icon={faChartLine}/></i> Boletins
                            </Link>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('certificados') >= 0) && (
                            <li className="navbar-itm"><Link className="nav-link" to="/certificados">
                                <i><FontAwesomeIcon icon={faGraduationCap}/></i> Certificados</Link>
                            </li>
                        )
                    }
                    {
                        (
                            (this.state.permissions.indexOf('composition') >= 0)
                        ) ?
                            <li className="navbar-itm"><Link className="nav-link" to="/composition"><i><FontAwesomeIcon
                                icon={faEdit}/></i> Bring It Out</Link>
                            </li> : null
                    }

                    {
                        (this.state.permissions.indexOf('conteudo-online-kids') >= 0) ?
                            <li className="navbar-itm"><Link className="nav-link"
                                                             to="/conteudo-online-kids"><i><FontAwesomeIcon
                                icon={faBookReader}/></i> Conteúdo Online</Link>
                            </li>
                            : (this.state.permissions.indexOf('conteudo-online') >= 0) &&
                            <li className="navbar-itm"><Link className="nav-link"
                                                             to="/conteudo-online"><i><FontAwesomeIcon
                                icon={faBookReader}/></i> Conteúdo Online</Link>
                            </li>
                    }

                    {
                        (this.state.permissions.indexOf('english-central') >= 0) &&
                        <li className="navbar-itm">
                            <a className="nav-link" target="_blank"
                               href={`https://pt.englishcentral.com/partner/rockfeller`}
                            >
                                <FontAwesomeIcon icon={faLightbulb}/> Plus
                            </a>
                        </li>
                    }

                    {
                        (this.state.permissions.indexOf('agendar-aula-presencial') >= 0) &&
                        <li className="navbar-itm">
                            {
                                (this.props.user.type === 'STUDENT_DUO') ? (
                                    <>
                                        <a className="nav-link">
                                            <button type="button"
                                                    className="bg-transparent border-0 text-white no-wrapper"
                                                    onClick={() => this.setState({openAgendarAula: !this.state.openAgendarAula})}>
                                                <i><FontAwesomeIcon icon={faUserClock}/></i>
                                                <span className="font-normal">Aulas presenciais</span>
                                                {
                                                    this.state.openAgendarAula ? (
                                                        <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretUp}/></i>
                                                            </span>
                                                    ) : (
                                                        <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretDown}/></i>
                                                            </span>
                                                    )
                                                }
                                            </button>
                                            {
                                                this.state.openAgendarAula && (
                                                    <ul className="ml-2">
                                                        <Link className="nav-link"
                                                              to="/agendar-aula-presencial">
                                                            <span>Agendar</span>
                                                        </Link>
                                                        <Link className="nav-link" to="/interaction">
                                                            <span>Aulas concluídas</span>
                                                        </Link>
                                                    </ul>
                                                )
                                            }
                                        </a>

                                    </>
                                ) : (
                                    <Link className="nav-link" to="/agendar-aula-presencial">
                                        <span><i><FontAwesomeIcon
                                            icon={faUserClock}/></i> Agendar aula no Rockspot</span>
                                    </Link>
                                )
                            }
                        </li>
                    }

                    {
                        this.state.permissions.indexOf('administrativo') >= 0 && (
                            <li className="navbar-itm">
                                <a className="nav-link">
                                    <button type="button" className="bg-transparent border-0 text-white"
                                            onClick={() => this.setState({openAdministrativo: !this.state.openAdministrativo})}>
                                        <i><FontAwesomeIcon icon={faUsersCog}/></i>
                                        <span className="font-normal">Administrativo</span>
                                        {
                                            this.state.openAdministrativo ? (
                                                <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretUp}/></i>
                                                            </span>
                                            ) : (
                                                <span className="pull-right">
                                                                &nbsp;<i><FontAwesomeIcon icon={faCaretDown}/></i>
                                                            </span>
                                            )
                                        }
                                    </button>
                                    {
                                        this.state.openAdministrativo && (
                                            <ul className="ml-2">
                                                <Link className="nav-link"
                                                      to="/recisao">
                                                    <span>Solicitar Rescisão</span>
                                                </Link>
                                            </ul>
                                        )
                                    }
                                </a>
                            </li>
                        )
                    }

                    {
                        (this.state.permissions.indexOf('agendar-suporte') >= 0) &&
                        <li className="navbar-itm"><Link className="nav-link"
                                                         to="/agendar-suporte"><i><FontAwesomeIcon
                            icon={faClock}/></i> Agendar
                            Suporte</Link>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('material-de-apoio') >= 0) &&
                        <li className="navbar-itm">
                            <Link className="nav-link" to="/material-de-apoio">
                                {
                                    (this.props.user.type === 'STUDENT_DUO') ?
                                        <span><i><FontAwesomeIcon icon={faLifeRing}/></i>Vídeo tutorial</span> :
                                        <span><i><FontAwesomeIcon icon={faLifeRing}/></i>Materiais de apoio</span>
                                }
                            </Link>
                        </li>
                    }
                    {
                        (this.state.permissions.indexOf('atividades-complementares') >= 0) &&
                        <li className="navbar-itm">
                            <Link className="nav-link" to='/atividades-complementares'><i><FontAwesomeIcon
                                icon={faPlus}/></i> Atividades Complementares
                            </Link>
                        </li>
                    }
                    <li className="navbar-itm">
                        <a className="text-light nav-link" onClick={() => this.logoutUsuario()} href="/">
                            <i><FontAwesomeIcon icon={faRunning}/></i> Sair
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData
    }
)

export default connect(mapStateToProps, {logoutUsuario, modificarUsuario, modificarSenha, modificaUserData})(Menu)
