import React, {Component} from 'react'
import {connect} from "react-redux"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import ConteudoOnlineItem from "./../../components/ConteudoOnlineItem"
import './styles.css'
import {conteudoOnlineUsuarioFetch} from './../../actions/ConteudoOnlineAction'
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"


class ConteudoOnline extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        this.props.conteudoOnlineUsuarioFetch(this.props.usuario.id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="atividade-complementar">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card">
                            <div className="card-header text-left">
                                <h4 className="h4 text-center">Conteúdo Disponível</h4>
                                <small className="small">
                                    O conteúdo a ser exibido é disponíbilizado após a compra do material pedagógico.
                                </small>
                            </div>
                            <div className="card-body">
                                <div className="row conteudo-atividade-complementar">
                                    {
                                        (this.state.loadingCompleted) ?
                                            (this.props.conteudoOnline.length)?
                                            this.props.conteudoOnline.map(item => (
                                                <ConteudoOnlineItem key={item.id} atividade={item}
                                                                           history={this.props.history}/>
                                            ))
                                            :
                                                <div className="alert alert-warning col-12" role="alert">
                                                    Nenhum registro de compra foi encontrado para <strong translate="no">{this.props.usuario.name}</strong>. <br/>
                                                    Para ter acesso ao conteúdo oline, é necessário realizar a compra do
                                                    material.
                                                </div>
                                            : <div className="w-100 text-center"><Spinner/></div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        conteudoOnline: state.ConteudoOnlineReducer.conteudoOnline,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {conteudoOnlineUsuarioFetch})(ConteudoOnline)



