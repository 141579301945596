import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    faCircle
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {confirmAlert} from 'react-confirm-alert'
//
import {cancelarAgendamento} from './../../actions/AgendarAulaPresencialAction'


class Agendamento extends Component {

    cancelarAgendamento = (id) => {
        confirmAlert({
            title: 'Deseja cancelar o seu agendamento?',
            message: `${this.props.agendamento.dateBrazilian}: ${this.props.agendamento.startTime}H ás ${this.props.agendamento.endTime}H`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () =>
                        this.props.cancelarAgendamento(id)
                            .catch(error => {
                                if (error.response && error.response.data)
                                    alert(error.response.data)
                                else
                                    alert('Falha ao tentar cancelar o agendamento!')
                            })
                },
                {
                    label: 'Não',
                    onClick: () => {
                    }
                }
            ]
        })
    }

    render() {
        return (
            <div className="row" key={this.props.agendamento.id}>
                <div className="col-1">
                    <FontAwesomeIcon icon={faCircle}
                                     color={(this.props.agendamento.cancelByStudent) ? 'red' : 'green'}/>
                </div>
                <div className="col-10">
                    <p>
                        <span
                            className="text-decoration-clear">{this.props.agendamento.dateBrazilian} - {this.props.agendamento.startTime} às {this.props.agendamento.endTime}</span>
                        <br/>
                        <span
                            className={(this.props.agendamento.cancelByStudent) ? 'text-decoration-del' : ''}>Professor(a): {this.props.agendamento.teacher}</span>
                    </p>
                </div>
                <div className="col-1">
                    {
                        (!this.props.agendamento.cancelByStudent) ?
                            <button onClick={this.cancelarAgendamento.bind(this, this.props.agendamento.id)}
                                    className="btn btn-sm btn-danger"
                                    title="Cancelar agendamento">
                                Cancelar
                            </button>
                            : null
                    }
                </div>
            </div>
        )
    }
}

export default connect(null, {cancelarAgendamento})(Agendamento)
