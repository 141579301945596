import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {comprasUsuarioFetch} from './../../actions/BoletinsAction'
import DropdownBoletim from './components/DropdownBoletim'


class Boletins extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        this.props.comprasUsuarioFetch(this.props.usuario.id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            () => this.setState({loadingCompleted: true})
        )
    }

    render() {
        return (
            <div className="boletins">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="h4">Boletins</h4>
                            </div>
                            <div className="card-body">
                                {
                                    (this.state.loadingCompleted) ?
                                        (this.props.boletins.length > 0) ? this.props.boletins.map((item) => (
                                                <DropdownBoletim key={item.id} boletim={item}/>
                                            ))
                                            : <div className="text-center alert-info pt-5 pb-5 pl-2 pr-2">
                                                <span className="h6">Você ainda não possui um boletim!</span>
                                            </div>
                                        : <div className="text-center"><Spinner/></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        boletins: state.BoletinsReducer.boletins,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {comprasUsuarioFetch})(Boletins)



