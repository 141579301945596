import React, {Component} from 'react'
import {connect} from "react-redux"
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom"
//
import Header from "./../../components/Header"
import Menu from "./../../components/Menu"
import './styles.css'
import {conteudoOnlineLivroFetch} from './../../actions/ConteudoOnlineAction'
import {URL} from "../../services/api"


class ConteudoOnlineLivro extends Component {

    state = {
        loadingCompleted: false
    }

    componentWillMount() {
        const id = parseInt(this.props.match.params.id)
        this.props.conteudoOnlineLivroFetch(this.props.usuario.id, id).then(
            () => this.setState({loadingCompleted: true})
        ).catch(
            error => {
                if (error.response && error.response.data)
                    alert(error.response.data)
                else
                    alert('Falha ao tentar acessar as aulas do livro!')
                //this.props.history.push('/conteudo-online')
            }
        )
    }

    render() {
        const {livro} = this.props
        return (
            <div className="conteudo-online-livro">
                <Header/>
                <div className="row">
                    <Menu/>
                    <div className="col-12 col-md-8 col-lg-9 col-xl-10">
                        {
                            (this.state.loadingCompleted) ?
                                <div className="card text-left">
                                    <div className="card-header">
                                        <div className="col-12">
                                            <img
                                                className="img-thumbnail"
                                                src={`${livro.livro.miniaturaSrc}`}
                                                width="70"
                                                height="70"
                                                alt={`Livro-${livro.livro.nome}`}
                                            />
                                            <span className="h5 text-info"> {livro.livro.nome}</span>
                                        </div>
                                        {
                                            (livro.livro.cdId && livro.livro.download) ?
                                                <div>
                                                    <hr/>
                                                    <a className="btn col-12 alert alert-info p-2 mt-3 text-left h-100"
                                                       href={`${URL}/student-payment/${this.props.usuario.id}/token/${this.props.usuario.token}/cd_download/${livro.livro.cdId}`}
                                                    >
                                                        <FontAwesomeIcon icon={faDownload}/> Este livro possui um CD
                                                        anexado, para obtê-lo clique aqui
                                                    </a>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {
                                                livro.aulas.map(aula => (
                                                    <div className="col-6 col-md-4" key={aula.id}>
                                                        <Link
                                                            to={`/${livro.livro.id}/aula/${aula.id}/na/${aula.na}/conteudo-online-atividade`}
                                                            className="card m-1 p-2 btn">
                                                            <div className="row">
                                                                <div className="col-2">
                                                                    <span
                                                                        className="btn btn-info rounded-circle">
                                                                        {aula.ordem}
                                                                    </span>
                                                                </div>
                                                                <div className="text-dark col-10">
                                                                    <h5 className="h5">{aula.nome}</h5>
                                                                    <small>{(aula.texto) ? aula.texto : 'Sem descrição'}</small>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div> : <div className="w-100 text-center"><Spinner/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        livro: state.ConteudoOnlineReducer.livro,
        usuario: state.AppReducer.userData

    }
)

export default connect(mapStateToProps, {conteudoOnlineLivroFetch})(ConteudoOnlineLivro)



