import {
    MODIFICA_LIVROS_A_COMPRAR,
    MODIFICA_COMPRAS,
    MODIFICA_CARRINHO_REMOVER,
    MODIFICA_CARRINHO_ADICIONAR,
    MODIFICA_CARRINHO,
    ADD_CARRINHO_SUCESSO
} from '../actions/types';

const INITIAL_STATE = {
    livrosAComprar: [],
    compras: [],
    carrinho: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_LIVROS_A_COMPRAR:
            return {...state, livrosAComprar: action.payload}
        case MODIFICA_COMPRAS:
            return {...state, compras: action.payload}
        case MODIFICA_CARRINHO_ADICIONAR:
            return {...state, carrinho: getCarrinho(state.carrinho, action.payload)}
        case MODIFICA_CARRINHO_REMOVER:
            return {...state, carrinho: removeCarrinho(state.carrinho, action.payload)}
        case MODIFICA_CARRINHO:
            return {...state, carrinho: action.payload}
        case ADD_CARRINHO_SUCESSO:
            return action.payload
        default:
            return state;
    }
}

const getCarrinho = (carrinho, payload) => {
    const index = carrinho.indexOf(payload)
    if (index < 0)
        carrinho.push(payload)
    return carrinho
}

const removeCarrinho = (carrinho, payload) => {
    carrinho.map((item, index) => {
        if (item.id === payload.id)
            carrinho.splice(index, 1)
    })
    return carrinho
}