import api from "../services/api"
import {
    MODIFICA_FINANCEIRO_LIST
} from "./types"

export const financeiroUsuarioFetch = (userId) => dispatch =>
    api.post('student/financial-list', {_id: userId}).then(
        financeiro => {
            return dispatch({type: MODIFICA_FINANCEIRO_LIST, payload: financeiro.data})
        }
    )
