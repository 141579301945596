//import React from "react";

export const getDiaString = dia => {
    switch (dia) {
        case 1:
            return 'segunda-feira'
        case 2:
            return 'terça-feira'
        case 3:
            return 'quarta-feira'
        case 4:
            return 'quinta-feira'
        case 5:
            return 'sexta-feira'
        case 6:
            return 'sábado'
        default:
            return ''
    }
}

export const formatMoney = str => {
    return `R$ ${parseFloat(str).toFixed(2).replace('.', ',')}`
}