import {
    MODIFICA_MINHAS_COMPRAS,
} from "../actions/types"

const INITIAL_STATE = {
    minhasCompras: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_MINHAS_COMPRAS:
            return {...state, minhasCompras: action.payload}
        default:
            return state;
    }

}