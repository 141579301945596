import React from 'react';


export default class BoletimKids extends React.Component {
    render() {
        const {resultado} = this.props

        return (
            <div className="boletim boletim-kids mb-2">
                <table className="table table-striped">
                    <tbody>
                    <tr>
                        <th>Média das tarefas</th>
                        <th>
                            {
                                resultado.avaliations.map(item => (
                                    <span key={item}
                                          className={parseFloat(item) >= 70 ? "text-info" : "text-warning"}>{parseFloat(item).toFixed(2).replace('.', ',')}</span>
                                ))
                            }
                        </th>
                    </tr>
                    {
                        resultado.pruebas.map(item => (
                            <tr key={item.name}>
                                <th translate="no">{item.name}</th>
                                <th className={(item.value >= 70) ? "text-info" : "text-warning"}>
                                    {(item.value) ? parseFloat(item.value).toFixed(2).replace('.', ',') : '-'}
                                </th>
                            </tr>
                        ))
                    }
                    <tr className="bg-secondary">
                        <th>Média Final</th>
                        <th className={(resultado.finalGrade >= 70) ? "text-info" : "text-warning"}>
                            {(resultado.finalGrade) ? parseFloat(resultado.finalGrade).toFixed(2).replace('.', ',') : '-'}
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}