import React, {Component} from 'react'
//
import "./styles.css"
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import Pagamento from './components/Pagamento'

class Dashboard extends Component {
    render() {
        return (
            <div className="pagamento">
                <Header/>
                <div className="row">
                    <Menu/>
                    <Pagamento history={this.props.history}/>
                </div>
            </div>
        )
    }
}

export default Dashboard
