import React from 'react'
//
import {connect} from "react-redux"
import {setEscolaAction, setStepAction, loadEscolasAction} from "../../actions/RenovacaoMatriculaAction";
import Spinner from "react-activity/lib/Spinner"
import "react-activity/lib/Spinner/Spinner.css"
import Select from 'react-select';

class StepEscola extends React.Component {

    state = {
        stepEscola: false,
        escola: null,
        loading: false,
    }

    renderLoading = () => (
        <div className="row">
            <div className="col-12 center">
                <Spinner/>
            </div>
        </div>
    );

    toStepPagamento = () => {
        const {matricula} = this.props;

        this.props.setEscolaAction(matricula.franchiseId);
        this.props.setStepAction(3);
    }

    setToStepEscola = () => {
        this.props.loadEscolasAction().then(
            () => this.setState({stepEscola: true})
        )

    }

    renderForm = matricula => (
        <div className="content h-100">
            <div className="content-body h-75">
                <div className="row h-100 align-middle">
                    <div className="col-12 d-flex flex-column justify-content-center align-middle align-items-center">
                        <span className="font-text-renovacao">Você continuará estudando na escola de <strong
                            className="text-info">{matricula.franchiseUnitId}</strong>?</span><br/><br/>
                    </div>
                </div>
            </div>
            <div className="input mt-2 h-25">
                <br/>
                <button onClick={this.toStepPagamento} className="btn btn-info mr-2">Sim</button>
                <button onClick={this.setToStepEscola} className="btn btn-success">Não</button>
            </div>
        </div>
    );

    handleSelectChange = ({value}) => {
        //console.log(value)
        this.setState({escola: value});
    }

    handleEscola = () => {
        const {escola} = this.state;
        if (escola) {
            this.props.setEscolaAction(escola);
            this.props.setStepAction(3);
        } else {
            alert('Selecione uma escola!');
        }
    }

    getSelectEscolas = () => {
        const {escolas} = this.props;
        const options = [];

        escolas.map(escola => (
            options.push({
                value: escola.id,
                label: escola.unitId,
            })
        ))
        return options;
    }


    renderFormSelecionarEscola = () => {
        return (
            <div className="content h-100">
                <div
                    className="content-body h-75 d-flex flex-column justify-content-center align-middle align-items-center">
                    <div className="row">
                        <div className="col-12">
                            <span className="font-text-renovacao">Temos várias escolas disponiveis</span><br/><br/>
                        </div>
                        <div className="col-12">
                            <Select
                                id="escola"
                                name="escola"
                                placeholder="Selecione a escola que deseja estudar"
                                onChange={this.handleSelectChange}
                                options={this.getSelectEscolas()}
                            />
                        </div>
                    </div>
                </div>
                <div className="row input mt-2 h-25">
                    <div className="col-12">
                        <button onClick={this.handleEscola} className="btn btn-success">Continuar</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {matricula} = this.props;
        return (
            this.state.loading ? this.renderLoading() : (
                this.state.stepEscola ? (this.renderFormSelecionarEscola()) : (this.renderForm(matricula))
            )
        )
    }
}

const mapStateToProps = state => (
    {
        usuario: state.AppReducer.userData,
        matricula: state.RenovacaoMatriculaReducer.matriculaRenovacao,
        escolas: state.RenovacaoMatriculaReducer.escolas,

    }
)

export default connect(mapStateToProps, {setEscolaAction, setStepAction, loadEscolasAction})(StepEscola)


