import {
    MODIFICA_NOTIFICACAO,
    LISTA_SUPORTES_USUARIO,
    LISTA_AGENDAMENTOS_USUARIO,
    MODIFICA_USER_DATA,
    MODIFICA_CARRINHO_ITENS,
    MODIFICA_CARRINHO_VALOR,
    MODIFICA_COMPRA_BOLETO,
    MODIFICA_NOTIFICACAO_LIST,
    MODIFICA_CARRINHO_DESCONTO,
    MODIFICA_CARRINHO_VALOR_FIXED,
    MODIFICA_PESQUISA,
    PESAQUISA_SALVA_SUCESSO,
    MODIFICA_IMAGEM_LIST,
    SET_INTERECTIONS_LIST,
    SET_MOTIVO_CANCELAR_MATRICULA_LIST,
    SET_DEFAULT,
    SET_ENROLLMENT,
    SET_CERTIFICADOS
} from '../actions/types';

const INITIAL_STATE = {
    notificacaoTotal: 0,
    notificacaoList: [],
    suportes: [],
    agendamentos: [],
    userData: [],
    carrinhoValor: 0,
    carrinhoValorFixed: 0,
    carrinhoValorDesconto: 0,
    carrinhoItens: 0,
    compraBoleto: [],
    pesquisa: [],
    imagemPublicitaria: [],
    interections: [],
    questionario: [],
    enrollment: [],
    certificados: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_NOTIFICACAO:
            return {...state, notificacaoTotal: action.payload}
        case MODIFICA_NOTIFICACAO_LIST:
            return {...state, notificacaoList: action.payload}
        case MODIFICA_USER_DATA:
            return {...state, userData: action.payload}
        case LISTA_SUPORTES_USUARIO:
            return {...state, suportes: action.payload}
        case LISTA_AGENDAMENTOS_USUARIO:
            return {...state, agendamentos: action.payload}
        case MODIFICA_CARRINHO_ITENS:
            return {...state, carrinhoItens: action.payload}
        case MODIFICA_CARRINHO_VALOR:
            return {...state, carrinhoValor: action.payload}
        case MODIFICA_CARRINHO_VALOR_FIXED:
            return {...state, carrinhoValorFixed: action.payload}
        case MODIFICA_CARRINHO_DESCONTO:
            return {...state, carrinhoValorDesconto: action.payload}
        case MODIFICA_COMPRA_BOLETO:
            return {...state, compraBoleto: action.payload}
        case MODIFICA_PESQUISA:
            return {...state, pesquisa: action.payload}
        case PESAQUISA_SALVA_SUCESSO:
            return action.payload
        case MODIFICA_IMAGEM_LIST:
            return {...state, imagemPublicitaria: action.payload}
        case SET_INTERECTIONS_LIST:
            return {...state, interections: action.payload}
        case SET_MOTIVO_CANCELAR_MATRICULA_LIST:
            return {...state, questionario: action.payload}
        case SET_DEFAULT:
            return  action.payload;
        case SET_ENROLLMENT:
            return {...state, enrollment: action.payload}
        case SET_CERTIFICADOS:
            return {...state, certificados: action.payload}
        default:
            return state;
    }

}
