import {
    MODIFICA_SUPORTE_LIST
} from '../actions/types';

const INITIAL_STATE = {
    suportes: [],
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_SUPORTE_LIST:
            return  {...state, suportes: action.payload}
        default:
            return state;
    }

}