import {
    MODIFICA_DIAS,
    MODIFICA_HORARIOS,
    MODIFICA_DIAS_HORARIOS_DISPONIVEIS,
    MODIFICA_PROCESSAMENTO, RESETAR_DIAS_AGENDAMENTO,
    ADD_HORARIO_DISPONIVEL,
    SET_HORARIO_DISPONIVEL,
} from '../actions/types';

const INITIAL_STATE = {
    dias: [],
    horarios: [],
    diasHoraiosDisponiveis: [],
    processando: false,
    horariosDisponiveis: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICA_DIAS:
            return {...state, dias: getDias(state.dias, action.payload), horarios: [], horariosDisponiveis: []};
        case MODIFICA_HORARIOS:
            return {...state, horarios: getHorarios(state.horarios, action.payload)}
        case MODIFICA_DIAS_HORARIOS_DISPONIVEIS:
            return {...state, diasHoraiosDisponiveis: action.payload, horarios: []}
        case MODIFICA_PROCESSAMENTO:
            return {...state, processando: action.payload}
        case RESETAR_DIAS_AGENDAMENTO:
            return {...state, dias: []};
        case SET_HORARIO_DISPONIVEL:
            return {...state, horariosDisponiveis: action.payload, horarios: []};
        case ADD_HORARIO_DISPONIVEL:
            const horariosDisponiveis = getAddHorarioDisponivel(state.horariosDisponiveis, action.payload, state.dias);
            return {...state, horariosDisponiveis: horariosDisponiveis, horarios: []};
        default:
            return state;
    }

}

const getDias = (dias, payload) => {
    const index = dias.indexOf(payload)
    const data = [];
    /*if (typeof payload === 'string') {
        if (index >= 0)
            dias.splice(index, 1)
        else
            dias.push(payload)
    }*/
    if (typeof payload === 'string') {
        if (index < 0)
            data.push(payload)
    }
    return data
};

const getHorarios = (horarios, payload) => {
    const index = horarios.indexOf(payload);
    if (typeof payload === 'string') {
        if (index >= 0)
            horarios.splice(index, 1)
        else
            horarios.push(payload)
    }
    return horarios
};

const getAddHorarioDisponivel = (horarios, payload, dias = []) => {

    const data = [];
    const index = horarios.indexOf(payload);
    if (typeof payload === 'string') {
        if(index < 0) {
            horarios.push(payload);
            data.push(payload)
        }
    }
    horarios.map(i => data.push(i));

    if(dias.length > 0) return  data;

    return [];
};
