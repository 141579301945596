import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faAngleDown,
    faAngleUp,
    faCalendar,
    faFilePdf,
    faFileArchive,
    faCreditCard
} from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux"
import b64 from 'base-64'
//
import {URL} from './../../../services/api'
import {modificaCarrinhoCompra} from './../../../actions/AppAction'

class Compra extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {compra, user} = this.props;
        const compraB64 = b64.encode(compra.id+'-myrock-encode');
        const userB64 = b64.encode(user.id+'-myrock-encode');

        return (
            <div className="dropdown-compras mb-3">
                <div className="row">
                    <div className="container">

                        <div className="card">

                            <div onClick={this.toggle} id="button-compra-dropdown" className={
                                (compra.status === 3) ?
                                    "btn card-header border-left-blue p-1"
                                    : (compra.status === 4 || compra.status === 7) ?
                                    "btn card-header border-left-green p-1"
                                    : (compra.status === 1) ?
                                        "btn card-header border-left-yellow p-1"
                                        : "btn card-header border-red p-1"
                            }>
                                <div className="row">
                                    <div className="col-5 text-left">
                                        <strong className="text-nowrap">{compra.id}</strong>
                                    </div>
                                    <div className="col-5">
                                        <small className="text-muted small text-nowrap"><FontAwesomeIcon
                                            icon={faCalendar}/> {compra.createdAt_BR}</small>
                                    </div>
                                    <div className="col-2">
                                        <FontAwesomeIcon icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}
                                                         size="1x"/>
                                    </div>
                                </div>
                            </div>

                            <div className={(this.state.dropdownOpen) ? 'row ' : 'row d-none'}>
                                <div className="col-12 mt-1 text-left">
                                    <div className="col-12 text-nowrap div-dropdown">
                                        <div className="d-block">Materiais:&nbsp;
                                            <strong>
                                                <ol className="ml-4">
                                                    {compra.products.map(product => (
                                                        <li translate="no" key={product.id}>
                                                            {product.product}(Matrícula: {product.enrollment})
                                                        </li>
                                                    ))}
                                                </ol>
                                            </strong>
                                        </div>
                                        <p>Valor
                                            total: <strong> R${parseFloat(compra.totalNF).toFixed(2).replace('.', ',')}</strong>
                                        </p>
                                        <p>
                                            Status:&nbsp;
                                            {
                                                (compra.status === 3) ?
                                                    <span
                                                        className="badge badge-info small">Aguardando pagamento</span>
                                                    : (compra.status === 4 || compra.status === 7) ?
                                                    <span className="badge badge-success  small">PAGO</span>
                                                    : (compra.status === 1) ?
                                                        <span
                                                            className="badge badge-success small">NF-e Emitida</span>
                                                        : null
                                            }
                                        </p>
                                        <p>
                                            {
                                                (compra.chaveAcesso !== '' && compra.reciboNFe !== '' && compra.dataEmissaoNfe && compra.ambienteEmissaoNfe && compra.status === 1) ? (
                                                    <div>
                                                        <a className="btn btn-sm btn-info mr-3" rel="noopener noreferrer" target="_blank"
                                                           title="Baixar Documento Auxiliar da Nota Fiscal Eletrônica"
                                                           href={`${URL}/student-payment/${userB64}/purchase/${compraB64}/danfe-pdf`}>
                                                            <FontAwesomeIcon icon={faFilePdf}/>
                                                        </a>
                                                        <a className="btn btn-sm btn-success" title="Baixar XML"
                                                           target="_blank"
                                                           href={`${URL}/student-payment/${userB64}/purchase/${compraB64}/danfe-xml`}>
                                                            <FontAwesomeIcon icon={faFileArchive}/>
                                                        </a>
                                                    </div>
                                                ) : (this.props.compra.status === 3) ? (
                                                    <button className="btn btn-sm btn-success" title="Tentar novamente"
                                                       onClick={
                                                           () => {
                                                               this.props.modificaCarrinhoCompra(compra.id, this.props.user).then(
                                                                   () => this.props.history.push('/pagamento')
                                                               )
                                                           }
                                                       }>
                                                        <FontAwesomeIcon icon={faCreditCard}/> Tentar novamente
                                                    </button>
                                                ) : null


                                            }
                                        </p>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => (
    {
        user: state.AppReducer.userData,

    }
)

export default connect(mapStateToProps, {modificaCarrinhoCompra})(Compra)