import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faCloud, faSun, faMoon} from '@fortawesome/free-solid-svg-icons'
//
import Sala from './Sala'


export default class Example extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: true
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            (this.props.horarios.length > 0)?
            <div className="row container ml-1 drop-down">
                <button onClick={this.toggle} className="row col-12 bg-secondary">
                    <div className="col-1">
                        <br/>
                        <FontAwesomeIcon icon={
                            (this.props.index === 0) ? faCloud : ((this.props.index === 1)) ? faSun : faMoon
                        }/>
                    </div>
                    <div className="col-10">
                        <br/>
                        <p className="center">{(this.props.index === 0) ? 'Manhã' : ((this.props.index === 1)) ? 'Tarde' : 'Noite'}</p>
                    </div>
                    <div className="col-1 text-right">
                        <br/>
                        <FontAwesomeIcon icon={(this.state.dropdownOpen) ? faAngleUp : faAngleDown}/>
                    </div>
                </button>

                <div className={(this.state.dropdownOpen) ? 'row col-12' : 'row col-12 d-none'}>
                    {
                        this.props.horarios.map((data, index) => (
                            <Sala history={this.props.history} key={`${index}`} horario={data}/>
                        ))
                    }
                </div>

            </div>:null
        );
    }
}