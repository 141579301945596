import React, {Component} from 'react'
import {Link} from "react-router-dom"
import {
    faDollarSign,
    faShoppingBasket,
    faBook,
    faBookOpen,
    faGlobe,
    faChartLine,
    faEdit,
    faLightbulb,
    faLifeRing,
    faGraduationCap,
    faBookReader,
    faPlay,
    faClock,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//
import {getPermission} from '../../services/checkPermission';

class Botoes extends Component {

    state = {
        permissions: [],
    }
    componentDidMount() {
        const permissions = getPermission(this.props.user);
        this.setState({permissions});
    }

    render() {
        return (
            <div className="menu-butoes d-md-none p-1" translate="no">

                <div className="row align-middle">
                    <div className="col-4 mb-3">
                        <Link to='/financeiro' className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                            <div className="col-12">
                                <FontAwesomeIcon icon={faDollarSign} size="4x" className="mb-2 mt-2"/>
                            </div>
                            <div className="col-12">
                                Financeiro
                            </div>
                        </Link>
                    </div>
                    {
                        (this.state.permissions.indexOf('minhas-compras') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/minhas-compras' className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faShoppingBasket} size="4x" className="mb-2 mt-2"/>
                                    <br/>Minhas<br/> compras
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('comprar-material-pedagogico') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/comprar-material-pedagogico'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faBook} size="4x" className="mb-2 mt-2"/>
                                    <br/> Comprar<br/> material<br/> didático
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('simulado-exames-internacionais') >= 0) && (
                            <div className="col-4 mb-3">
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={`https://rockfeller.edusynch.com/sign-up?school=${this.props.user.franchiseCNPJ}`}
                                   className="w-100 h-100 btn-menu btn pt-2 pb-2">
                                    <FontAwesomeIcon icon={faGlobe} size="4x" className="mb-2 mt-2"/>
                                    <br/>Simulados<br/>de<br/>exames<br/>internacionais
                                </a>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('rockfeller-play') >= 0) && (
                            <div className="col-4 mb-3">
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={`https://play.rockfeller.com.br/`}
                                   className="w-100 h-100 btn-menu btn pt-2 pb-2">
                                    <FontAwesomeIcon icon={faPlay} size="4x" className="mb-2 mt-2"/>
                                    <FontAwesomeIcon icon={faPlay} size="4x" className="mb-2 mt-2"/>
                                    <br/>Rockfeller<br/>Play
                                </a>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('boletins') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/boletins' className="w-100 h-100 btn-menu btn btn-light pt-3 pb-3">
                                    <FontAwesomeIcon icon={faChartLine} size="4x" className="mb-2 mt-2"/>
                                    <br/>
                                    Boletins
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('composition') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/composition' className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faEdit} size="4x" className="mb-2 mt-2"/>
                                    <br/>Bring It Out
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('conteudo-online-kids') >= 0) ? (
                            <div className="col-4 mb-3">
                                <Link to='/conteudo-online-kids'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faBookReader} size="4x" className="mb-2 mt-2"/>
                                    <br/>Conteúdo<br/>Online
                                </Link>
                            </div>
                        ) : (this.state.permissions.indexOf('conteudo-online') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/conteudo-online' className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faBookReader} size="4x" className="mb-2 mt-2"/>
                                    <br/>Conteúdo<br/>Online
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('english-central') >= 0) && (
                            <div className="col-4 mb-3">
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={`https://pt.englishcentral.com/partner/rockfeller`}
                                   className="w-100 h-100 btn-menu btn pt-2 pb-2">
                                    <FontAwesomeIcon icon={faLightbulb} size="4x" className="mb-2 mt-2"/>
                                    <br/>Plus
                                </a>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('agendar-aula-presencial') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/agendar-aula-presencial'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    {
                                        (this.props.user.type === 'STUDENT_DUO') ?
                                            <span><FontAwesomeIcon icon={faBook} size="4x" className="mb-2 mt-2"/><br/> Agendar<br/>aula<br/>Presencial</span> :
                                            <span><FontAwesomeIcon icon={faBook} size="4x" className="mb-2 mt-2"/><br/> Agendar<br/>aula<br/>no<br/>Rockspot</span>
                                    }
                                </Link>
                            </div>
                        )
                    }
                    {
                        this.props.user.type === 'STUDENT_DUO' && (
                            <div className="col-4 mb-3">
                                <Link to='/interaction'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    {
                                        <span><FontAwesomeIcon icon={faBookOpen} size="4x" className="mb-2 mt-2"/><br/> Aulas<br/>concluídas</span>
                                    }
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('agendar-suporte') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/agendar-suporte'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faClock} size="4x" className="mb-2 mt-2"/>
                                    <br/>Agendar<br/>suporte
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('material-de-apoio') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/material-de-apoio'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faLifeRing} size="4x" className="mb-2 mt-2"/>
                                    <br/>
                                    {
                                        (this.props.user.type === 'STUDENT_DUO') ?
                                            <span>Vídeo<br/>tutorial</span> :
                                            <span>Materiais<br/>de<br/>apoio</span>
                                    }
                                </Link>
                            </div>
                        )
                    }
                    {
                        (this.state.permissions.indexOf('atividades-complementares') >= 0) && (
                            <div className="col-4 mb-3">
                                <Link to='/atividades-complementares'
                                      className="w-100 h-100 btn-menu btn btn-light pt-2 pb-2">
                                    <FontAwesomeIcon icon={faPlus} size="4x" className="mb-2 mt-2"/>
                                    <br/> Atividades<br/>Complementares
                                </Link>
                            </div>
                        )
                    }
                </div>

            </div>

        )
    }
}

export default Botoes
