import {
    MODIFICA_BOLETINS,
} from '../actions/types'

const INITIAL_STATE = {
    boletins: [],
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case MODIFICA_BOLETINS:
            return { ...state, boletins: action.payload }
        default:
            return state;
    }

}